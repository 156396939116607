<template>
  <master-layout>
    <div v-if="isLoading" class="w-screen h-screen flex items-center justify-center">
        <Loading />
    </div>
    <div
      v-else
      class="h-[calc(var(--app-height)-(88px))] lg:flex-1 pt-[24px] pb-[123px] md:pb-[138px] md:pt-[32px] md:px-[56px] px-[24px]"
    >
      <header class="bg-[#F8F9F9] z-10 mb-[24px]">
        <router-link
          class="flex items-center rounded-full"
          to="/service-replay"
        >
          <img
            class="block"
            src="../../../public/assets/icons/chevron-left-white.svg"
            alt=""
          />
        </router-link>
      </header>
      <section class="pb-6">
        <div class="w-full max-w-[936px]">
          <div v-if="current.isMux" class="w-full">
            <mux-player
              stream-type="on-demand"
              :playback-id="current.vimeoUrl"
              primary-color="#FFFFFF"
              secondary-color="#000000"
            ></mux-player>
          </div>
          <div v-else class="relative pt-[56.25%]">
            <iframe
              class="absolute top-0 left-0 w-full h-full"
              :src="current.vimeoUrl"
              :title="current.title"
              allow="autoplay; fullscreen; picture-in-picture"
            >
            </iframe>
          </div>
        </div>

        <h1
          class="text-[24px] leading-[32px] md:text-[32px] md:leading-[40px] text-[#0B0C0F] font-bold mt-[24px]"
        >
          {{ current.title }}
        </h1>
        <p class="mt-[8px] md:mt-[16px]">{{ current.description }}</p>
      </section>
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import Loading from "@/components/common/LoadingState.vue";

export default {
  head() {
    return {
      script: [
        {
          src: "https://cdn.jsdelivr.net/npm/@mux/mux-player",
        },
      ],
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapState("serviceReplay", {
      isLoading: (state) => state.isLoading,
      serviceReplays: (state) => state.serviceReplays,
    }),
    current() {
      console.log("this.$route.params.id", this.$route.params.id);
      console.log("this.serviceReplays", this.serviceReplays);
      console.log("current", this.serviceReplays[this.$route.params.id]);
      return this.serviceReplays[this.$route.params.id];
    },
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("serviceReplay/fetchServiceReplayById", {
      serviceReplayId: this.$route.params.id,
    });
    await this.$store.dispatch("updates/getUpdates", "all");
  },
};
</script>
