<template>
  <div>
    <FDButton text="Back to letters" linkto="/hogcfd" :is-bold="true" />
    <div class="relative mt-[80px] sm:mt-[56px] sm:w-[562px]">
      <h1 class="font-xtypewriter text-[32px] sm:text-[40px] leading-[32px]">
        Father’s day letter
      </h1>
      <YearStampIcon :year="year" class="absolute bottom-[44px] right-[-10px] sm:bottom-0" />
    </div>
    <FDUnderline class="mt-[9px] sm:mt-[13px] max-w-[100vw] h-full" />
  </div>
</template>

<script>
import FDButton from '@/components/hogcfd/FDButton'
import YearStampIcon from '@/components/hogcfd/YearStampIcon'
import FDUnderline from '../../../public/assets/icons/hogcfd/FDUnderline'

export default {
  components: { FDButton, FDUnderline, YearStampIcon },
  props: {
    year: {
      type: String,
      required: true,
    },
  },
}
</script>
