<template>
  <div
    class="absolute top-full left-0 right-0 bg-[#ffffff] pt-[12px] pb-[24px] px-[24px] z-10 shadow-sm lg:px-0"
  >
    <div class="menu w-full lg:max-w-[477px] mx-auto lg:pl-[29.5px]">
      <div v-for="(year, i) in Object.keys(dates)" :key="i" class="group">
        <label class="year-label font-bold font-proxima text-[16px] leading-[24px]">
          {{ year }}
        </label>
        <div
          class="grid md:grid-rows-4 justify-start grid-rows-6 grid-flow-col gap-y-[12px] gap-x-[48px] mt-[4px]"
          :class="{ 'less-than-6': dates[year].length < 6 }"
        >
          <div v-for="(month, m) in dates[year]" :key="m">
            <span
              class="cursor-pointer month-badge"
              :class="{
                active:
                  currentMonth?.split(' ')?.join('')?.toLowerCase() ===
                  `${month}${year}`.toLowerCase(),
              }"
              @click="selectMonth(month, year)"
            >
              {{ month }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <p
      class="pt-[26px] lg:pt-[36px] italic text-[#99A4B2] text-[16px] leading-[20px] font-normal lg:max-w-[418px] mx-auto"
    >
      Calendar last updated {{ calendarLastUpdatedDate }}
    </p>
    <a
      v-if="calendarDownloadLink && isLeader"
      :href="calendarDownloadLink"
      class="mt-[26px] h-[20px] w-full lg:max-w-[418px] mx-auto flex flex-row"
      target="_blank"
    >
      <p class="text-[#6245F4] leading-[20px] font-semibold mr-[2px]">Excel view of calendar</p>
      <img class="h-[20px]" src="../../../../public/assets/icons/calendar/link-out.svg" />
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('calendar', ['currentMonth', 'isLeadersCalendar']),
    ...mapState('cms', ['calendarEvents', 'calendarDownloadLink', 'calendarLastUpdatedDate']),
    ...mapState('profile', ['isLeader']),
    dates () {
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const items = Object.values(this.calendarEvents).flat()
      const data = {}
      items.filter((item) => {
        if (this.isLeadersCalendar === false) {
          return item.isLeadersOnly !== true
        }
        return item
      }).forEach((item) => {
        const parsedDate = new Date(item.startDate)
        const monthIndex = parsedDate.getUTCMonth()
        const year = parsedDate.getUTCFullYear()

        if (data[year]) {
          if (!data[year].includes(months[monthIndex])) {
            data[year].push(months[monthIndex])
          }
        } else {
          data[year] = [months[monthIndex]]
        }
      })
      return data
    },
  },
  methods: {
    async selectMonth (month, year) {
      console.log('in selectMonth')
      console.log('month')
      console.log(month)
      console.log('year')
      console.log(year)
      await this.$store.dispatch('calendar/updateCurrentMonth', `${month} ${year}`)
      await this.$store.dispatch('calendar/updateIsScrollToMonth', true)
      await this.$store.dispatch('calendar/updateIsFirstScroll', true)
      await this.$store.dispatch('calendar/updateShowFilterBar', true)
      await this.$store.dispatch('calendar/updateShowCalendarMenu', false)
    },
  },
}
</script>

<style scoped>
.group:not(:first-child) {
  margin-top: 24px;
}

.year-label {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #373e49;
}

.month-badge {
  background: #efecfd;
  border-radius: 8px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #373e49;
}

.month-badge.active {
  border: 1.5px solid #6245f4;
}

@media (min-width: 458px) {
  .menu {
    display: flex;
    gap: 48px;
  }
  .group:not(:first-child) {
    margin-top: 0;
  }
  .group > div > div {
    flex: 1 0 128px;
  }
}

@media (max-width: 640px) {
  .top-full {
    border-radius: 0px 0px 24px 24px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  .grid.less-than-6 {
    grid-template-rows: min-content 1fr;
  }
}

span {
  margin-right: 12px;
}
</style>
