<template>
    <header
      class="home-top-nav flex items-start items-center justify-between sticky top-0 left-0 right-0 z-[100] h-[68px] lg:h-[88px]"
    >
      <div class="cursor-pointer ml-[24px] md:ml-[40px] inline-flex items-center">
        <component
          :is="icon"
          class="h-[32px] w-[32px]"
          @click="onNavClick"
        />
      </div>
  
      <div class="md:mr-[60px] mr-[24px] flex items-center">
  <!--      <BaseButton-->
  <!--        v-if="!isIOS"-->
  <!--        type="outline"-->
  <!--        class="text-[#6245F4] hover:bg-[#F8F9F9]"-->
  <!--        outline-classes="border-[#6245F4] !leading-[24px]"-->
  <!--        @click="toggleSelect"-->
  <!--      >-->
  <!--        {{ isSelectingPhotos ? 'Cancel' : 'Select' }}-->
  <!--      </BaseButton>-->
        <button class="block lg:hidden" @click="openScanner">
          <img class="w-[32px] h-[32px]" src="../../../public/assets/icons/photo/scanner.svg" alt="Scanner Icon">
        </button>
        <BaseButton
          v-if="!isSelectingPhotos && !isPhotosEmpty && !isShowQr"
          class="ml-[12px] theme-button"
          solid-classes="bg-[#6245F4] text-white border-[#6245F4] hover:bg-[#3B4CEF] !leading-[24px]"
          @click.stop="toggleQr"
        >
          Photo QR
        </BaseButton>
      </div>
  
      <p v-if="isSelectingPhotos" class="md:hidden text-[16px] md:text-[20px] leading-[32px] font-bold text-center">
        {{ selectedPhotos.length }}
        {{ selectedPhotos.length === 1 ? 'photo' : 'photos' }} selected
      </p>
  
      <div v-if="isSelectingPhotos" class="flex flex-row md:mr-[60px] mr-[24px]">
        <p class="hidden md:block mr-[28px] text-[16px] md:text-[20px] leading-[32px] font-bold text-center">
          {{ selectedPhotos.length }}
          {{ selectedPhotos.length === 1 ? 'photo' : 'photos' }} selected
        </p>
        <img
          src="../../../public/assets/icons/photo/download-black.svg"
          class="cursor-pointer h-[32px] w-[32px]"
          alt="download black"
          @click="download"
        />
      </div>
    </header>
  </template>
  
  <script>
  import { mapGetters, mapState } from 'vuex'
  import BaseButton from '@/components/design/BaseButton.vue'
  import isIos from '../../mixins/isIos'
  import ChevronLeftBlack from '../../../public/assets/icons/photo/ChevronLeftBlack'
  import Cancel from '@/components/icons/Cancel'
  export default {
    components: {
      BaseButton,
    },
    mixins: [isIos],
    computed: {
      ...mapState('photos', ['isSelectingPhotos', 'selectedPhotos', 'isShowQr']),
      ...mapGetters('photos', ['isPhotosEmpty']),
      icon () {
        return this.isSelectingPhotos ? Cancel : ChevronLeftBlack
      },
    },
    methods: {
      onNavClick () {
        if (this.isShowQr) this.toggleQr()
        else if (this.isSelectingPhotos) this.toggleSelect()
        else this.navigateToHome()
      },
      async toggleSelect () {
        await this.$store.dispatch('photos/updateIsSelectingPhotos', !this.isSelectingPhotos)
        await this.$store.dispatch('photos/resetSelectedPhotos')
      },
      async toggleQr () {
        await this.$store.dispatch('photos/updateIsShowQr', !this.isShowQr)
      },
      openScanner () {
        this.$router.push('/scanner')
      },
      navigateToHome () {
        this.$router.push('/')
      },
      download () {
        // TODO: refactor the axios
        if (!this.selectedPhotos.length) return
        const newAxios = this.$axios.create({})
        delete newAxios.defaults.headers.common.Authorization
  
        const calls = this.selectedPhotos.map(
          async (image) =>
            await newAxios.get(image, {
              headers: {
                'Access-Control-Allow-Origin': '*',
              },
              responseType: 'blob',
            }),
        )
  
        Promise.all(calls)
          .then(async (responses) => {
            const blobs = responses.map((res) => URL.createObjectURL(res.data))
            const date = new Date().toLocaleDateString('en-US')
  
            blobs.forEach((blob, index) => {
              const anchor = document.createElement('a')
  
              anchor.href = blob
              anchor.download = `${date}_${index}.jpg`
  
              anchor.click()
  
              URL.revokeObjectURL(blob)
            })
  
            await this.$store.dispatch('photos/updateIsSelectingPhotos', false)
            await this.$store.dispatch('photos/resetSelectedPhotos')
          })
          .catch(async (e) => {
            await this.$store.dispatch('toast/error', e.response.data.message, { root: true })
          })
      },
    },
  }
  </script>
  
  <style scoped>
  @import url('https://use.typekit.net/jne6owq.css');
  
  .home-top-nav {
    background: linear-gradient(135deg, #ffe543 0.13%, #ffcd19 100.13%);
  }
  </style>
  