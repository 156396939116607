<template>
    <label v-if="$slots.default" :class="labelClasses">
      <slot />
    </label>
  </template>
  
  <script>
  export default {
    props: {
      labelClasses: {
        type: [String, Array, Object],
        default: 'text-[14px] leading-[20px] mb-[4px] block',
      },
    },
  }
  </script>