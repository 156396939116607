<template>
  <svg
    v-if="!isActive"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.70589 14.2013C9.87112 9.85258 14.3544 7.26416 18.7031 8.42939C23.0518 9.59461 25.6402 14.0779 24.475 18.4266L22.8959 24.3197L24.7094 29.3649L3.03371 23.5569L7.12685 20.0944L8.70589 14.2013Z"
      stroke="#99A4B2"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M23.0041 6.57693L23.1057 6.19761C23.6321 4.23325 22.4718 2.2235 20.5074 1.69715C18.543 1.1708 16.5333 2.33113 16.0069 4.29549L15.9053 4.67482C18.9129 4.66758 20.563 5.06616 23.0041 6.57693Z"
      stroke="#99A4B2"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M13.2709 30.8167L12.2142 30.5336C9.88403 29.9092 8.50552 27.5215 9.12988 25.1914L18.613 27.7324C17.9887 30.0625 15.601 31.441 13.2709 30.8167Z"
      stroke="#99A4B2"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M23.549 19.3911C26.0863 20.0709 28.7954 18.5068 29.4752 15.9696C30.1551 13.4323 28.591 10.7232 26.0537 10.0434C23.5165 9.36352 20.8074 10.9276 20.1275 13.4649C19.4477 16.0021 21.0118 18.7112 23.549 19.3911Z"
      fill="#FF6600"
    />
  </svg>

  <svg
    v-else
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11109_25236)">
      <path
        d="M25.468 26.6906L23.901 22.3825L25.2586 17.3159C26.4514 12.8642 23.7698 8.25736 19.2863 7.056C14.8027 5.85464 10.177 8.50343 8.98411 12.9552L7.62652 18.0218L4.11543 20.9692C3.84148 21.199 3.71785 21.5613 3.79649 21.9119C3.87512 22.2624 4.13533 22.5299 4.48212 22.6228L24.2989 27.9327C24.6456 28.0256 25.0204 27.9151 25.2605 27.6631C25.5039 27.3988 25.578 27.0233 25.4556 26.6873L25.468 26.6906Z"
        fill="#00AAE4"
      />
      <path
        d="M16.0877 5.46082C16.1738 5.48388 16.2476 5.50366 16.3435 5.48983C18.9408 5.4872 20.2845 5.83407 22.2833 7.08138C22.5431 7.24325 22.8547 7.26085 23.1345 7.15129C23.4142 7.04173 23.622 6.80743 23.7011 6.51228L23.7934 6.16795C24.4063 3.88059 23.0469 1.52609 20.7595 0.913195C18.4722 0.3003 16.1177 1.65967 15.5048 3.94703L15.4125 4.29136C15.3367 4.57421 15.3995 4.881 15.5714 5.12478C15.6984 5.29059 15.8786 5.4048 16.0877 5.46082Z"
        fill="#00AAE4"
      />
      <path
        d="M18.3007 27.3076L9.69234 25.001C9.18814 24.8659 8.65798 25.172 8.52288 25.6762C7.82102 28.2956 9.37845 30.9932 11.9978 31.695L12.9571 31.952C15.5764 32.6539 18.274 31.0965 18.9759 28.4771C19.111 27.9729 18.8049 27.4427 18.3007 27.3076Z"
        fill="#00AAE4"
      />
    </g>
    <defs>
      <clipPath id="clip0_11109_25236">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0.606445 0.410156)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "UnreadUpdatesIcon",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
