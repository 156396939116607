<template>
  <svg
    v-if="isReadActive"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2624 10.1384V17.7858C22.2624 18.5246 21.9689 19.2332 21.4465 19.7556C20.9241 20.278 20.2155 20.5715 19.4767 20.5715H6.19099C5.45217 20.5715 4.74361 20.278 4.22119 19.7556C3.69877 19.2332 3.40527 18.5246 3.40527 17.7858V10.1384C3.40527 10.0501 3.41042 9.96181 3.41984 9.87524L12.4927 15.5452C12.5817 15.6009 12.6831 15.6339 12.7878 15.6414C12.8925 15.6489 12.9975 15.6306 13.0936 15.5881L13.175 15.5452L22.2478 9.87524C22.2573 9.96181 22.2624 10.0501 22.2624 10.1384ZM13.187 2.8921L21.2013 8.16953C21.413 8.30838 21.5964 8.4781 21.7498 8.6701L12.8338 14.2415L3.91784 8.6701C4.07127 8.4781 4.25556 8.30838 4.46642 8.16953L12.4807 2.8921C12.5857 2.82297 12.7086 2.78613 12.8343 2.78613C12.96 2.78613 13.0829 2.82297 13.1878 2.8921H13.187Z"
      :fill="fill"
    />
  </svg>

  <svg
    v-else
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6786 7.42542C22.147 7.20302 22.5498 6.863 22.8477 6.43852C23.1455 6.01404 23.3282 5.51961 23.3781 5.00345C23.4279 4.48729 23.343 3.96704 23.1318 3.49344C22.9206 3.01985 22.5902 2.60908 22.173 2.30123C21.7557 1.99337 21.2657 1.79894 20.7509 1.73689C20.2361 1.67484 19.7139 1.7473 19.2355 1.94719C18.757 2.14709 18.3385 2.46759 18.0208 2.87745C17.7031 3.2873 17.4971 3.77249 17.4228 4.2857L17.422 4.29428C17.3619 4.72184 17.394 5.15732 17.5163 5.57142C17.64 5.98615 17.852 6.36921 18.1377 6.69434C18.4233 7.01947 18.7759 7.27899 19.1713 7.45509C19.5666 7.63119 19.9954 7.71971 20.4282 7.71459C20.8609 7.70947 21.2875 7.61083 21.6786 7.42542ZM5.60713 4.2857H16.1286C16.086 4.71509 16.1077 5.14842 16.1928 5.57142H5.60713C5.23068 5.57143 4.868 5.71299 4.59108 5.968C4.31416 6.22301 4.14324 6.57282 4.11227 6.94799L4.10713 7.07142V7.8977L12.25 12.132L18.8346 8.7077C19.318 8.89628 19.8434 8.99999 20.3928 8.99999C20.8289 9.00049 21.2625 8.93429 21.6786 8.8037V16.9286C21.6786 17.6401 21.4064 18.3247 20.9177 18.8419C20.429 19.3591 19.761 19.6697 19.0506 19.71L18.8928 19.7143H5.60713C4.89558 19.7143 4.21099 19.4421 3.69378 18.9534C3.17658 18.4647 2.86598 17.7967 2.8257 17.0863L2.82141 16.9286V7.07142C2.82136 6.35987 3.0936 5.67528 3.58227 5.15808C4.07094 4.64088 4.73901 4.33027 5.44941 4.28999L5.60713 4.2857ZM20.3928 9.34713L12.5466 13.4271C12.4706 13.4666 12.3874 13.4905 12.302 13.4975C12.2167 13.5044 12.1308 13.4942 12.0494 13.4674L11.9534 13.4271L4.10713 9.34713V16.9286C4.10714 17.305 4.2487 17.6677 4.50371 17.9446C4.75872 18.2215 5.10853 18.3924 5.4837 18.4234L5.60713 18.4286H18.8928C19.2694 18.4285 19.6322 18.2868 19.9092 18.0317C20.1861 17.7765 20.3569 17.4265 20.3877 17.0511L20.3928 16.9286V9.34713Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isReadActive: {
      type: Boolean,
      required: false,
    },
    fill: {
      type: String,
      default: 'black',
    },
  },
}
</script>
