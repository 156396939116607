<template>
  <birthday-layout>
    <div class="w-full h-full min-h-screen">
      <SideBar v-if="showSideBar" />
      <div :class="opaqueClass">
        <BoardHeader :is-page-unique-to-board="true" />
        <div class="w-full max-w-[680px] mx-auto">
          <div
            class="inline-flex items-center ml-[12px] mt-[24px] cursor-pointer"
            @click="back()"
            @mouseover="isMouseOver = true"
            @mouseout="isMouseOver = false"
          >
            <ChevronLeftIcon :fill="chevronLeftFill" />
            <p class="hidden md:block ml-[12px]" :class="hoverTextColour">
              Back
            </p>
          </div>
          <MessageForm />
        </div>
      </div>
    </div>
  </birthday-layout>
</template>

<script>
import { mapState } from "vuex";
import ChevronLeftIcon from "../../../public/assets/icons/board/ChevronLeftIcon";
import MessageForm from "@/components/Board/Input/MessageForm";
import SideBar from "@/components/Board/Main/SideBar";
import BirthdayLayout from "@/components/BirthdayLayout.vue";
import BoardHeader from "@/components/Board/Main/BoardHeader";

export default {
  components: {
    ChevronLeftIcon,
    MessageForm,
    SideBar,
    BirthdayLayout,
    BoardHeader,
  },
  data() {
    return {
      isMouseOver: false,
    };
  },
  computed: {
    ...mapState("board", ["currBoard", "showSideBar"]),
    chevronLeftFill() {
      return this.isMouseOver ? this.currBoard.themeColour : "#000000";
    },
    hoverTextColour() {
      return "hover:text-[" + this.currBoard.hoverTextColour + "]";
    },
    opaqueClass() {
      return this.showSideBar ? "brightness-50 bg-[#ffffff] h-[100vh]" : "";
    },
  },
  methods: {
    back() {
      this.$router.push("/birthdayboard");
    },
  },
};
</script>
