<template>
  <router-link
    v-if="!isExternalLink"
    :id="id"
    class="button-container cursor-pointer lg:drop-shadow-tile"
    :class="disabled && 'cursor-not-allowed pointer-events-none'"
    :to="to"
    :disabled="disabled"
  >
    <div class="button relative lg:static">
      <component
        :is="icon"
        class="w-[36px] h-[36px] lg:w-[64px] lg:h-[64px]"
        :disabled="disabled"
      />
      <label v-if="disabled" class="coming-soon absolute">Coming Soon!</label>
      <div
        v-else-if="isNew"
        class="px-[3px] py-[4.5px] lg:px-[4px] lg:py-[5px] bg-[#FEDB00] absolute top-[-10px] right-[-4px] lg:right-[32px] lg:top-[12px] flex items-center justify-center rounded-md lg:rounded-lg"
      >
        <label
          class="font-semibold text-[10px] leading-[10px] lg:text-[14px] lg:leading-[14px]"
          >New!</label
        >
      </div>
    </div>
    <label
      class="btn-label cursor-pointer"
      :for="id"
      :class="disabled && 'cursor-not-allowed text-[#7C8A9C]'"
      >{{ label }}</label
    >
  </router-link>

  <a
    v-else
    :id="id"
    class="button-container cursor-pointer drop-shadow-tile"
    :class="disabled && 'cursor-not-allowed pointer-events-none'"
    :href="to"
    target="_blank"
  >
    <div class="button relative lg:static">
      <component
        :is="icon"
        class="w-[36px] h-[36px] lg:w-[64px] lg:h-[64px]"
        :disabled="disabled"
      />
      <div
        v-if="isNew"
        class="px-[3px] py-[4.5px] lg:px-[4px] lg:py-[5px] bg-[#FEDB00] absolute top-[-10px] right-0 lg:right-[10px] lg:right-[16px] lg:top-[12px] flex items-center justify-center rounded-md lg:rounded-lg"
      >
        <label
          class="font-semibold text-[10px] leading-[10px] lg:text-[14px] lg:leading-[14px]"
          >New!</label
        >
      </div>
    </div>
    <label
      class="btn-label cursor-pointer"
      :for="id"
      :class="disabled && 'cursor-not-allowed text-[#7C8A9C]'"
      >{{ label }}</label
    >
  </a>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    icon: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    isExternalLink: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap");
.coming-soon {
  font-family: "Caveat", cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  left: 50px;
  top: 15px;
  color: #6245f4;
  transform: rotate(15deg);
  width: 100px;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 76px;
}

.button {
  background: linear-gradient(180deg, #f8f9f9 0%, #e6e1ff 146.43%);
  height: 60px;
  width: 60px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.btn-label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

@media screen and (min-width: 1024px) {
  .button-container {
    flex-direction: row;
    align-items: center;
    background: linear-gradient(180deg, #ffffff 0%, #efe8ff 135.78%);
    height: 96px;
    width: 264px;
    border-radius: 24px;
    padding-left: 32px;
  }

  .button-container:not(:last-child) {
    margin-right: 24px;
  }

  .button {
    align-items: center;
    background: transparent;
    height: auto;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 12px;
    margin-top: 0;
    width: auto;
  }

  .btn-label {
    font-size: 20px;
  }
}
</style>
