<template>
    <div>
      <div class="flex flex-col">
        <div
          class="flex flex-row cursor-pointer mb-[16px]"
          :hover="currBoard.themeColour"
          data-event-tracking="favourite"
          @click="favouriteToggle"
          @mouseover="isFavouriteHover = true"
          @mouseout="isFavouriteHover = false"
        >
          <FavouriteIcon :is-favourite-active="isFavouriteActive" :fill="checkFavHover" class="w-[24px] h-[24px]"/>
        </div>
        <div
          class="flex flex-row cursor-pointer"
          :hover="currBoard.themeColour"
          data-event-tracking="mark-as-read"
          @click="readToggle"
          @mouseover="isReadHover = true"
          @mouseout="isReadHover = false"
        >
          <MarkAsReadIcon :is-read-active="isReadActive" :fill="checkReadHover" class="w-[24px] h-[24px]"/>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapState } from 'vuex'
import FavouriteIcon from '../../../../public/assets/icons/board/FavouriteIcon'
import MarkAsReadIcon from '../../../../public/assets/icons/board/MarkAsReadIcon'

export default {
  components: {
    FavouriteIcon,
    MarkAsReadIcon,
  },
  props: {
    isFavourite: {
      type: Boolean,
    },
    isRead: {
      type: Boolean,
    },
    isReadActive: {
      type: Boolean,
    },
    isFavouriteActive: {
      type: Boolean,
    },
    favourite: {
      type: Function,
    },
    read: {
      type: Function,
    },
  },
  data () {
    return {
      isPostFavourited: this.isFavourite,
      isPostRead: this.isRead,
      isFavouriteHover: false,
      isReadHover: false,
      favouriteToggle: this.favourite,
      readToggle: this.read,
    }
  },
  computed: {
    ...mapState('board', ['currBoard']),
    checkFavHover () {
      return this.isFavouriteHover ? this.currBoard.themeColour : '#373E49'
    },
    checkReadHover () {
      return this.isReadHover ? this.currBoard.themeColour : '#373E49'
    },
  },
}
</script>
