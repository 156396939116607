import STATUSES from '@/constants/STATUSES'
import axios from "axios";

const state = () => {
  const currentYear = new Date().getFullYear()
  const years = [...Array(100).keys()].map(key => currentYear - key)
  // TODO: need to fix
  // years.unshift('Prefer not to say')

  return {
    status: STATUSES.PENDING,
    genders: [{ id: 'M', name: 'Male' }, { id: 'F', name: 'Female' }],
    nationalities: [],
    residentialAreas: [],
    maritalStatuses: [],
    educationalPhases: [],
    schools: {},
    course: {},
    industries: [],
    transitions: [],
    stagesOfLife: [],
    christianJourneys: [],
    pastoralMapping: {},
    cgMapping: {},
    years,
    zoneCgMapping: {},
  }
}

const mutations = {
  set (state, { key, value }) {
    state[key] = value
  },
  setCgMapping (state, { value }) {
    state.cgMapping = value
  },
  setZoneCgMapping (state, { value }) {
    state.zoneCgMapping = value
  },
}

const actions = {
  async fetchCgMapping ({ state, commit, dispatch, rootState }) {
    if (Object.keys(state.cgMapping).length) return

    try {
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL +  '/profile/cg-mapping',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      commit('setCgMapping', { value: data })
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch cg list', { root: true })
    }
  },
  async fetchZoneCgMapping ({ state, commit, dispatch, rootState }) {
    if (Object.keys(state.cgMapping).length) return

    try {
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL +  '/profile/zone-cg-mapping',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      commit('setZoneCgMapping', { value: data })
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch zone cg mapping', { root: true })
    }
  },
  async fetchIfNotYet ({ state, commit, dispatch, rootState }) {
    if (state.status !== STATUSES.PENDING) return

    commit('set', { key: 'status', value: STATUSES.LOADING })

    try {
      const accessToken = rootState.user.token;
      const [dropdowns, mapping] = await Promise.all([
        axios.get(
          process.env.VUE_APP_API_URL +  '/profile/dropdowns',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        ),
        axios.get(
          process.env.VUE_APP_API_URL +  '/profile/pastoral-mapping',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        ),
      ])

      const lookups = [
        'nationalities', 'residentialAreas', 'maritalStatuses',
        'educationalPhases', 'schools', 'course', 'industries',
        'transitions', 'stagesOfLife', 'christianJourneys',
      ]

      lookups.forEach(key => commit('set', { key, value: dropdowns.data[key] }))

      commit('set', { key: 'pastoralMapping', value: mapping.data })
      commit('set', { key: 'status', value: STATUSES.DONE })
    } catch (e) {
      dispatch('toast/error', 'Failed to connect to server. Please try again.', { root: true })
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
