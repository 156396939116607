<template>
  <svg
    v-if="!isActive"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11109_25176)">
      <path
        d="M8.68637 14.7736C9.85162 10.4249 14.3349 7.83644 18.6837 9.00169C23.0324 10.1669 25.6209 14.6503 24.4556 18.999L22.8766 24.8922L24.6901 29.9374L3.01412 24.1294L7.10731 20.6668L8.68637 14.7736Z"
        stroke="#99A4B2"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M22.9846 7.14927L23.0863 6.76994C23.6126 4.80556 22.4523 2.79579 20.4879 2.26943C18.5235 1.74307 16.5138 2.90342 15.9874 4.8678L15.8858 5.24713C18.8934 5.23989 20.5435 5.63848 22.9846 7.14927Z"
        stroke="#99A4B2"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M13.2519 31.389L12.1952 31.1059C9.865 30.4815 8.48647 28.0938 9.11084 25.7637L18.5941 28.3047C17.9697 30.6349 15.582 32.0134 13.2519 31.389Z"
        stroke="#99A4B2"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_11109_25176">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0.605469 0.757812)"
        />
      </clipPath>
    </defs>
  </svg>

  <svg
    v-else
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_11109_25236)">
      <path
        d="M25.468 26.6906L23.901 22.3825L25.2586 17.3159C26.4514 12.8642 23.7698 8.25736 19.2863 7.056C14.8027 5.85464 10.177 8.50343 8.98411 12.9552L7.62652 18.0218L4.11543 20.9692C3.84148 21.199 3.71785 21.5613 3.79649 21.9119C3.87512 22.2624 4.13533 22.5299 4.48212 22.6228L24.2989 27.9327C24.6456 28.0256 25.0204 27.9151 25.2605 27.6631C25.5039 27.3988 25.578 27.0233 25.4556 26.6873L25.468 26.6906Z"
        fill="#00AAE4"
      />
      <path
        d="M16.0877 5.46082C16.1738 5.48388 16.2476 5.50366 16.3435 5.48983C18.9408 5.4872 20.2845 5.83407 22.2833 7.08138C22.5431 7.24325 22.8547 7.26085 23.1345 7.15129C23.4142 7.04173 23.622 6.80743 23.7011 6.51228L23.7934 6.16795C24.4063 3.88059 23.0469 1.52609 20.7595 0.913195C18.4722 0.3003 16.1177 1.65967 15.5048 3.94703L15.4125 4.29136C15.3367 4.57421 15.3995 4.881 15.5714 5.12478C15.6984 5.29059 15.8786 5.4048 16.0877 5.46082Z"
        fill="#00AAE4"
      />
      <path
        d="M18.3007 27.3076L9.69234 25.001C9.18814 24.8659 8.65798 25.172 8.52288 25.6762C7.82102 28.2956 9.37845 30.9932 11.9978 31.695L12.9571 31.952C15.5764 32.6539 18.274 31.0965 18.9759 28.4771C19.111 27.9729 18.8049 27.4427 18.3007 27.3076Z"
        fill="#00AAE4"
      />
    </g>
    <defs>
      <clipPath id="clip0_11109_25236">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0.606445 0.410156)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "UpdatesIcon",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
