<template>
  <div
    class="h-[24px] cursor-pointer flex items-center"
    @click.stop="emitOpenOptionsModal"
    @mouseover="isMouseOver = true"
    @mouseout="isMouseOver = false"
  >
    <EllipsisIcon v-if="isMe || isCurrBoardAdmin" :fill="iconColour" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EllipsisIcon from '../../../../public/assets/icons/board/EllipsisIcon'

export default {
  components: {
    EllipsisIcon,
  },
  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isMouseOver: false,
    }
  },
  computed: {
    ...mapState('board', ['currBoard', 'isCurrBoardAdmin']),
    iconColour () {
      return this.isMouseOver ? this.currBoard.themeColour : '#000000'
    },
  },
  methods: {
    emitOpenOptionsModal () {
      this.$emit('open-options-modal')
    },
  },
}
</script>
