<template>
  <div class="flex flex-col md:flex-row md:px-20 md:justify-between items-center">
    <div class="md:pl-20 mb-[48px] md:mb-0 md:mr-[80px]">
      <DraggableStackContainer :scroll-trigger-id="scrollTriggerId" :visible-cards="images" />
    </div>
    <div class="md:pl-5">
      <h3 class="text-[24px] md:text-[32px] leading-[40px] cormorant-garamond-bold mb-6 text-center">
        {{ name }}
      </h3>
      <ProtectedContent :resource="resource" width="w-[294px] mx-0" />
    </div>
  </div>
</template>

<script>
import DraggableStackContainer from '@/components/hogcfd/DraggableStackContainer.vue'
import ProtectedContent from '@/components/ProtectedPage/ProtectedContent.vue'

export default {
  components: { DraggableStackContainer, ProtectedContent },
  props: {
    images: {
      type: Array,
    },
    resource: {
      type: Object,
    },
    scrollTriggerId: {
      type: String,
    },
    name: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss"></style>
