<template>
  <ion-page>
    <!-- 
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-title>Login5</ion-title>
        </ion-toolbar>
      </ion-header> -->

    <ion-content :fullscreen="true">
      <div class="w-screen h-screen flex items-center justify-center">
        <loading-state v-if="!isLocal" />
        <template v-if="isAuthenticated">
          <p class="hidden">{{ getToken() }}</p>
        </template>
        <LocalLogin v-if="isLocal" :usernames="usernames" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  isPlatform,
} from "@ionic/vue";
import axios from "axios";
import { Browser } from "@capacitor/browser";
import { defineComponent } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { mapActions } from "vuex";
import { createRedirectUri } from "../../utils/redirect-uri";
import { useRoute } from "vue-router";
import { computed, ref, watch } from "vue";
import { SplashScreen } from "@capacitor/splash-screen";
import LoadingState from "@/components/common/LoadingState.vue";
import LocalLogin from "@/components/Local/localLogin.vue";
// import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export default defineComponent({
  name: "HomePage",
  components: {
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    LoadingState,
    LocalLogin,
  },

  setup() {
    const { loginWithRedirect, isAuthenticated, user, checkSession } =
      useAuth0();
    const logoutUri = createRedirectUri(
      process.env.VUE_APP_PACKAGE_ID,
      process.env.VUE_APP_AUTH0_DOMAIN
    );
    const isBrowserOpen = ref(false);
    const isNative = isPlatform("android") || isPlatform("ios");
    const isNativeBrowser = isPlatform("mobileweb");
    const isLocal = computed(() => process.env.VUE_APP_NODE_ENV === "local");
    const usernames = ref(() => []);

    const login = async () => {
      const deepLinkPathObj = JSON.parse(
        sessionStorage.getItem("deepLinkPathObj")
      );
      await loginWithRedirect({
        appState: {
          fullPathObj: deepLinkPathObj,
        },
        openUrl: (url) =>
          Browser.open({
            url,
            windowName: "_self",
          }),
      });
      isBrowserOpen.value = true;
    };

    function delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    if (isNative && !isNativeBrowser) {
      watch(isBrowserOpen, async (value) => {
        if (!value) {
          await delay(1000);
          if (!isAuthenticated.value) await login();
        }
      });
      /**
       * Platform: Web/Android/iOS
       * Logs an app event.
       * @param name - name of the event to log
       *        params - key/value pairs of properties (25 maximum per event)
       * @returns void
       */
      // FirebaseAnalytics.logEvent({
      //   name: "native_login",
        // params: {
        //   content_type: "image",
        //   content_id: "P12453",
        //   items: [{ name: "Kittens" }],
        // },
      // });
    }

    return {
      isAuthenticated,
      user,
      login,
      isBrowserOpen,
      delay,
      isNative,
      isNativeBrowser,
      checkSession,
      isLocal,
      usernames,
    };
  },
  data() {
    return {
      token: ""
    };
  },
  async mounted() {
    if (!this.isAuthenticated) {
      if (!this.isLocal) {
        await this.login();
      } else {
        const { data: usernames } = await axios.get(
          process.env.VUE_APP_API_URL + "/local/usernames"
        );
        this.usernames = usernames;
      }
    }
  },
  async created() {
    Browser.addListener("browserFinished", async () => {
      this.isBrowserOpen = false;
    });
    await SplashScreen.hide();
  },
  methods: {
    ...mapActions("user", ["setToken"]),

    async getToken() {
      const route = useRoute();
      // console.log(route)
      const path = computed(() => route.path);
      const fromPath = computed(() => route.redirectedFrom);
      if (process.env.VUE_APP_NODE_ENV === "local") {
        this.token = localStorage.getItem("token");
      } else {
        const { getAccessTokenSilently } = useAuth0();
        this.token = await getAccessTokenSilently();
      }
      console.log("in getToken: " + this.token);
      // console.log(fromPath.value.path)
      console.log("path value: " + path.value);
      if (path.value.includes("login")) {
        console.log("pushing to home here");
        this.$router.push("/home");
      } else {
        console.log("else path value: " + path.value);
      }
      this.setToken(this.token);

    },
  },
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
