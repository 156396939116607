<template>
  <div
    class="popup-background fixed left-0 lg:left-[316px] top-0 right-0 bottom-0 flex items-center justify-center z-50"
  >
    <div
      class="popup flex flex-col justify-end items-end p-6 absolute mx-auto top-[230px] lg:top-[162px] gap-[40px] w-[327px] h-[172px] rounded-[16px]"
    >
      <div
        class="flex flex-col items-start p-0 h-32 flex-none order-none flex-grow-0 gap-[20px] w-[279px]"
      >
        <slot />
        <div
          class="flex flex-row items-start p-0 h-8 flex-none order-none flex-grow-0 gap-[28px] w-[279px]"
        >
          <div
            class="w-56 h-8 not-italic font-bold text-2xl leading-8 flex items-center text-black flex-none order-none flex-grow-0 font-proxima"
          >
            Calendar
          </div>
          <button class="w-8 h-8 flex-none order-1 flex-grow-0" @click="togglePopup()">
            <img
              class="w-[12px] h-[12px] m-auto"
              src="../../../../public/assets/icons/calendar/popup-close.svg"
              alt="popup-close-icon"
            />
          </button>
        </div>
        <CalendarToggleRadio
          @cal_category="(category) => selectCalendar(category)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CalendarToggleRadio from '@/components/Calendar/Toggle/CalendarToggleRadio'

export default {
  components: {
    CalendarToggleRadio,
  },
  props: {
    togglePopup: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    selectCalendar (category) {
      this.togglePopup()
      this.$emit('popup_cal_category', category)
    },
  },
}
</script>

<style lang="scss" scoped>
.popup-background {
  background-color: rgba(0, 0, 0, 0.6);
}
.popup {
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.2);
  background: #f8f9f9;
}
</style>
