export const state = () => ({
  currentMonth: null,
  filters: [],
  isFirstScroll: true,
  isLeadersCalendar: false,
  isScrollToMonth: false,
  showCalendarFilters: false,
  showCalendarMenu: false,
  showCalendarNotifications: false,
  showFilterBar: true,
  showLeadersCalendar: false,
  notifications: [],
  hasNewNotification: false,
})

export const mutations = {
  setCurrentMonth (state, currentMonth) {
    state.currentMonth = currentMonth
  },
  setFilters (state, filters) {
    state.filters = filters
  },
  setIsFirstScroll (state, isFirstScroll) {
    state.isFirstScroll = isFirstScroll
  },
  setIsLeadersCalendar (state, isLeadersCalendar) {
    state.isLeadersCalendar = isLeadersCalendar
  },
  setIsScrollToMonth (state, isScrollToMonth) {
    state.isScrollToMonth = isScrollToMonth
  },
  setShowCalendarFilters (state, showCalendarFilters) {
    state.showCalendarFilters = showCalendarFilters
  },
  setShowCalendarMenu (state, showCalendarMenu) {
    state.showCalendarMenu = showCalendarMenu
  },
  setShowCalendarNotifications (state, showCalendarNotifications) {
    state.showCalendarNotifications = showCalendarNotifications
    if (!showCalendarNotifications) {
      // this.$axios.$patch('/profile/update/last-seen-calendar-notifications-at')
      state.hasNewNotification = false
      state.notifications = state.notifications?.map((notification) => {
        notification.isRead = true
        return notification
      })
    }
  },
  setShowFilterBar (state, showFilterBar) {
    state.showFilterBar = showFilterBar
  },
  setShowLeadersCalendar (state, showLeadersCalendar) {
    state.showLeadersCalendar = showLeadersCalendar
  },
  setNotifications (state, notifications) {
    state.notifications = notifications
  },
  setHasNewNotification (state, hasNewNotification) {
    state.hasNewNotification = hasNewNotification
  },
}

export const actions = {
  updateCurrentMonth ({ commit }, currentMonth) {
    commit('setCurrentMonth', currentMonth)
  },
  updateFilters ({ commit }, filters) {
    commit('setFilters', filters)
  },
  updateIsFirstScroll ({ commit }, isFirstScroll) {
    commit('setIsFirstScroll', isFirstScroll)
  },
  updateIsLeadersCalendar ({ commit }, isLeadersCalendar) {
    commit('setIsLeadersCalendar', isLeadersCalendar)
  },
  updateIsScrollToMonth ({ commit }, isScrollToMonth) {
    commit('setIsScrollToMonth', isScrollToMonth)
  },
  updateShowCalendarFilters ({ commit }, showCalendarFilters) {
    commit('setShowCalendarFilters', showCalendarFilters)
  },
  updateShowCalendarMenu ({ commit }, showCalendarMenu) {
    commit('setShowCalendarMenu', showCalendarMenu)
  },
  updateShowCalendarNotifications ({ commit }, showCalendarNotifications) {
    commit('setShowCalendarNotifications', showCalendarNotifications)
  },
  updateShowFilterBar ({ commit }, showFilterBar) {
    commit('setShowFilterBar', showFilterBar)
  },
  updateShowLeadersCalendar ({ commit }, showLeadersCalendar) {
    commit('setShowLeadersCalendar', showLeadersCalendar)
  },
  updateNotifications ({ commit }, notifications) {
    commit('setNotifications', notifications)
  },
  updateHasNewNotification ({ commit }, hasNewNotification) {
    commit('setHasNewNotification', hasNewNotification)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
