<template>
  <header
    class="home-top-nav flex items-start lg:items-center justify-between fixed top-0 left-0 right-0 z-[100] pt-[34px] lg:pt-[48px]"
    :class="{ '!pt-[54px]': isIos() }"
  >
    <div class="relative flex flex-row w-full justify-between mb-[12px]">
      <div
        class="w-[130px] lg:w-[200px] h-[28px] inline-flex items-center ml-[24px]"
      >
        <router-link to="/" class="inline-flex theme-logo">
          <img
            src="../../../public/assets/icons/logo-heart-cross.svg"
            class="w-[28px] h-[28px] mr-[10px]"
            alt="hogc logo"
          />
          <h1
            class="text-[18px] lg:text-[24px] not-italic font-bold text-[#373E49] mt-[6px]"
          >
            HOGC One
          </h1>
        </router-link>
      </div>

      <div
        class="lg:hidden mr-[24px] relative cursor-pointer"
        data-event-tracking="profile-icon"
        @click="updates()"
      >
        <p
          v-if="unreadCount > 0"
          class="absolute top-[12px] left-[18px] rounded-full bg-[#FF6600] w-min min-w-[16px] h-[16px] text-[12px] text-center leading-[16px] px-[4px] font-bold text-[#FFFFFF]"
        >
          {{ unreadCount }}
        </p>
        <component :is="updatesIconSwap()" class="h-[36px] w-[36px]" />
      </div>
    </div>
  </header>
</template>

<script>
// import Avatar from '../icons/Avatar.vue'
import { isIosNativeOnly } from "@/utils/platform-check";
import { mapState } from "vuex";
import UpdatesIcon from "../icons/UpdatesIcon.vue";
import UnreadUpdatesIcon from "../icons/UnreadUpdatesIcon.vue";

export default {
  // components: {
  //   Avatar,
  // },
  computed: {
    ...mapState("updates", ["unreadCount"]),
  },
  methods: {
    async updates() {
      await this.$store.dispatch("updates/getUpdates", "all");
      await this.$store.commit("updates/setActiveButton", "all");
      this.$router.push("/updates");
    },
    isIos() {
      return isIosNativeOnly();
    },
    updatesIconSwap() {
      // if (this.unreadCount > 0) {
      //   return UnreadUpdatesIcon;
      // } else {
      //   return UpdatesIcon;
      // }
      return UpdatesIcon;
    },
  },
};
</script>

<style scoped>
@import url("https://use.typekit.net/jne6owq.css");

.home-top-nav {
  background: linear-gradient(135deg, #ffe543 0.13%, #ffcd19 100.13%);
}
</style>
