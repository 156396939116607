<template>
  <section id="stories-section" class="w-full max-w-[837px] mx-auto">
    <FD2024Story
      scroll-trigger-id="fd-2024-story-2"
      :resource="resourceTwo"
      class="mb-[48px] md:mb-[86px] mx-auto"
      :images="photosTwo"
      name="Cordell Ng and Claire Ng"
    />
    <FD2024Story
      scroll-trigger-id="fd-2024-story-1"
      :resource="resourceOne"
      class="mb-[48px] md:mb-[86px]"
      :images="photosOne"
      name="Andy Yee"
    />
    <FD2024Story
      scroll-trigger-id="fd-2024-story-3"
      :resource="resourceThree"
      class="mb-[48px] md:mb-[86px]"
      :images="photosThree"
      name="Nadine Leong"
    />
    <FD2024Story
      scroll-trigger-id="fd-2024-story-4"
      :resource="resourceFour"
      class="mb-[140px]"
      :images="photosFour"
      name="Klennan Low"
    />
  </section>
</template>

<script>
import FD2024Story from '@/components/hogcfd/FD2024Story.vue'

export default {
  components: { FD2024Story },
  props: {
    resource: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      resourceOne: null,
      resourceTwo: null,
      resourceThree: null,
      resourceFour: null,
      photosOne: [
        {
          img: 'https://hogc-one.s3.ap-southeast-1.amazonaws.com/fd2024/Andy-cover.png',
          tracking: '',
        },
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Andy-1.png',
          tracking: '',
        },
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Andy-2.png',
          tracking: '',
        },
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Andy-3.png',
          tracking: '',
        },
      ],
      photosTwo: [
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Cordell-and-Claire-cover.png',
          tracking: '',
        },
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Cordell-and-Claire-3.png',
          tracking: '',
        },
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Cordell-and-Claire-2.png',
          tracking: '',
        },
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Cordell-and-Claire-5.png',
          tracking: '',
        },
      ],
      photosThree: [
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Nadine-Leong-cover.png',
          tracking: '',
        },
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Nadine-1.png',
          tracking: '',
        },
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Nadine-2.png',
          tracking: '',
        },
      ],
      photosFour: [
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Klennan-cover.png',
          tracking: '',
        },
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/Klennan-1.png',
          tracking: '',
        },
        {
          img: 'https://d1ocj872pzimwq.cloudfront.net/fd2024/klennan-last.png',
          tracking: '',
        },
      ],
    }
  },
  created () {
    const stories = this.resource.content
    this.resourceOne = { content: [stories[0]], title: null }
    this.resourceTwo =
      stories.length > 1
        ? { content: [stories[1]], title: null }
        : { content: [stories[0]], title: null }
    this.resourceThree =
      stories.length > 2
        ? { content: [stories[2]], title: null }
        : {
            content: [stories[0]],
            title: null,
          }
    this.resourceFour =
      stories.length > 3
        ? { content: [stories[3]], title: null }
        : { content: [stories[0]], title: null }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;700&display=swap');

.cormorant-garamond-medium {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 500;
  font-style: normal;
}

.cormorant-garamond-bold {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 700;
  font-style: normal;
}
</style>
