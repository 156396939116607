<template>
  <FDLayout>
  <div
    class="flex flex-col w-full overscroll-y-none overflow-y-scroll overflow-x-hidden justify-start"
  >
    <div class="h-[100vh] w-[100vw] overscroll-y-none">
      <div class="pt-[123px] px-[24px] sm:px-0 sm:max-w-[678px] mx-auto">
        <FDTitle year="2015" />
        <p
          class="font-source-sans-3 text-[19px] sm:text-[20px] leading-[28px] sm:leading-[30px] mt-[41px] sm:mt-[56px]"
        >
          This Father’s Day, I am thinking of you and smiling.<br />
          I am smiling because I know that through Christ, YOU have an amazing future and a great
          destiny.<br /><br />
          I want to give you this keychain to remind you of how much I believe in you.<br /><br />
          <b>1. Keys represent responsibility.</b><br />
          No parent would give the house keys to an irresponsible child.<br />
          I know you are responsible because the ‘bad’ that you’ve gone through turned out to be
          ‘good’, because it has made you a more responsible person.<br />
          So today, on behalf of your father, I bestow upon you this key.<br /><br />
          <b>2. Keys represent closing the door to a bad past.</b><br />
          Close that door.<br />
          Your past does not determine your future.<br />
          Leave the past. Let it go. You can’t embrace the future holding on to the past.<br />
          You can’t run forward looking backwards.<br />
          Forgive who you need to forgive.<br />
          Forgive yourself too... if you have to.<br />
          Forget what you need to forget.<br />
          Most people remember what they should forget and forget what they should remember.<br />
          Only remember God and His goodness.<br /><br />
          <b>3. Keys represent opening the door to your great future.</b><br />
          Unlock the door to your destiny.<br />
          Fling wide the gates.<br />
          Step through and step out.<br />
          Be brave.<br />
          David ran towards the giant.<br />
          David fought the lion and bear in preparation.<br />
          You have fought your battles in life. <br class="sm:hidden" />Now you are stronger.<br />
          You can conquer your giants.<br />
          You can conquer your future.<br /><br />
          Let your future begin!
        </p>
        <FDSignOff class="mt-[48px]" year="2015" />
      </div>

      <FDFooter
        back-text="2014 letter"
        forward-text="2016 letter"
        back-slug="/hogcfd/2014"
        forward-slug="/hogcfd/2016"
        class="mt-[48px]"
      />
      <div class="pt-[96px] mt-[96px] lg:mt-0" />
    </div>
  </div>
</FDLayout>
</template>

<script>
import FDSignOff from '@/components/hogcfd/FDSignOff'
import FDTitle from '@/components/hogcfd/FDTitle'
import FDFooter from '@/components/hogcfd/FDFooter'
import FDLayout from '@/components/FDLayout.vue'

export default {
  components: {
    FDTitle,
    FDFooter,
    FDSignOff,
    FDLayout
  },
}
</script>
