<template>
  <svg
    v-if="!isActive"
    class="block"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 14V9.5C11 8.30653 11.5268 7.16193 12.4645 6.31802C13.4021 5.47411 14.6739 5 16 5C17.3261 5 18.5979 5.47411 19.5355 6.31802C20.4732 7.16193 21 8.30653 21 9.5V14"
      stroke="#6245F4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.4286 13H9.57143C8.15127 13 7 14.3431 7 16V25C7 26.6569 8.15127 28 9.57143 28H22.4286C23.8487 28 25 26.6569 25 25V16C25 14.3431 23.8487 13 22.4286 13Z"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.25 23C17.0784 23 17.75 22.3284 17.75 21.5C17.75 20.6716 17.0784 20 16.25 20C15.4216 20 14.75 20.6716 14.75 21.5C14.75 22.3284 15.4216 23 16.25 23Z"
      fill="#6245F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.25 20.75C15.8358 20.75 15.5 21.0858 15.5 21.5C15.5 21.9142 15.8358 22.25 16.25 22.25C16.6642 22.25 17 21.9142 17 21.5C17 21.0858 16.6642 20.75 16.25 20.75ZM14 21.5C14 20.2574 15.0074 19.25 16.25 19.25C17.4926 19.25 18.5 20.2574 18.5 21.5C18.5 22.7426 17.4926 23.75 16.25 23.75C15.0074 23.75 14 22.7426 14 21.5Z"
      fill="#6245F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.25 20C15.9739 20 15.75 19.7761 15.75 19.5V17.5C15.75 17.2239 15.9739 17 16.25 17C16.5261 17 16.75 17.2239 16.75 17.5V19.5C16.75 19.7761 16.5261 20 16.25 20Z"
      fill="#6245F4"
    />
    <path
      d="M17.25 21.5C17.25 22.0523 16.8023 22.5 16.25 22.5V23.5C17.3546 23.5 18.25 22.6046 18.25 21.5H17.25ZM16.25 22.5C15.6977 22.5 15.25 22.0523 15.25 21.5H14.25C14.25 22.6046 15.1454 23.5 16.25 23.5V22.5ZM15.25 21.5C15.25 20.9477 15.6977 20.5 16.25 20.5V19.5C15.1454 19.5 14.25 20.3954 14.25 21.5H15.25ZM16.25 20.5C16.8023 20.5 17.25 20.9477 17.25 21.5H18.25C18.25 20.3954 17.3546 19.5 16.25 19.5V20.5ZM16 21.5C16 21.3619 16.1119 21.25 16.25 21.25V20.25C15.5596 20.25 15 20.8096 15 21.5H16ZM16.25 21.75C16.1119 21.75 16 21.6381 16 21.5H15C15 22.1904 15.5596 22.75 16.25 22.75V21.75ZM16.5 21.5C16.5 21.6381 16.3881 21.75 16.25 21.75V22.75C16.9404 22.75 17.5 22.1904 17.5 21.5H16.5ZM16.25 21.25C16.3881 21.25 16.5 21.3619 16.5 21.5H17.5C17.5 20.8096 16.9404 20.25 16.25 20.25V21.25ZM16.25 18.75C14.7312 18.75 13.5 19.9812 13.5 21.5H14.5C14.5 20.5335 15.2835 19.75 16.25 19.75V18.75ZM19 21.5C19 19.9812 17.7688 18.75 16.25 18.75V19.75C17.2165 19.75 18 20.5335 18 21.5H19ZM16.25 24.25C17.7688 24.25 19 23.0188 19 21.5H18C18 22.4665 17.2165 23.25 16.25 23.25V24.25ZM13.5 21.5C13.5 23.0188 14.7312 24.25 16.25 24.25V23.25C15.2835 23.25 14.5 22.4665 14.5 21.5H13.5ZM15.25 19.5C15.25 20.0523 15.6977 20.5 16.25 20.5V19.5H15.25ZM15.25 17.5V19.5H16.25V17.5H15.25ZM16.25 16.5C15.6977 16.5 15.25 16.9477 15.25 17.5H16.25V16.5ZM17.25 17.5C17.25 16.9477 16.8023 16.5 16.25 16.5V17.5H17.25ZM17.25 19.5V17.5H16.25V19.5H17.25ZM16.25 20.5C16.8023 20.5 17.25 20.0523 17.25 19.5H16.25V20.5Z"
      fill="#6245F4"
    />
  </svg>

  <svg
    v-else
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 14V9.5C11 8.30653 11.5268 7.16193 12.4645 6.31802C13.4021 5.47411 14.6739 5 16 5C17.3261 5 18.5979 5.47411 19.5355 6.31802C20.4732 7.16193 21 8.30653 21 9.5V14"
      stroke="#6245F4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.4286 13H9.57143C8.15127 13 7 14.3431 7 16V25C7 26.6569 8.15127 28 9.57143 28H22.4286C23.8487 28 25 26.6569 25 25V16C25 14.3431 23.8487 13 22.4286 13Z"
      fill="#6245F4"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.25 23C17.0784 23 17.75 22.3284 17.75 21.5C17.75 20.6716 17.0784 20 16.25 20C15.4216 20 14.75 20.6716 14.75 21.5C14.75 22.3284 15.4216 23 16.25 23Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.25 20.75C15.8358 20.75 15.5 21.0858 15.5 21.5C15.5 21.9142 15.8358 22.25 16.25 22.25C16.6642 22.25 17 21.9142 17 21.5C17 21.0858 16.6642 20.75 16.25 20.75ZM14 21.5C14 20.2574 15.0074 19.25 16.25 19.25C17.4926 19.25 18.5 20.2574 18.5 21.5C18.5 22.7426 17.4926 23.75 16.25 23.75C15.0074 23.75 14 22.7426 14 21.5Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.25 20C15.9739 20 15.75 19.7761 15.75 19.5V17.5C15.75 17.2239 15.9739 17 16.25 17C16.5261 17 16.75 17.2239 16.75 17.5V19.5C16.75 19.7761 16.5261 20 16.25 20Z"
      fill="white"
    />
    <path
      d="M17.25 21.5C17.25 22.0523 16.8023 22.5 16.25 22.5V23.5C17.3546 23.5 18.25 22.6046 18.25 21.5H17.25ZM16.25 22.5C15.6977 22.5 15.25 22.0523 15.25 21.5H14.25C14.25 22.6046 15.1454 23.5 16.25 23.5V22.5ZM15.25 21.5C15.25 20.9477 15.6977 20.5 16.25 20.5V19.5C15.1454 19.5 14.25 20.3954 14.25 21.5H15.25ZM16.25 20.5C16.8023 20.5 17.25 20.9477 17.25 21.5H18.25C18.25 20.3954 17.3546 19.5 16.25 19.5V20.5ZM16 21.5C16 21.3619 16.1119 21.25 16.25 21.25V20.25C15.5596 20.25 15 20.8096 15 21.5H16ZM16.25 21.75C16.1119 21.75 16 21.6381 16 21.5H15C15 22.1904 15.5596 22.75 16.25 22.75V21.75ZM16.5 21.5C16.5 21.6381 16.3881 21.75 16.25 21.75V22.75C16.9404 22.75 17.5 22.1904 17.5 21.5H16.5ZM16.25 21.25C16.3881 21.25 16.5 21.3619 16.5 21.5H17.5C17.5 20.8096 16.9404 20.25 16.25 20.25V21.25ZM16.25 18.75C14.7312 18.75 13.5 19.9812 13.5 21.5H14.5C14.5 20.5335 15.2835 19.75 16.25 19.75V18.75ZM19 21.5C19 19.9812 17.7688 18.75 16.25 18.75V19.75C17.2165 19.75 18 20.5335 18 21.5H19ZM16.25 24.25C17.7688 24.25 19 23.0188 19 21.5H18C18 22.4665 17.2165 23.25 16.25 23.25V24.25ZM13.5 21.5C13.5 23.0188 14.7312 24.25 16.25 24.25V23.25C15.2835 23.25 14.5 22.4665 14.5 21.5H13.5ZM15.25 19.5C15.25 20.0523 15.6977 20.5 16.25 20.5V19.5H15.25ZM15.25 17.5V19.5H16.25V17.5H15.25ZM16.25 16.5C15.6977 16.5 15.25 16.9477 15.25 17.5H16.25V16.5ZM17.25 17.5C17.25 16.9477 16.8023 16.5 16.25 16.5V17.5H17.25ZM17.25 19.5V17.5H16.25V19.5H17.25ZM16.25 20.5C16.8023 20.5 17.25 20.0523 17.25 19.5H16.25V20.5Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
