<template>
  <svg :width="size" :height="size" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.75 24.0833V14.0833H0.749969V10.75H10.75V0.75H14.0833V10.75H24.0833V14.0833H14.0833V24.0833H10.75"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 25,
    },
    fill: {
      type: String,
      default: 'black',
    },
  },
}
</script>
