<template>
  <div class="flex flex-col justify-center">
    <FDFooterUnderline class="mx-auto" />
    <div class="flex justify-between px-[24px] mt-[40px] w-[100vw] sm:max-w-[678px] mx-auto">
      <FDButton :text="backText" :linkto="backSlug" />
      <FDButton :text="forwardText" :forward="true" :linkto="forwardSlug" />
    </div>
  </div>
</template>

<script>
import FDButton from '@/components/hogcfd/FDButton'
import FDFooterUnderline from '../../../public/assets/icons/hogcfd/FDFooterUnderline'

export default {
  components: { FDButton, FDFooterUnderline },
  props: {
    backText: {
      type: String,
      default: '',
    },
    backSlug: {
      type: String,
      default: '',
    },
    forwardText: {
      type: String,
      default: '',
    },
    forwardSlug: {
      type: String,
      default: '',
    },
  },
}
</script>
