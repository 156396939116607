<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1535_53285)">
      <path
        d="M6 14C5.45 14 4.979 13.804 4.587 13.412C4.195 13.02 3.99934 12.5493 4 12C4 11.45 4.196 10.979 4.588 10.587C4.98 10.195 5.45067 9.99934 6 10C6.55 10 7.021 10.196 7.413 10.588C7.805 10.98 8.00067 11.4507 8 12C8 12.55 7.804 13.021 7.412 13.413C7.02 13.805 6.54934 14.0007 6 14ZM12 14C11.45 14 10.979 13.804 10.587 13.412C10.195 13.02 9.99934 12.5493 10 12C10 11.45 10.196 10.979 10.588 10.587C10.98 10.195 11.4507 9.99934 12 10C12.55 10 13.021 10.196 13.413 10.588C13.805 10.98 14.0007 11.4507 14 12C14 12.55 13.804 13.021 13.412 13.413C13.02 13.805 12.5493 14.0007 12 14ZM18 14C17.45 14 16.979 13.804 16.587 13.412C16.195 13.02 15.9993 12.5493 16 12C16 11.45 16.196 10.979 16.588 10.587C16.98 10.195 17.4507 9.99934 18 10C18.55 10 19.021 10.196 19.413 10.588C19.805 10.98 20.0007 11.4507 20 12C20 12.55 19.804 13.021 19.412 13.413C19.02 13.805 18.5493 14.0007 18 14Z"
        :fill="fill"
      />
    </g>
    <defs>
      <clipPath id="clip0_1535_53285">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#000000',
    },
  },
}
</script>
