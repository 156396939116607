<template>
  <svg
    v-if="!isActive"
    class="block"
    width="25"
    height="25"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.1 5.9L25.05 6.95M3 15.5H4.5H3ZM16.5 2V3.5V2ZM28.5 15.5H30H28.5ZM6.9 5.9L7.95 6.95L6.9 5.9Z"
      stroke="#99A4B2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 21.5C10.7407 20.5555 9.8105 19.2388 9.34115 17.7363C8.8718 16.2337 8.8871 14.6216 9.38488 13.1283C9.88266 11.635 10.8377 10.3361 12.1147 9.41568C13.3917 8.49528 14.9259 8 16.5 8C18.0741 8 19.6083 8.49528 20.8853 9.41568C22.1623 10.3361 23.1173 11.635 23.6151 13.1283C24.1129 14.6216 24.1282 16.2337 23.6589 17.7363C23.1895 19.2388 22.2593 20.5555 21 21.5C20.4144 22.0797 19.9734 22.7892 19.7128 23.5709C19.4522 24.3527 19.3793 25.1848 19.5 26C19.5 26.7956 19.1839 27.5587 18.6213 28.1213C18.0587 28.6839 17.2956 29 16.5 29C15.7044 29 14.9413 28.6839 14.3787 28.1213C13.8161 27.5587 13.5 26.7956 13.5 26C13.6207 25.1848 13.5478 24.3527 13.2872 23.5709C13.0266 22.7892 12.5856 22.0797 12 21.5Z"
      stroke="#99A4B2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.05 23H19.95"
      stroke="#99A4B2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.34115 17.7363C9.8105 19.2388 10.7407 20.5555 12 21.5C12.5856 22.0797 12.8138 22.2182 13.0744 23H19.5C19.7606 22.2182 20.4144 22.0797 21 21.5C22.2593 20.5555 23.1895 19.2388 23.6589 17.7363C24.1282 16.2337 24.1129 14.6216 23.6151 13.1283C23.1173 11.635 22.1623 10.3361 20.8853 9.41568C19.6083 8.49528 18.0741 8 16.5 8C14.9259 8 13.3917 8.49528 12.1147 9.41568C10.8377 10.3361 9.88266 11.635 9.38488 13.1283C8.8871 14.6216 8.8718 16.2337 9.34115 17.7363Z"
      stroke="#99A4B2"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else
    width="25"
    height="25"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6 5.9L24.55 6.95M2.5 15.5H4H2.5ZM16 2V3.5V2ZM28 15.5H29.5H28ZM6.4 5.9L7.45 6.95L6.4 5.9Z"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5 21.5C10.2407 20.5555 9.3105 19.2388 8.84115 17.7363C8.3718 16.2337 8.3871 14.6216 8.88488 13.1283C9.38266 11.635 10.3377 10.3361 11.6147 9.41568C12.8917 8.49528 14.4259 8 16 8C17.5741 8 19.1083 8.49528 20.3853 9.41568C21.6623 10.3361 22.6173 11.635 23.1151 13.1283C23.6129 14.6216 23.6282 16.2337 23.1589 17.7363C22.6895 19.2388 21.7593 20.5555 20.5 21.5C19.9144 22.0797 19.4734 22.7892 19.2128 23.5709C18.9522 24.3527 18.8793 25.1848 19 26C19 26.7956 18.6839 27.5587 18.1213 28.1213C17.5587 28.6839 16.7956 29 16 29C15.2044 29 14.4413 28.6839 13.8787 28.1213C13.3161 27.5587 13 26.7956 13 26C13.1207 25.1848 13.0478 24.3527 12.7872 23.5709C12.5266 22.7892 12.0856 22.0797 11.5 21.5Z"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.55 23H19.45"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.84115 17.7363C9.3105 19.2388 10.2407 20.5555 11.5 21.5C12.0856 22.0797 12 22.5 12.5744 23H19.5C20 22.5 19.9144 22.0797 20.5 21.5C21.7593 20.5555 22.6895 19.2388 23.1589 17.7363C23.6282 16.2337 23.6129 14.6216 23.1151 13.1283C22.6173 11.635 21.6623 10.3361 20.3853 9.41568C19.1083 8.49528 17.5741 8 16 8C14.4259 8 12.8917 8.49528 11.6147 9.41568C10.3377 10.3361 9.38266 11.635 8.88488 13.1283C8.3871 14.6216 8.3718 16.2337 8.84115 17.7363Z"
      fill="#6245F4"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
