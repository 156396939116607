<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.88057 19.8369C4.54054 18.6012 2.43238 16.6914 1.51332 13.818C0.598202 10.957 1.61925 6.76058 4.82947 5.89863C5.9996 5.58445 7.81463 5.71823 8.84274 6.62121C9.87086 7.52418 10.057 8.17931 10.2256 8.69367C10.8394 8.0139 11.368 7.61865 11.9648 7.33228C12.2458 7.19741 12.7838 6.90845 13.5909 6.90845C15.5641 6.90845 16.8131 7.89491 17.7644 9.71582C18.3447 10.8264 18.643 12.9953 18.1475 14.2965C17.7151 15.6142 17.0052 16.6048 16.3603 17.3067C14.7607 19.0478 13.5988 20.0087 12.4739 20.8472C11.7084 21.4177 8.16876 23.507 5.65897 23.7739C3.98907 23.9515 1.74473 24.1189 0.0279052 23.7739C-0.196877 23.7287 0.969581 23.7796 2.32989 23.4293C2.51436 23.3818 1.54726 23.2712 1.74473 23.2523C2.97829 23.1341 4.62622 22.6497 5.65897 22.2682C7.69565 21.5159 9.52344 20.3954 10.6417 19.6446C11.76 18.8939 12.5872 18.2141 13.525 17.4315C13.8302 17.1768 14.3981 16.5857 14.6731 16.2914C15.0593 15.8781 15.1562 15.7597 15.5077 15.3096C15.7341 15.0198 15.8512 14.8245 16.1057 14.3691C16.3603 13.9136 17.1071 12.0439 16.1057 10.3681C15.1044 8.69225 13.8391 8.95066 12.6712 9.45249C11.3196 10.0333 10.5447 11.2203 10.057 11.62C9.6132 10.8369 9.44139 10.5353 8.84274 9.71582C8.2441 8.8964 7.45058 8.24861 6.4947 8.05042C4.52836 7.64272 2.86165 9.57733 3.06496 12.058C3.21064 13.8355 4.15408 15.0005 4.15408 15.0005C4.15408 15.0005 5.82796 16.9561 6.07733 17.2217C6.32989 17.517 6.67286 17.9173 6.8027 18.0688C6.69251 18.0799 6.59159 18.0949 6.4095 18.0949C6.43843 18.1582 6.60113 18.2862 6.65922 18.3891C6.57056 18.4138 6.52362 18.4331 6.4095 18.4538C6.4586 18.5434 6.72209 18.9065 6.8027 19.0195C6.8027 19.0195 6.4793 18.9999 6.32989 19.0195C6.4395 19.1918 6.88057 19.8369 6.88057 19.8369Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3776 0C18.3776 0 19.3756 1.56903 19.5661 2.79348C20.2556 2.86957 21.7958 2.31176 21.7958 2.31176L22.0485 4.28952C22.0485 4.28952 21.0566 4.21909 20.5393 4.28952C20.0219 4.35996 19.8326 4.40321 19.7147 4.47079C19.7861 5.28035 19.7861 6.0553 19.849 6.5348C19.9118 7.01431 20.2441 8.27598 20.41 8.65393C20.4793 8.812 20.6724 9.51884 20.9234 10.3055C21.2726 11.3998 21.669 12.6689 21.7614 13.0112C21.8312 13.2696 21.9513 13.7898 22.0009 14.3659C22.0009 15.1382 22.0485 15.904 22.0485 16.1074C22.0485 16.47 22.5419 17.5799 22.6666 17.9159C22.8766 18.4821 23.0543 19.2763 23.3405 20.2188C23.5766 20.9962 23.8884 21.7218 23.9417 21.8573C24.0595 22.1572 21.9034 20.2871 21.7614 20.0891C21.6802 19.7591 21.5597 19.2324 21.4144 18.6658C21.269 18.0992 20.8352 17.2403 20.7551 16.6358C20.7244 16.4039 20.6611 16.1274 20.6334 15.8094C20.5888 15.2989 20.6334 14.7799 20.5393 14.3659C20.3393 13.4479 19.3457 10.7104 19.0957 9.85896C18.8458 9.00754 18.2985 7.4075 18.1961 6.69094C18.0936 5.97438 18.0059 5.56953 17.9118 4.76065C17.3591 4.69488 16.7899 4.67267 16.3475 4.6829C15.8292 4.69488 15.4171 4.76898 14.6584 4.95101C13.8626 5.14194 13.4294 5.29228 12.8167 5.37952C12.207 5.46633 11.5345 5.57498 10.5449 5.57498C9.94853 5.51911 9.51422 5.43294 9.18506 5.0411C10.4326 4.43293 11.8746 4.08353 12.8167 3.87667C13.915 3.59546 14.7404 3.04938 15.5191 3.04938C16.2978 3.04938 17.6873 3.04938 17.6873 3.04938C17.6873 3.04938 17.6803 2.31724 17.4873 1.97045C17.2943 1.62366 17.1873 1.10489 17.1079 0.802883C17.3095 0.590249 18.3776 0 18.3776 0Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#FFFFFF',
    },
  },
}
</script>
