<template>
  <div
    class="flex items-center py-[8px] gap-[10px] rounded-[8px] border-[1px] border-solid border-[#CACACE] bg-[#FFFFFF] h-[40px]"
  >
    <img
      src="@/../public/assets/icons/home/search-dark.svg"
      class="cursor-pointer"
    />
    <input
      v-model="searchTerm"
      type="text"
      placeholder="Search for church resources"
      @input="onSearch()"
    />
    <img
      src="@/../public/assets/icons/home/search-clear.svg"
      class="cursor-pointer self-end"
      @click="clearSearch()"
    />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    onSearch() {
      this.$emit("submit-search", this.searchTerm);
    },
    clearSearch() {
      this.searchTerm = "";
      this.onSearch();
    },
  },
};
</script>

<style scoped>
input {
  /* Body 2 */
  background-color: #FFFFFF;
  font-family: Proxima Nova;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  border: none;
  width: 100%;
}

input:focus {
  outline: none;
}
</style>
