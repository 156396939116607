<template>
    <div>
      <img
        src="../../../public/assets/icons/chevron-left-white.svg"
        class="absolute top-[24px] left-6 lg:mt-[80px] cursor-pointer theme-back-button mt-[60px]"
        alt="Back icon"
        @click="back"
      />
      <div v-if="loading" class="mt-[114px] lg:mt-[144px]">Loading...</div>
      <div v-else-if="personalId" class="flex flex-col mt-[114px] lg:mt-[144px]">
        <p class="font-bold mx-auto text-[20px] mb-[12px]">
          Personal ID: {{ personalId }}
        </p>
        <div
          class="
            w-[296px]
            h-[280px]
            bg-[#FFD629]
            rounded-[16px]
            flex flex-col
            pt-[16px]
            px-[48px]
            pb-[32px]
          "
        >
          <p class="mx-auto mb-[8px]">My QR Code</p>
          <section
            class="
              bg-[#ffffff]
              rounded-[16px]
              h-[200px]
              w-[200px]
              flex
              items-center
              justify-center
            "
          >
            <qrcode-vue :value="personalId" :size="160" level="H" />
          </section>
        </div>
      </div>
      <div
        v-else
        class="
          m-4
          w-[335px]
          h-[288px]
          lg:m-8 lg:w-[400px] lg:h-[248px]
          mt-[114px]
          lg:mt-[144px]
        "
      >
        <h1 class="text-[24px] leading-8 not-italic font-bold text-[##0B0C0F]">
          Oops, you’re missing something!
        </h1>
        <p
          class="
            mt-4
            text-[20px]
            leading-6
            not-italic
            font-normal
            text-[##0B0C0F]
          "
        >
          {{ errorMsg }}
        </p>
        <BaseButton
          v-if="!dob || !uin"
          full-width
          style="line-height: 24px"
          class="mt-10"
          solid-classes="bg-[#6245F4] text-white"
          @click="editProfile"
        >
          Edit Profile
        </BaseButton>
      </div>
    </div>
  </template>
  <script>
  import { mapState } from 'vuex'
  import BaseButton from '@/components/design/BaseButton.vue'
  import QrcodeVue from 'qrcode.vue'

  export default {
    components: {
      BaseButton,
      QrcodeVue,
    },

    computed: {
      ...mapState('profile', ['dob', 'uin', 'personalId', 'loading']),
      errorMsg () {
        let details = ''
        if (!this.dob && !this.uin) {
          details = 'last 4 characters of your NRIC/FIN and Date of Birth'
        } else if (!this.dob) {
          details = 'Date of Birth'
        } else if (!this.uin) {
          details = 'last 4 characters of your NRIC/FIN'
        } else if (!this.personalId) {
          return 'There a hiccup in generating your Personal ID. Fret not, our friendly IT team will be in touch with you over email / SMS.'
        }
        return `Update your ${details} under ‘Edit Profile’ to view your unique QR code here!`
      },
    },

    methods: {
      back () {
        this.$router.push('/')
      },
      editProfile () {
        this.$router.push('/profile')
      },
    },
  }
  </script>
