<template>
  <div class="flex lg:max-w-[418px] w-full mx-auto pt-[12px]">
    <button
      class="h-5 not-italic font-bold text-base leading-5 text-indigo-600 font-proxima disabled:pointer-events-none"
      :disabled="!isLeader"
      @click="() => togglePopUp('buttonTrigger')"
    >
      <div v-if="!isLeadersCalendar">Church calendar</div>
      <div v-else>Leaders calendar</div>
    </button>
    <CalendarToggleOnboarding
      v-if="showOnboardingModal"
      @open-toggle-calendar-popup="openToggleCalendarPopup"
    />
    <CalendarToggleModal
      v-if="popupTrigger.buttonTrigger"
      :toggle-popup="() => togglePopUp('buttonTrigger')"
      @popup_cal_category="(category) => selectCalendar(category)"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { mapState } from "vuex";
import CalendarToggleModal from "@/components/Calendar/Toggle/CalendarToggleModal";
import CalendarToggleOnboarding from "@/components/Calendar/Toggle/CalendarToggleOnboarding";

export default {
  components: {
    CalendarToggleModal,
    CalendarToggleOnboarding,
  },
  setup() {
    const popupTrigger = ref({
      buttonTrigger: false,
    });
    const togglePopUp = (trigger) => {
      popupTrigger.value[trigger] = !popupTrigger.value[trigger];
    };
    return {
      CalendarToggleModal,
      popupTrigger,
      togglePopUp,
    };
  },
  computed: {
    ...mapState("calendar", ["isLeadersCalendar"]),
    ...mapState("profile", ["hasSeenLdrsCal", "isLeader"]),
    showOnboardingModal() {
      return !this.hasSeenLdrsCal && this.isLeader;
    },
  },
  watch: {
    showOnboardingModal(isShowing) {
      if (isShowing) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
    "popupTrigger.buttonTrigger"(isShowing) {
      if (isShowing) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
  methods: {
    async selectCalendar(category) {
      await this.$store.dispatch("calendar/updateFilters", []);
      if (category === "church") {
        await this.$store.dispatch("calendar/updateIsLeadersCalendar", false);
      } else {
        await this.$store.dispatch("calendar/updateIsLeadersCalendar", true);
      }
    },
    async openToggleCalendarPopup() {
      await this.$store.dispatch("profile/updateHasSeenLdrsCal", true);
      this.togglePopUp("buttonTrigger");
    },
  },
};
</script>
