<template>
  <Collapsible
    v-model="localValue"
    class="accordion border border-[#D3D8DE] rounded-[12px]"
    @opened="$emit('opened')"
    @closed="$emit('closed')"
  >
    <template #trigger="{ isOpen, toggle }">
      <div
        class="w-full header px-[16px] sm:px-[20px] py-[16px] flex cursor-pointer rounded-t-[12px]"
        :class="isOpen ? 'theme-accordian-bg bg-[#FEDB00] rounded-b-0' : 'bg-[#F0F2F4] rounded-b-[12px]'"
        @click="toggle"
      >
        <div class="w-[37px]">
          <img :src="icon" alt="" class=" w-[20px] h-[20px]" :class="isOpen ? 'theme-accordian-icon' : ''"/>
        </div>
        <h3
          class="text-[20px] leading-[24px] font-bold"
          :class="isOpen ? 'theme-accordian-text text-[#0B0C0F]' : 'text-[#373E49]'"
        >
          {{ title }}
        </h3>
        <div class="ml-auto flex">
          <div v-if="accordian" class="accordianCircle ml-auto mt-[7px] mr-[15px]" />
          <img src="../../../public/assets/icons/chevron-down.svg" alt="" :class="{ 'rotate-180 theme-accordian-icon': isOpen }" />
        </div>
      </div>
    </template>
    <template #collapsible="{ isOpen }">
      <div
        v-show="isOpen"
        class="w-full body sm:pb-[40px] sm:px-[32px] sm:pt-[32px] p-[16px] pb-[32px] bg-[#ffffff] rounded-b-[12px]"
      >
        <slot />
      </div>
    </template>
  </Collapsible>
</template>

<script>
import Collapsible from '@/components/design/Collapsible'
import HasLocalValue from '@/mixins/HasLocalValue'

export default {
  components: {
    Collapsible,
  },

  mixins: [HasLocalValue],

  props: {
    icon: {
      type: [Object, String],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    initialOpen: {
      type: Boolean,
      default: false,
    },
    accordian: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  created () {},
}
</script>

<style scoped>
.accordianCircle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: red;
}
</style>
