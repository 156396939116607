<template>
  <birthday-layout>
  <div>
    <BoardContent class="pt-[152px] md:pt-[200px]" :is-hidden-messages="true" />
  </div>
</birthday-layout>
</template>

<script>
import BoardContent from '@/components/Board/Main/BoardContent'
import BirthdayLayout from "@/components/BirthdayLayout.vue";

export default {
  components: {
    BoardContent,
    BirthdayLayout,
  },
}
</script>
