import InputField from '@/components/design/InputField'

export default {
  components: {
    InputField,
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default() {
        return 'input-text-' + Math.floor(Math.random() * 10000)
      },
    },
    error: {
      type: String,
      default: null,
    },
    helper: {
      type: String,
      default: null,
    },
  },

  computed: {
    errorClasses() {
      return this.error ? 'border-[#B80A0A]' : ''
    },
  },
}
