<template>
  <master-layout>
    <div class="w-full">
      <div
        class="flex-1 max-w-[640px] flex justify-center items-start pt-[32px] pb-[58px] px-[24px] mx-auto sm:py-[64px]"
      >
        <div class="max-w-[680px] w-full z-10">
          <h1
            class="text-[24px] leading-[32px] font-bold mb-[16px] sm:mb-[24px]"
          >
            Update Profile
          </h1>
          <div
            v-if="almostDone"
            class="p-[12px] mb-[16px] flex rounded-[8px]"
            style="background-color: #ebf3ff"
          >
            <div class="mr-[12px]">
              <img
                src="../../../public/assets/icons/profile/information.svg"
                class="h-[24px] min-w-[24px] w-[24px]"
                alt="Warning"
              />
            </div>
            <div class="text-[16px] leading-[24px] font-[400]">
              You’re almost done! Your profile is off to a great start. Take a
              moment to complete it.
            </div>
          </div>
          <div class="sm:space-y-[24px] space-y-[20px]">
            <FormCollapsible
              :icon="require('../../../public/assets/icons/profile/details.svg')"
              title="Personal Details"
              :accordian="personalDetailsHasErrors"
            >
              <FormRow>
                <div class="w-full sm:w-1/2 relative">
                  <TextInput
                    v-model="payload.fullname"
                    class="w-full mb-[32px]"
                    label="Full name"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!payload.fullname"
                    class="absolute right-0 top-0"
                  />
                </div>
                <div class="w-full sm:w-1/2 relative">
                  <BaseSelect
                    v-model="payload.nationalityId"
                    class="w-full mb-[32px]"
                    :options="nationalities"
                    value-field="id"
                    label-field="name"
                    label="Nationality"
                    width-classes="min-w-0"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!payload.nationalityId"
                    class="absolute right-0 top-0"
                  />
                </div>
              </FormRow>

              <FormRow>
                <div class="relative w-full sm:w-1/2 relative">
                  <MobileField
                    v-model="localMobileNumber"
                    :country-code="countryCode"
                    class="w-full"
                    @setCountryCode="setCountryCode"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!localMobileNumber"
                    class="absolute right-0 top-0"
                  />
                </div>
                <div class="relative w-full sm:w-1/2">
                  <EmailField
                    v-model="email"
                    :is-verified="emailVerified"
                    class="w-full"
                    @unverified="emailVerified = false"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!emailVerified"
                    class="absolute right-0 top-0"
                  />
                </div>
              </FormRow>

              <div class="mb-[32px] relative">
                <BaseSelect
                  v-model="payload.residentialId"
                  label="Residential area"
                  value-field="id"
                  label-field="name"
                  :options="residentialAreas"
                  class="w-full"
                />
                <ProfileIndicatorIcon
                  :completed="!!payload.residentialId"
                  class="absolute right-0 top-0"
                />
              </div>

              <FormRow>
                <div class="w-full sm:w-1/2 relative">
                  <BaseSelect
                    v-model="payload.gender"
                    label="Gender"
                    :options="genders"
                    value-field="id"
                    label-field="name"
                    class="relative mb-[32px] w-full"
                    :disabled="Boolean(originalPayload.gender)"
                    disabled-classes="text-[#99A4B2] border-[#D3D8DE] bg-[#F0F2F4]"
                    width-classes="min-w-0"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!payload.gender"
                    class="absolute right-0 top-0"
                  />
                </div>
                <div class="w-full sm:w-1/2 relative">
                  <div class="relative mb-[32px]">
                    <TextInput
                      v-model="cg"
                      label="CG"
                      class="w-full"
                      disabled-classes="disabled:text-[#99A4B2] disabled:border-[#D3D8DE] disabled:bg-[#F0F2F4]"
                      placeholder="None"
                      disabled
                    />
                    <ProfileIndicatorIcon
                      :completed="!!cg"
                      class="absolute right-0 top-0"
                    />
                  </div>
                </div>
              </FormRow>

              <FormRow>
                <div class="w-full sm:w-1/2 sm:mb-0 mb-[32px] relative">
                  <TextInput
                    v-model="payload.dob"
                    label="Date of birth"
                    type="date"
                    class="w-full"
                    placeholder="01/01/2000"
                    :disabled="originalPayload.dob_ddmm"
                    disabled-classes="disabled:text-[#99A4B2] disabled:border-[#D3D8DE] disabled:bg-[#F0F2F4]"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!payload.dob_ddmm"
                    class="absolute right-0 top-0"
                  />
                </div>
                <div class="w-full sm:w-1/2 relative">
                  <TextInput
                    v-model="payload.uin"
                    label="Partial NRIC/FIN"
                    class="w-full"
                    placeholder="e.g. 123C"
                    :disabled="originalPayload.uin"
                    disabled-classes="disabled:text-[#99A4B2] disabled:border-[#D3D8DE] disabled:bg-[#F0F2F4]"
                    maxlength="4"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!payload.uin"
                    class="absolute right-0 top-0"
                  />
                </div>
              </FormRow>
            </FormCollapsible>

            <FormCollapsible
              :icon="require('../../../public/assets/icons/profile/phase.svg')"
              title="Phase of Life"
              :accordian="phaseOfLifeHasErrors"
            >
              <div class="mb-[32px] relative">
                <BaseSelect
                  v-model="payload.phaseId"
                  class="w-full"
                  label="Stage of life"
                  :options="stagesOfLife"
                  value-field="id"
                  label-field="name"
                />
                <ProfileIndicatorIcon
                  :completed="!!payload.phaseId"
                  class="absolute right-0 top-0"
                />
              </div>

              <div
                v-if="payload.phaseId === 2"
                class="mb-[16px] w-full relative"
              >
                <BaseSelect
                  v-model="payload.courseIndustry"
                  label="Industry"
                  value-field="name"
                  label-field="name"
                  :options="industries"
                  class="w-full"
                />
                <ProfileIndicatorIcon
                  :completed="!!payload.courseIndustry"
                  class="absolute right-0 top-0"
                />
              </div>

              <div v-if="payload.phaseId === 2" class="relative mb-[32px]">
                <Checkbox v-model="payload.partTimeStudy" class="mx-0">
                  I am working part-time
                </Checkbox>
              </div>

              <FormRow v-if="payload.phaseId === 1">
                <div class="w-full sm:w-1/2 two-row relative">
                  <BaseSelect
                    v-model="payload.levelRankId"
                    label="Education Phase"
                    :options="educationalPhases"
                    value-field="id"
                    label-field="name"
                    class="relative mb-[32px] w-full"
                    width-classes="min-w-0"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!payload.levelRankId"
                    class="absolute right-0 top-0"
                  />
                </div>
                <div class="w-full sm:w-1/2 relative">
                  <BaseSelect
                    v-model="payload.educationalPhaseYear"
                    label="Year"
                    :options="range(1, 8)"
                    class="relative mb-[32px] w-full"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!payload.educationalPhaseYear"
                    class="absolute right-0 top-0"
                  />
                </div>
              </FormRow>

              <FormRow v-if="payload.phaseId === 1">
                <div
                  v-if="schools[payload.levelRankId]"
                  class="w-full relative"
                >
                  <BaseSelect
                    v-model="payload.school"
                    label="School"
                    class="relative mb-[32px] w-full"
                    :options="schools[payload.levelRankId]"
                    value-field="name"
                    label-field="name"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!payload.school"
                    class="absolute right-0 top-0"
                  />
                </div>
              </FormRow>
              <FormRow v-if="payload.phaseId === 1 && course[schoolId]">
                <div class="w-full relative">
                  <BaseSelect
                    v-model="payload.courseIndustry"
                    label="Course"
                    class="relative mb-[32px] w-full"
                    :options="course[schoolId]"
                    value-field="course"
                    label-field="course"
                  />
                  <ProfileIndicatorIcon
                    :completed="!!payload.courseIndustry"
                    class="absolute right-0 top-0"
                  />
                </div>
              </FormRow>

              <FormRow v-if="payload.phaseId === 4" class="mb-[32px] relative">
                <BaseSelect
                  v-model="payload.levelRankId"
                  label="Transition"
                  value-field="id"
                  label-field="name"
                  :options="transitions"
                  class="w-full"
                />
                <ProfileIndicatorIcon
                  :completed="!!payload.levelRankId"
                  class="absolute right-0 top-0"
                />
              </FormRow>

              <FormRow class="mb-[16px] relative">
                <BaseSelect
                  v-model="payload.maritalStatus"
                  label="Marital Status"
                  value-field="name"
                  label-field="name"
                  :options="maritalStatuses"
                  class="w-full"
                />
                <ProfileIndicatorIcon
                  :completed="!!payload.maritalStatus"
                  class="absolute right-0 top-0"
                />
              </FormRow>

              <Toggle
                v-model="payload.haveChildren"
                class="justify-between w-full mb-[16px]"
              >
                <template #left> Parent with children? </template>
              </Toggle>

              <div
                v-if="payload.haveChildren"
                class="px-[20px] pt-[32px] pb-[20px] bg-[#F0F2F4] rounded-[8px]"
              >
                <div class="flex justify-between w-full">
                  <label
                    for=""
                    class="max-w-[70%] text-[16px] leading-[20px] mb-[12px] block"
                  >
                    Number of children:
                  </label>

                  <div class="flex items-center">
                    <NumberInput
                      v-model="payload.numberOfChildren"
                      :min="1"
                      :max="10"
                      :disabled="!payload.haveChildren"
                    />
                  </div>
                </div>

                <div class="flex flex-wrap justify-between w-full">
                  <div
                    v-for="(_, index) in [...Array(payload.numberOfChildren)]"
                    :key="index"
                    :set="
                      (textToDisplay = getChildIndexBirthYearDisplay(index + 1))
                    "
                    class="w-full sm:w-half-24 mt-[16px]"
                  >
                    <BaseSelect
                      v-model="payload.childrenBirthYears[index]"
                      :label="`Birth Year of ${
                        index + 1
                      }${textToDisplay} Child`"
                      :options="years"
                      :disabled="!payload.haveChildren"
                      class="relative"
                      placeholder="Select Year"
                    />
                    <ProfileIndicatorIcon
                      :completed="!!payload.childrenBirthYears[index]"
                      class="absolute right-0 top-0 sm:left-[50%]"
                    />
                  </div>
                </div>
              </div>
            </FormCollapsible>

            <FormCollapsible
              :icon="
                require('../../../public/assets/icons/profile/christian.svg')
              "
              title="Christian Journey"
              :accordian="christianJourneyHasErrors"
            >
              <div class="mb-[32px] relative">
                <BaseSelect
                  v-model="payload.preHogcId"
                  label="I came to Heart of God Church as a..."
                  :options="christianJourneys"
                  value-field="id"
                  label-field="name"
                  class="w-full"
                />
                <ProfileIndicatorIcon
                  :completed="!!payload.preHogcId"
                  class="absolute right-0 top-0"
                />
              </div>

              <Toggle
                v-model="payload.firstGenChristian"
                class="justify-between w-full"
              >
                <template #left> I am a first-generation Christian </template>
              </Toggle>
            </FormCollapsible>

            <div>
              <h3
                class="text-[20px] leading-[24px] font-bold text-[#373E49] mb-[8px]"
              >
                Privacy Policy
              </h3>
              <p class="text-[16px] leading-[24px] mb-[16px] text-[#637083]">
                By providing this information, I consent to Heart of God Church
                collecting, using and disclosing my personal data as provided
                here or obtained by the organisation for the purposes of
                administration and/or receiving pastoral care from the church.
                <br />
                <br />
                For more details of Heart of God Church's Privacy Policy, please
                visit the website at
                <a
                  href="https://privacy.heartofgodchurch.org"
                  class="text-[#056DFF]"
                  target="_blank"
                  >privacy.heartofgodchurch.org.</a
                >
              </p>

              <div class="relative mb-[32px] sm:mb-[64px]">
                <Checkbox v-model="termsAndConditions" class="mx-0">
                  I agree to the terms & conditions
                </Checkbox>
              </div>
            </div>
          </div>

          <BaseButton
            full-width
            style="line-height: 24px"
            class="mb-[48px]"
            solid-classes="bg-[#6245F4] text-white hover:bg-[#4959f0]"
            :disabled="!termsAndConditions || loading || !hasChanges()"
            @click="save"
          >
            {{ loading ? "Loading..." : "Save Changes" }}
          </BaseButton>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BaseButton from "@/components/design/BaseButton.vue";
import BaseSelect from "@/components/design/BaseSelect.vue";
import Checkbox from "@/components/design/Checkbox.vue";
import TextInput from "@/components/design/TextInput.vue";
import Toggle from "@/components/design/Toggle.vue";
import { IonInput } from "@ionic/vue";
import FormCollapsible from "@/components/ProfilePage/FormCollapsible";
import FormRow from "@/components/ProfilePage/FormRow";
import NumberInput from "@/components/ProfilePage/NumberInput";
import EmailField from "@/components/ProfilePage/EmailField";
import MobileField from "@/components/ProfilePage/MobileField";
import ProfileIndicatorIcon from "../../../public/assets/icons/profile/ProfileIndicatorIcon";

export default {
  components: {
    Checkbox,
    TextInput,
    Toggle,
    BaseSelect,
    BaseButton,
    FormCollapsible,
    FormRow,
    NumberInput,
    EmailField,
    MobileField,
    ProfileIndicatorIcon,
    IonInput,
    TextInput,
  },

  data() {
    return {
      email: "",
      localMobileNumber: "",
      countryCode: "",
      cg: "",
      termsAndConditions: false,
      emailVerified: false,
      personalDetailsAccordian: false,
      POLAccordian: false,
      christianJourneyAccordian: false,
      payload: {
        fullname: "",
        gender: "",
        dob: "",
        nationalityId: "",
        residentialId: "",
        maritalStatus: "",
        numberOfChildren: undefined,
        uin: "",
        haveChildren: false,
        preHogcId: undefined,
        firstGenChristian: "",
        childrenBirthYears: [],
        phaseId: undefined,
        levelRankId: undefined,
        educationalPhaseYear: "",
        school: "",
        courseIndustry: "",
        partTimeStudy: "",
      },
    };
  },

  computed: {
    ...mapState("profile", {
      loading: (state) => state.loading,
      originalEmailVerified: (state) => state.emailVerified,
    }),
    ...mapState("lookups", [
      "genders",
      "nationalities",
      "residentialAreas",
      "maritalStatuses",
      "educationalPhases",
      "industries",
      "schools",
      "course",
      "transitions",
      "stagesOfLife",
      "christianJourneys",
      "years",
      "cgMapping",
    ]),

    ...mapGetters({
      originalEmail: "profile/email",
      originalPayload: "profile/payload",
      originalMobile: "profile/localMobileNumber",
      originalCountryCode: "profile/countryCode",
      cgId: "profile/cgId",
    }),

    personalDetailsHasErrors() {
      // eslint-disable-next-line camelcase
      const {
        fullname,
        nationalityId,
        residentialId,
        gender,
        dob,
        dob_ddmm,
        uin,
      } = this.payload;

      return this.checkPersonalDetails([
        fullname,
        nationalityId,
        this.localMobileNumber,
        this.countryCode,
        this.emailVerified,
        residentialId,
        gender,
        this.cg,
        dob,
        // eslint-disable-next-line camelcase
        dob_ddmm,
        uin,
      ]);
    },

    phaseOfLifeHasErrors() {
      const {
        phaseId,
        levelRankId,
        school,
        courseIndustry,
        educationalPhaseYear,
        maritalStatus,
      } = this.payload;

      const fields = [phaseId, maritalStatus];
      const studying = 1;
      const working = 2;
      const inTransition = 4;

      if (phaseId === studying) {
        fields.push(levelRankId, educationalPhaseYear);

        if (this.schools[levelRankId]) {
          fields.push(school);
        }

        if (this.course[this.schoolId]) {
          fields.push(courseIndustry);
        }
      }

      if (phaseId === working) {
        fields.push(courseIndustry);
      }

      if (phaseId === inTransition) {
        fields.push(levelRankId);
      }

      // If at least one of the fields has a falsy value
      // like 0, null, false, or empty string.
      return fields.some((field) => !field);
    },

    christianJourneyHasErrors() {
      return this.checkPersonalDetails([this.payload.preHogcId]);
    },

    almostDone() {
      const conditions = [
        this.personalDetailsHasErrors,
        this.phaseOfLifeHasErrors,
        this.christianJourneyHasErrors,
      ];

      return conditions.filter((condition) => condition === true).length > 0;
    },

    schoolId() {
      const payload = this.payload;
      const schools = this.schools[payload.levelRankId];
      const school = payload.school;
      if (!schools || !school) {
        return null;
      }
      return schools.find((item) => item.name === school)?.id;
    },

    difference() {
      const diff = {};
      for (const key in this.payload) {
        if (this.payload[key] !== this.originalPayload[key])
          diff[key] = this.payload[key];
      }
      this.addLifePhaseObj(diff);
      this.addChildrenObj(diff);
      return diff;
    },
  },
  watch: {
    "payload.phaseId"(newValue, oldValue) {
      // if not yet set previously, do nothing
      if (!oldValue) {
        return;
      }

      const { courseIndustry, levelRankId } = this.originalPayload;
      const courses = this.course[this.schoolId];
      const isStudying = newValue === 1;
      const isWorking = newValue === 2;
      const isTransition = newValue === 4;

      // For "Course" and "Industry" fields.
      if (
        (isStudying &&
          courses &&
          !courses.some((item) => item.course === courseIndustry)) ||
        (isWorking &&
          !this.industries.some((item) => item.name === courseIndustry))
      ) {
        this.payload.courseIndustry = "";
      } else {
        this.payload.courseIndustry = courseIndustry;
      }

      // For "Education Phase" and "Transition" fields.
      if (
        (isTransition &&
          !this.transitions.some((item) => item.id === levelRankId)) ||
        (isStudying &&
          !this.educationalPhases.some((item) => item.id === levelRankId))
      ) {
        this.payload.levelRankId = undefined;
      } else {
        this.payload.levelRankId = levelRankId;
      }
    },
    "payload.levelRankId"(newValue, oldValue) {
      if (
        this.payload.phaseId !== 1 ||
        oldValue === undefined ||
        this.schools[newValue].some(
          (school) => school.name === this.payload.school
        )
      ) {
        return;
      }

      this.payload.school = "";
    },
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("lookups/fetchIfNotYet");
    await this.$store.dispatch("profile/fetchProfile");
    await this.$store.dispatch("lookups/fetchCgMapping");
    await this.$store.dispatch("updates/getUpdates", "all");
    this.payload = { ...this.originalPayload };
    this.payload.childrenBirthYears = [
      ...this.originalPayload.childrenBirthYears,
    ];
    this.localMobileNumber = this.originalMobile;
    this.countryCode = this.originalCountryCode;
    this.email = this.originalEmail;
    this.emailVerified = this.originalEmailVerified;
    for (const key in this.cgMapping)
      if (this.cgMapping[key] === this.cgId) this.cg = key;
  },

  methods: {
    addLifePhaseObj(diff) {
      const lifephasesKey = [
        "phaseId",
        "levelRankId",
        "educationalPhaseYear",
        "school",
        "courseIndustry",
        "partTimeStudy",
      ];
      let hasChanges = false;
      for (const key of lifephasesKey) if (diff[key]) hasChanges = true;
      if (!hasChanges) return;
      for (const key of lifephasesKey)
        if (!diff[key]) diff[key] = this.originalPayload[key];
    },
    addChildrenObj(diff) {
      const childrenKey = ["haveChildren", "numberOfChildren"];
      let hasChanges = false;
      for (const key of childrenKey) if (diff[key]) hasChanges = true;
      if (
        !this.arrayEquals(
          this.originalPayload.childrenBirthYears,
          this.payload.childrenBirthYears
        )
      ) {
        hasChanges = true;
      }
      if (!hasChanges) return;
      for (const key of childrenKey)
        if (!diff[key]) diff[key] = this.originalPayload[key];
      diff.childrenBirthYears = [];
      for (let i = 0; i < diff.numberOfChildren; i++) {
        diff.childrenBirthYears.push(this.payload.childrenBirthYears[i]);
      }
      for (let i = 0; i < 10 - diff.numberOfChildren; i++)
        diff.childrenBirthYears.push(undefined);
    },
    hasChanges() {
      if (
        Object.keys(this.difference).length === 1 &&
        this.difference.childrenBirthYears &&
        this.arrayEquals(
          this.difference.childrenBirthYears,
          this.originalPayload.childrenBirthYears
        )
      ) {
        return false;
      }
      return Object.keys(this.difference).length > 0;
    },
    async save() {
      if (this.hasChanges())
        await this.$store.dispatch("profile/updateProfile", this.difference);
    },
    range(start, size) {
      return [...Array(size).keys()].map((i) => i + start);
    },
    arrayEquals(a, b) {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      );
    },
    getChildIndexBirthYearDisplay(index) {
      if (index === 1) return "st";
      if (index === 2) return "nd";
      if (index === 3) return "rd";
      return "th";
    },
    checkPersonalDetails(personalInfoList) {
      for (let i = 0; i < personalInfoList.length; i++) {
        const info = personalInfoList[i];
        if (info === null || info === "" || info === false) {
          return true;
        }
      }
    },
    setCountryCode(countryCode) {
      this.countryCode = countryCode;
    },
  },
};
</script>

<style>
@media all and (min-width: 640px) {
  .accordion .two-row span.truncate {
    max-width: 190px !important;
  }
}
</style>
