<template>
  <div
    class="min-w-[327px] items-center bg-white rounded-[8px] flex justify-between space-x-[12px]"
    :class="largeClassesForWrapper"
    style="box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1)"
  >
    <slot v-if="hasLeftIcon" name="left-icon" />
    <div
      class="text-left flex-grow text-[#0B0C0F]"
      :class="largeClassesForContent"
    >
      <slot />
    </div>
    <slot
      v-if="hasRightIcon"
      name="right-icon"
      :class="largeClassesForRightIcon"
    >
      <img src="../../../public/assets/icons/design/arrows.svg" />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    hasLeftIcon: {
      type: Boolean,
      default: false,
    },
    hasRightIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    largeClassesForWrapper() {
      return this.large ? "px-[20px] py-[16px]" : " p-[16px]";
    },
    largeClassesForContent() {
      return this.large
        ? "text-[20px] leading-[28px]"
        : "text-[16px] leading-[20px]";
    },
    largeClassesForRightIcon() {
      return this.large ? "" : "";
    },
  },
};
</script>
