<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44.6003 41.4727H50.4803H52.1603L43.7603 49.8727V43.9927L44.6003 41.4727Z"
      fill="#6245F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.2318 51.5517H43.3288C44.0648 51.5517 44.7712 51.2667 45.2933 50.7592L52.1768 44.072C52.7038 43.5607 53 42.8641 53 42.1379V17.8039C53 15.6459 51.2113 13.8965 49.0049 13.8965H46.5872V13.8966H40.2975V13.8965H23.7858V13.8966H17.4961V13.8965H14.9951C12.7887 13.8965 11 15.646 11 17.8039V47.6444C11 49.802 12.7885 51.5517 14.9946 51.5517H16.5866C16.5939 51.5517 16.6011 51.5517 16.6082 51.5517H43.2318V51.5517ZM44.5829 48.4459V44.2115C44.5829 43.4051 45.2541 42.7492 46.078 42.7492H50.1171L44.5829 48.4459Z"
      fill="#FEDB00"
    />
    <path
      d="M20.8581 22.5862H21.0922C22.2256 22.5862 23.1476 21.8229 23.1476 20.8846V12.7017C23.1476 11.7633 22.2256 11 21.0922 11H20.8581C19.7247 11 18.8027 11.7633 18.8027 12.7017V20.8842C18.8027 21.8229 19.7247 22.5862 20.8581 22.5862Z"
      fill="#6245F4"
    />
    <path
      d="M43.4689 22.5862H43.7031C44.8364 22.5862 45.7584 21.8229 45.7584 20.8846V12.7017C45.7584 11.7633 44.8365 11 43.7031 11H43.4689C42.3356 11 41.4136 11.7633 41.4136 12.7017V20.8842C41.4132 21.8229 42.3351 22.5862 43.4689 22.5862Z"
      fill="#6245F4"
    />
    <rect x="16.0405" y="28.3789" width="8.68966" height="7.24138" rx="1.44828" fill="#6245F4" />
    <rect x="27.7993" y="28.3789" width="8.68966" height="7.24138" rx="1.44828" fill="#6245F4" />
    <rect x="16.0405" y="39.5605" width="8.68966" height="7.24138" rx="1.44828" fill="#6245F4" />
    <rect x="27.7993" y="39.5605" width="8.68966" height="7.24138" rx="1.44828" fill="#6245F4" />
    <rect x="39.5596" y="28.3789" width="8.68966" height="7.24138" rx="1.44828" fill="#6245F4" />
  </svg>
</template>
