export default {
    props: {
      type: {
        type: String,
        validator (value) {
          return ['solid', 'outline', 'borderless'].includes(value)
        },
        default: 'solid',
      },
      size: {
        type: String,
        validator (value) {
          return ['small', 'large'].includes(value)
        },
        default: 'small',
      },
      loading: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      solidClasses: {
        type: [String, Array, Object],
        default: 'bg-[#080A6E] border-[#080A6E] text-white',
      },
      outlineClasses: {
        type: [String, Array, Object],
        default: 'bg-transparent border-[#080A6E] font-[#080A6E] hover:text-white',
      },
      borderlessClasses: {
        type: [String, Array, Object],
        default: 'border-0 border-transparent hover:rounded-none hover:bg-black hover:bg-opacity-10 hover:border-transparent active:border-transparent',
      },
      disabledClasses: {
        type: [String, Array, Object],
        default: 'disabled:border-[#D3D8DE] disabled:bg-[#D3D8DE] disabled:cursor-default',
      },
      loadingClasses: {
        type: [String, Array, Object],
        default: null,
      },
    },
    computed: {
      typeClasses () {
        return {
          solid: this.solidClasses,
          outline: this.outlineClasses,
          borderless: this.borderlessClasses,
        }[this.type]
      },
    },
  }