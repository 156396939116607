<template>
  <svg width="64" height="64" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5712_57802)">
      <path d="M8 25.3333V27.9999" stroke="#FEDB00" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M24 25.3333V27.9999" stroke="#FEDB00" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M6.6665 8V14.6667L9.5 20H22.5L25.3332 14.6667V8C25.3332 6.93913 24.9117 5.92172 24.1616 5.17157C23.4115 4.42143 22.394 4 21.3332 4H10.6665C9.60564 4 8.58822 4.42143 7.83808 5.17157C7.08793 5.92172 6.6665 6.93913 6.6665 8Z" fill="#FEDB00"/>
      <path d="M6.66667 14.6667C7.37391 14.6667 8.05219 14.9477 8.55229 15.4478C9.05238 15.9479 9.33333 16.6262 9.33333 17.3334V20.0001H22.6667V17.3334C22.6667 16.6262 22.9476 15.9479 23.4477 15.4478C23.9478 14.9477 24.6261 14.6667 25.3333 14.6667C26.0406 14.6667 26.7189 14.9477 27.219 15.4478C27.719 15.9479 28 16.6262 28 17.3334V22.6667C28 23.374 27.719 24.0523 27.219 24.5524C26.7189 25.0525 26.0406 25.3334 25.3333 25.3334H6.66667C5.95942 25.3334 5.28115 25.0525 4.78105 24.5524C4.28095 24.0523 4 23.374 4 22.6667V17.3334C4 16.6262 4.28095 15.9479 4.78105 15.4478C5.28115 14.9477 5.95942 14.6667 6.66667 14.6667Z" fill="#6245F4"/>
    </g>
    <defs>
      <clipPath id="clip0_5712_57802">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
