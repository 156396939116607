<template>
  <div>
    <DraggableCardsStack
      :id="scrollTriggerId"
      ref="cardStack"
      class="block mx-auto mt-4 items-center max-w-[1px] lg:max-w-[1px]"
      is-section="worship"
      :cards="visibleCards"
      :style="{ transform: 'scale(1.25)' }"
      :drag-horizontal-distance="-60"
      :drag-vertical-distance="30"
      alt-text="Image of Singapore; Location for GenerationS Conference & Strong Church Summit 2022"
      @cardAccepted="handleCardAccepted"
      @cardRejected="handleCardRejected"
      @cardSkipped="handleCardSkipped"
      @pickCard="pickCard"
      @releaseCard="releaseCard"
    />
  </div>
</template>

<script>
import DraggableCardsStack from '@/components/hogcfd/DraggableStack'
import { gsap } from '@/libraries/gsap'
import { ScrollTrigger } from '@/libraries/gsap/ScrollTrigger'

const ACCEPT_CARD = 'cardAccepted'
const REJECT_CARD = 'cardRejected'
export default {
  components: { DraggableCardsStack },
  props: {
    visibleCards: {
      type: Array,
      default () {
        return [
          {
            img: 'https://generationsmvmt.com/assets/conference/singapore-img-1.png',
            tracking: '',
          },
          {
            img: 'https://generationsmvmt.com/assets/conference/singapore-img-2.png',
            tracking: '',
          },
          {
            img: 'https://generationsmvmt.com/assets/conference/singapore-img-3.png',
            tracking: '',
          },
          {
            img: 'https://generationsmvmt.com/assets/conference/singapore-img-4.png',
            tracking: '',
          },
        ]
      },
    },
    scrollTriggerId: {
      type: String,
    },
  },
  data () {
    return {
      pauseTimer: false,
      index: 0,
    }
  },
  mounted () {
    this.index = 0
    this.removeCardFromDeck()
    this.scrollAnimation()
  },
  methods: {
    handleCardAccepted () {console.log('handleCardAccepted')},
    handleCardRejected () {console.log('handleCardRejected')},
    handleCardSkipped () {console.log('handleCardSkipped')},
    scrollAnimation () {
      console.log('scrollAnimation')
      gsap.registerPlugin(ScrollTrigger)
      gsap.timeline({
        ScrollTrigger: {
          trigger: `#${this.scrollTriggerId}`,

          duration: '2.2',
          ease: 'Power3.easeOut',
          onEnter: () => this.PeekOut(),
        },
      })
      console.log('scrollAnimation end')
    },
    PeekOut () {
      console.log('PeekOut')
      if (this.isEnter) {
        return
      }
      this.isEnter = true
      this.$refs.cardStack.PeekOut()
      console.log('PeekOut end')
    },
    pickCard () {
      console.log('pickCard')
      this.pauseTimer = true
    },
    releaseCard (param) {
      console.log('releaseCard')
      this.pauseTimer = false
      if (param !== -1) this.progressVal = param === REJECT_CARD ? 400 : 500
      this.removeCardFromDeck()
    },
    removeCardFromDeck () {
      console.log('removeCardFromDeck')
      if (!this.pauseTimer) {
        this.progressVal += 1
        if (this.progressVal >= 300) {
          if (this.index < this.visibleCards.length) {
            const direction =
              this.progressVal > 310 ? (this.progressVal === 401 ? REJECT_CARD : ACCEPT_CARD) : null
            this.$refs.cardStack.moveToOut(this.index, direction)
            this.index++
            if (this.index === this.visibleCards.length) {
              this.$refs.cardStack.moveToOrigin()
              this.index = 0
            }
          }
          this.progressVal = 0
        }
      }
      console.log('removeCardFromDeck end')
    },
  },
}
</script>
