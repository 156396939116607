<template>
    <div>
        <BaseSwiper
            v-slot="slotProps"
            :items="resources"
            :options="swiperOptions"
            class="mt-[24px] lg:mb-[-40px]"
            :class="{
                'lg:hidden': resources.length <= 4,
            }"
        >
            <ResourceCard
                :link="slotProps.item.link"
                :image-src="slotProps.item.imageSrc"
                :icon-src="slotProps.item.iconSrc"
                :can-copy="canCopy"
            />
        </BaseSwiper>

        <div
            v-if="resources.length <= 4"
            class="hidden mt-[12px] resources-grid"
        >
            <div
                v-for="item in resources"
                :key="item.id"
                class="drop-shadow-card flex-1 border-[0px] rounded-[24px] overflow-hidden"
            >
                <ResourceCard
                    :link="item.link"
                    :image-src="item.imageSrc"
                    :icon-src="item.iconSrc"
                    :can-copy="canCopy"
                    :enable-copy="enableCopy"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BaseSwiper from "@/components/design/BaseSwiper.vue";
import ResourceCard from "@/components/ResourcesPage/ResourceCard.vue";

export default {
    components: { BaseSwiper, ResourceCard },
    props: {
        canCopy: {
            type: Boolean,
            default: false,
        },
        enableCopy: {
            type: Boolean,
            default: false,
        },
        resources: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        breakpoints() {
            return {
                412: {
                    slidesPerView: 2.2,
                    centeredSlides: false,
                },
                641: {
                    slidesPerView: 3,
                    centeredSlides: false,
                },
                1366: {
                    slidesPerView: 4,
                    centeredSlides: false,
                },
            };
        },
    },
};
</script>

<style scoped>
::v-deep .swiper-container {
    padding-bottom: 0;
}

@media (min-width: 1024px) {
    .resources-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, 215px);
        gap: 20px;
    }
}
</style>
