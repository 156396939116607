<template>
  <div class="fixed bottom-[21px] right-[27px]">
    <BoardToast v-if="isBoardToastOpen" class="hidden md:flex w-[343px] mb-6" />
    <button
      class="border-2 border-solid h-[60px] w-[60px] md:w-[200px] rounded-[100px] flex flex-row items-center justify-center cursor-pointer float-right"
      :style="{
        'background-color': currBoard.themeColour,
        'border-color': currBoard.themeColour,
      }"
      data-event-tracking="write-message"
      @click="createMessage"
      @mouseover="hovering = true"
      @mouseout="hovering = false"
    >
      <WriteMessagePlusIcon
        class="h-[25px] w-[25px] md:h-[19px] md:w-[19px]"
        :fill="currBoard.themeTextColour"
      />
      <p
        class="ml-[14px] text-[20px] hidden md:block font-semibold"
        :style="{ color: currBoard.themeTextColour }"
      >
        Write Message
      </p>
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import WriteMessagePlusIcon from "../../../../public/assets/icons/board/WriteMessagePlusIcon";
import BoardToast from "./BoardToast.vue";

export default {
  components: {
    WriteMessagePlusIcon,
    BoardToast,
  },
  data() {
    return {
      hovering: false,
    };
  },
  computed: {
    ...mapState("board", ["currBoard", "isBoardToastOpen"]),
    hoverBgColour() {
      return this.currBoard.themeTextColour === "#000000"
        ? "#FFFFFF"
        : "#000000";
    },
  },
  methods: {
    async createMessage() {
      this.$router.push("/birthdayboard/message");
      await this.$store.dispatch("board/updateFormHeader", "Create Message");
      await this.$store.dispatch("board/updateSubmitButton", "Post Message");
    },
  },
};
</script>
