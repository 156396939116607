<template>
  <master-layout>
    <div
      class="lg:pr-[30px] lg:flex-1 lg:overflow-hidden relative theme-homepage-background"
    >
      <div
        v-if="isCmsLoading && isProfileLoading"
        class="w-screen h-screen flex items-center justify-center"
      >
        <Loading />
      </div>
      <div v-else>
        <!-- <ButtonsCardHome class="px-[24px] mt-36 lg:pl-[49px] lg:pr-0" /> -->
        <div class="relative pt-[36px]" :class="{ '!pt-[56px]': isIos() }">
          <SearchBar class="mx-[24px] mt-[12px] lg:ml-[49px]"></SearchBar>
          <JustForYou class="px-[24px] mt-[12px] lg:pl-[49px] lg:pr-0" />
          <!-- <WeekendHighlights class="mt-[90px] lg:mt-[64px]" /> -->
          <section
            v-if="!isCmsLoading && weeklyEvents.length > 0"
            class="mt-[16px] lg:pl-[49px]"
          >
            <h1
              class="font-medium text-[18px] leading-[18px] px-[24px] sm:px-0"
            >
              Highlights
            </h1>
            <HighlightsSlider
              ref="home-page-highlights"
              baseSwiperId="home-page-highlights"
              :events="weeklyEvents"
              :options="swiperOptions"
            />
          </section>
          <section
            v-if="!isCmsLoading && weeklyEvents.length > 0"
            class="mt-[16px] lg:pl-[49px]"
          >
            <h1
              class="font-medium text-[18px] leading-[18px] px-[24px] sm:px-0"
            >
              Demographic Highlights
            </h1>
            <HighlightsSlider
              ref="home-page-demographic"
              baseSwiperId="home-page-demographic"
              :events="weeklyEvents"
              :options="swiperOptions"
            />
          </section>
        </div>
        <!-- <UpdatesExploration v-if="false" /> -->
      </div>
    </div>
  </master-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";
import Loading from "@/components/common/LoadingState.vue";
import ButtonsCardHome from "@/components/HomePage/ButtonsCard.vue";
// import WeekendHighlights from '@/components/HomePage/WeekendHighlights.vue'
import JustForYou from "@/components/HomePage/JustForYou.vue";
// import UpdatesExploration from '@/components/modals/UpdatesExploration.vue'
import HighlightsSlider from "@/components/HomePage/HighlightsSlider.vue";
import SearchBar from "@/components/HomePage/SearchBar.vue";

export default {
  components: {
    Loading,
    ButtonsCardHome,
    // WeekendHighlights,
    JustForYou,
    // UpdatesExploration,
    HighlightsSlider,
    SearchBar,
  },
  data() {
    return {
      swiperOptions: {
        centeredSlides: false,
        slidesPerView: "auto",
        grabCursor: true,
        pagination: {
          el: ".swiper-pagination",
          dynamicBullets: true,
          clickable: true,
        },
        hasNavigation: true,
      },
    };
  },
  computed: {
    ...mapState("profile", {
      hasSeenUpdates: (state) => state.hasSeenUpdates,
      isProfileLoading: (state) => state.loading,
    }),
    ...mapState("cms", ["upcomingEvents", "weeklyEvents"]),
    ...mapState("cms", {
      isCmsLoading: (state) => state.loading,
    }),
  },
  async ionViewWillEnter() {
    await Promise.all([
      this.$store.dispatch("cms/fetchCmsContent", {
        entityName: "just-for-yous",
        stateName: "justForYous",
      }),
      this.$store.dispatch("cms/fetchCmsContent", {
        entityName: "weekly-events",
        stateName: "weeklyEvents",
      }),
      this.$store.dispatch("updates/getUpdates", "all")
    ]);
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
  },
};
</script>
