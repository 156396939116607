<template>
  <div class="flex-1 pt-6 pl-5 md:pl-20">
    <div
      class="header flex flex-wrap items-center justify-between mb-3 w-[326px] md:w-[606px] xl:w-[812px]"
    >
      <h2 class="flex-1 text-lg font-bold">{{ formatDate(date) }}</h2>
      <div v-if="hasTooltip" class="relative tooltip">
        <img
          ref="tooltipTrigger"
          class="peer"
          src="../../../public/assets/icons/photo/tooltip.svg"
          @click="toggleTooltip"
        />

        <div
          v-show="showingTooltip"
          ref="tooltip"
          class="tooltip-box px-[16px] py-[12px] w-[279px] rounded-[8px] bg-[#F8F9F9] absolute z-10"
        >
          <h4
            class="mb-[10px] font-semibold text-[16px] leading-[24px] text-[#0B0C0F]"
          >
            Download your photo
          </h4>
          <p class="text-[14px] leading-[20px] text-[#0B0C0F]">
            {{
              viewportWidth >= 1024
                ? "Right click on a photo to download it."
                : "Press and hold on a photo to download it to your phone!"
            }}
          </p>
        </div>
      </div>
    </div>

    <PhotoPopup v-show="!isSelectingPhotos" :images="formatPhoto(links)" />

    <div
      v-show="isSelectingPhotos"
      class="tiles grid gap-x-1 gap-y-2 pb-2 auto-rows-fr min-w-[106px] min-h-[106px] md:min-w-[200px] md:min-h-[200px]"
    >
      <label
        v-for="(image, linkIndex) in links"
        :key="linkIndex"
        class="relative"
        :class="{ 'cursor-pointer': isSelectingPhotos }"
        @click="selectPhotos(image)"
      >
        <img
          class="block w-[106px] h-[106px] md:w-[200px] md:h-[200px] object-cover"
          :src="image"
        />

        <img
          v-if="isSelectingPhotos && isPhotoSelected(image)"
          class="absolute block bottom-2 right-2 w-[20px] md:w-[28px] h-[20px] md:h-[28px] border border-[#00A857] rounded-full"
          src="../../../public/assets/icons/photo/blue-tick.svg"
          alt=""
        />

        <span
          v-if="isSelectingPhotos && !isPhotoSelected(image)"
          class="absolute bottom-2 right-2 block w-[20px] md:w-[28px] h-[20px] md:h-[28px] bg-[#ffffff] border border-[#00A857] rounded-full"
        />
      </label>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PhotoPopup from "@/components/Photo/PhotosPopup";
import isIos from "@/mixins/isIos";

export default {
  components: {
    PhotoPopup,
  },
  mixins: [isIos],
  props: {
    date: {
      type: String,
      required: true,
    },
    links: {
      type: Array,
      required: true,
    },
    viewportWidth: {
      type: Number,
      required: true,
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showingTooltip: false,
    };
  },
  computed: {
    ...mapState("photos", ["isSelectingPhotos"]),
    ...mapGetters("photos", ["isPhotoSelected"]),
  },
  watch: {
    showingTooltip(value) {
      if (value) {
        window.addEventListener("mousedown", this.outsideClick);
      } else {
        window.removeEventListener("mousedown", this.outsideClick);
      }
    },
    viewportWidth(width) {
      if (width >= 1024) {
        this.showingTooltip = false;
      }
    },
  },
  methods: {
    formatDate(date) {
      const currentDate = new Date(date);
      const options = { weekday: "long", month: "long", day: "numeric" };
      return currentDate.toLocaleDateString("en-us", options);
    },
    formatPhoto(photos) {
      const arr = photos;
      return arr.map((photos) => {
        return {
          src: photos,
          thumbnail: photos,
          w: 600,
          h: 600,
        };
      });
    },
    async selectPhotos(photo) {
      if (this.isSelectingPhotos) {
        await this.$store.dispatch("photos/toggleSelectedPhoto", photo);
      }
    },
    outsideClick(event) {
      const { tooltip, tooltipTrigger } = this.$refs;

      if (!tooltip.contains(event.target) && event.target !== tooltipTrigger) {
        this.showingTooltip = false;
      }
    },
    toggleTooltip() {
      this.showingTooltip = !this.showingTooltip;
    },
  },
};
</script>

<style scoped>
.tiles {
  grid-template-columns: repeat(auto-fill, minmax(106px, 0));
}

@media (min-width: 768px) {
  .tiles {
    grid-template-columns: repeat(auto-fill, minmax(200px, 0));
  }
}

.tooltip-box {
  top: calc(100% + 14px);
  right: -10px;

  box-shadow: 0px 0px 5px rgba(11, 12, 15, 0.35);
  /* filter: drop-shadow(0px 0px 5px rgba(11, 12, 15, 0.35)); */
}

.tooltip-box:before {
  content: "";
  position: absolute;
  top: -7px;
  right: 10px;
  border: 6px solid #f8f9f9;
  border-color: transparent #f8f9f9 #f8f9f9 transparent;
  transform-origin: 0 0;
  transform: rotate(45deg);
  z-index: -1;
  box-shadow: -3px -3px 3px rgba(11, 12, 15, 0.15);
}
.tooltip-box:after {
  content: "";
  position: absolute;
  top: -7px;
  right: 10px;
  border: 6px solid white;
  border-color: #f8f9f9 transparent transparent #f8f9f9;
  transform-origin: 0 0;
  transform: rotate(45deg);
  z-index: 2;
}

@media (min-width: 1415px) {
  .header {
    width: 1016px;
  }
}

@media (min-width: 1620px) {
  .header {
    width: 1224px;
  }
}
</style>
