<template>
    <div>
        <div class="md:py-[40px] md:pl-[40px] lg:pr-[30px] sm:px-[24px] py-[24px] lg:py-[56px] lg:pl-[64px] lg:flex-1 lg:overflow-hidden mb-[24px] mt-[60px]">
            <section class="px-[24px] sm:px-0 z-10">
                <h1
                    class="text-[32px] leading-[48px] sm:text-[48px] sm:leading-[64px] font-bold mb-[8px] text-[#0B0C0F]">
                    {{ this.filteredTrackName }}
                </h1>
            </section>
            <div class="flex justify-center items-center">
                <div class="grid grid-cols-2 gap-1 mx-[12px]">
                    <MilestoneCard v-for="(milestone, index) in milestones" :key="index" :milestone="milestone" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import MilestoneCard from "@/components/Milestones/MilestoneCard.vue";

export default {
    components: {
        MilestoneCard,
    },
    computed: {
        ...mapState("milestones", ["milestones", "filteredTrackName"]),
    },
    async ionViewWillEnter() {
        await this.$store.dispatch("updates/getUpdates");
    },
};

</script>