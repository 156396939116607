const state = () => ({
  currResource: {},
})

const mutations = {
  setCurrResource (state, currResource) {
    state.currResource = currResource
  },
}

const actions = {
  updateCurrResource ({ commit }, currResource) {
    commit('setCurrResource', currResource)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
