<template>
  <ion-page>
    <ion-header>
      <PhotosHeader />
    </ion-header>
    <ProfileModal
      v-if="showingProfileModal"
      @close="closeProfileModal"
    />
    <ion-content
      :id="id"
      fullscreen="false"
      class="master"
      @ionScroll="onScroll"
    >
      <main class="lg:flex h-full lg:h-auto">
        <SideBar class="shrink-0" @open-modal="toggleLogoutPopup()" />
        <slot class="flex-1 pb-16 lg:pb-0" test="test"> </slot>
      </main>
    </ion-content>
    <ion-footer>
      <ion-toolbar
        class="bg-white h-[68px] lg:hidden box-shadow"
        :class="{ 'h-[85px]': isIos() }"
      >
        <BottomNav />
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonFooter,
  isPlatform,
} from "@ionic/vue";
import { StatusBar } from "@capacitor/status-bar";
import store from "@/store";
import { mapState } from "vuex";
import markerSDK from '@marker.io/browser'
import Toast from '@/components/common/Toast.vue'
import PhotosHeader from '@/components/Photo/PhotosHeader'
import SideBar from '@/components/common/Sidebar'
import BottomNav from '@/components/common/BottomNav'
import Global from '@/mixins/Global'
import { isIosNativeOnly } from "@/utils/platform-check";
import ProfileModal from "@/components/modals/Profile";

export default {
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonFooter,
    SideBar,
    BottomNav,
    Toast,
    PhotosHeader,
    ProfileModal,
  },
  mixins: [Global],
  props: {
    id: {
      type: String,
      default: "photo-content",
    },
  },
  computed: {
    ...mapState("profile", ["showingProfileModal"]),
  },
  async created () {
    await this.$store.dispatch('profile/fetchProfile')
    if (process.env.NODE_ENV === 'staging') {
      const widget = await markerSDK.loadWidget({
        destination: process.env.MARKERIO_DESTINATION, // <- Your unique destination ID
      })
      widget.setReporter({ email: this.email, fullName: this.name })
    }
  },
  methods: {
    onScroll(event) {
      this.$emit("on-scroll", event);
    },
    isIos() {
      return isIosNativeOnly();
    },
    async closeProfileModal() {
      await this.$store.commit("profile/setShowingProfileModal", false);
    }
  },
};
</script>
<style scoped>
ion-toolbar {
  --border-color: transparent;
  --background: transparent;
  --ion-color-base: transparent !important;
}

ion-content.master {
  --padding-bottom: 0px;
  --offset-top: -10% !important;
}

.footer-md::before {
  display: none;
}

.box-shadow {
  box-shadow: 0px 1px 25px 0px #0000001a;
}
</style>