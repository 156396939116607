<template>
  <svg
    v-if="!isActive"
    class="block"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9999 6.66699H7.99992C6.52716 6.66699 5.33325 7.8609 5.33325 9.33366V25.3337C5.33325 26.8064 6.52716 28.0003 7.99992 28.0003H23.9999C25.4727 28.0003 26.6666 26.8064 26.6666 25.3337V9.33366C26.6666 7.8609 25.4727 6.66699 23.9999 6.66699Z"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.3333 4V9.33333"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6665 4V9.33333"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33325 14.667H26.6666"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.6665 20C8.6665 21.1046 9.56194 22 10.6665 22C11.7711 22 12.6665 21.1046 12.6665 20C12.6665 18.8954 11.7711 18 10.6665 18C9.56194 18 8.6665 18.8954 8.6665 20Z"
      fill="#6245F4"
    />
    <path
      d="M23.9999 6.66699H7.99992C6.52716 6.66699 5.33325 7.8609 5.33325 9.33366V14.5H26.6666V9.33366C26.6666 7.8609 25.4727 6.66699 23.9999 6.66699Z"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <svg
    v-else
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9999 6.66699H7.99992C6.52716 6.66699 5.33325 7.8609 5.33325 9.33366V25.3337C5.33325 26.8064 6.52716 28.0003 7.99992 28.0003H23.9999C25.4727 28.0003 26.6666 26.8064 26.6666 25.3337V9.33366C26.6666 7.8609 25.4727 6.66699 23.9999 6.66699Z"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.3333 4V9.33333"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6665 4V9.33333"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.33325 14.667H26.6666"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.6665 20C8.6665 21.1046 9.56194 22 10.6665 22C11.7711 22 12.6665 21.1046 12.6665 20C12.6665 18.8954 11.7711 18 10.6665 18C9.56194 18 8.6665 18.8954 8.6665 20Z"
      fill="#6245F4"
    />
    <path
      d="M23.9999 6.66699H7.99992C6.52716 6.66699 5.33325 7.8609 5.33325 9.33366V14.5H26.6666V9.33366C26.6666 7.8609 25.4727 6.66699 23.9999 6.66699Z"
      fill="#6245F4"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
