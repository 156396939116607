<template>
  <div>
    <a
      class="cursor-pointer flex flex-row text-[#6245F4]"
      :href="`https://www.instagram.com/${igHandle}`"
      target="_blank"
    >
      <img class="w-[16px] mr-[4px]" src="../../../../public/assets/icons/board/instagram-blue.svg" />
      @{{ igHandle }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    igHandle: {
      type: String,
      required: true,
    },
  },
}
</script>
