<template>
  <master-layout>
    <div class="flex p-0 h-100vh lg:h-auto background-img">
      <div
        class="w-full flex justify-center px-[24px] lg:px-0 min-h-[calc(var(--app-height)-88px)]"
      >
        <Loading v-if="isLoadingWhitelist" />
        <div v-else-if="resource">
          <FD2024Excerpt class="mb-[53px] md:mb-[70px]" />
          <FD2024Stories :resource="resource" />
        </div>
        <PasswordEntry
          v-else
          class="pt-[36px]"
          :is-loading="isLoadingPassword"
          :res-status="resStatus"
          @enter-password="getResourceWithPassword"
        />
      </div>
    </div>
  </master-layout>
</template>
<script>
import { mapState } from "vuex";
import Loading from "@/components/common/LoadingState.vue";
import FD2024Excerpt from "@/components/hogcfd/FD2024ExcerptSection.vue";
import FD2024Stories from "@/components/hogcfd/FD2024StoriesSection.vue";
import PasswordEntry from "@/components/ProtectedPage/PasswordEntry.vue";
import axios from "axios";

export default {
  components: { FD2024Stories, Loading, FD2024Excerpt, PasswordEntry },
  data() {
    return {
      resource: null,
      isLoadingPassword: false,
      isLoadingWhitelist: true,
      resStatus: 0,
    };
  },
  computed: {
    ...mapState("user", ["token"]),
  },
  async ionViewWillEnter() {
    try {
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/protected/fd2024/whitelist",
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.resource = response.data;
    } catch (e) {
      if (
        e.response &&
        (e.response.status === 403 || e.response.status === 500)
      ) {
        await this.$router.push({ path: "/" });
      }
    } finally {
      this.isLoadingWhitelist = false;
    }
  },
  mounted() {},
  methods: {
    async getResourceWithPassword(password) {
      if (this.isLoadingPassword) return;
      this.isLoadingPassword = true;
      try {
        const response = await axios.request(
          process.env.VUE_APP_API_URL + "/cms/protected",
          {
            method: "post",
            data: { password, slug: "fd2024" },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.resource = response.data;
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.resStatus = e.response.status;
        }
      } finally {
        this.isLoadingPassword = false;
      }
    },
  },
};
</script>
<style scoped>
.background-img {
  background-image: url("../../../public/assets/images/hogcfd/fd-background-image.png");
  width: 100vw;
}
</style>
