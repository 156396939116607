<template>
  <span v-if="$slots.default" :class="helperClasses">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    helperClasses: {
      type: [String, Array, Object],
      default: 'text-[16px] text-[#373E49] leading-[24px] mt-[4px] block',
    },
  },
}
</script>
