<template>
  <div
    class="filter-bar-container lg:px-[48px] xl:px-[204px] h-[88px] flex flex-col items-center border-[#D3D8DE] border-b-[1px] border-solid bg-[#F8F9F9]"
  >
    <div
      v-if="!isMainPage"
      class="flex justify-start items-center w-full cursor-pointer pt-[8px] block sm:hidden"
      @click="redirectMainBoard"
    >
      <img
        src="../../../../public/assets/icons/board/back-to-board.svg"
        class="h-[24px] w-[24px] mr-[4px]"
        alt="back logo"
      />
      <p class="text-[16px] leading-[20px] pt-[2px]">Back</p>
    </div>
    <div
      :class="{
        'pt-[8px] sm:pt-[24px]': !isMainPage,
        'sm:pt-[24px]': isMainPage,
      }"
      class="hidden md:flex md:justify-between md:w-full"
    >
      <p class="text-[24px] leading-[40px] font-bold text-black">
        {{ pageTitle }}
      </p>
      <div class="flex flex-row">
        <FilterButton v-if="!isMyMessagesPage" class="mr-[40px]" />
        <FilterToggle v-if="isMainPage" />
      </div>
    </div>
    <div class="flex md:hidden w-full">
      <BoardToast v-if="isBoardToastOpen" class="w-full" />
      <div
        v-else
        :class="{
          'pt-[8px] sm:pt-[24px]': !isMainPage,
          'pt-[24px]': isMainPage,
        }"
        class="flex justify-between w-full"
      >
        <FilterToggle v-if="isMainPage" />
        <p v-else class="text-[24px] leading-[40px] font-bold text-black">
          {{ pageTitle }}
        </p>
        <FilterButton v-if="!isMyMessagesPage" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BoardToast from "@/components/Board/Main/BoardToast.vue";
import FilterButton from "@/components/Board/Filter/FilterButton";
import FilterToggle from "@/components/Board/Filter/FilterToggle";

export default {
  components: {
    BoardToast,
    FilterButton,
    FilterToggle,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState("board", ["isBoardToastOpen", "myZoneFilterValue"]),
    isMainPage() {
      return this.$route.path === "/birthdayboard";
    },
    isMyMessagesPage() {
      return this.$route.path === "/birthdayboard/my-messages";
    },
    pageTitle() {
      if (
        this.$route.path === "/birthdayboard" &&
        this.myZoneFilterValue === "myzone"
      ) {
        return "My Zone Messages";
      } else if (this.$route.path === "/birthdayboard") {
        return "Church Messages";
      } else if (this.$route.path === "/birthdayboard/my-messages") {
        return "My Messages";
      } else if (this.$route.path === "/birthdayboard/favourite-messages") {
        return "Favourite Messages";
      } else if (this.$route.path === "/birthdayboard/pinned-messages") {
        return "Pinned Messages";
      } else if (this.$route.path === "/birthdayboard/hidden-messages") {
        return "Hidden Messages";
      } else {
        return "";
      }
    },
  },
  methods: {
    redirectMainBoard() {
      this.$router.push("/birthdayboard");
    },
  },
};
</script>
<style scoped lang="scss">
.filter-bar-container {
  padding: 0 1.5rem;
}
@media (max-width: 389px) {
  .filter-bar-container {
    padding: 0 1rem;
  }
}
</style>
