<template>
  <div>
    <div
      class="buttons-card fixed left-[24px] right-[24px] top-[120px] z-[100] flex lg:hidden"
    >
      <HomeButtons
        v-for="button in topButtons"
        :id="button.id"
        :key="button.label"
        :to="button.path"
        :icon="button.icon"
        :label="button.label"
        :disabled="button.disabled"
        :is-new="button.isNew"
        :is-external-link="button.isExternalLink"
        :data-event-tracking="button.dataEventTracking"
      />
    </div>
    <div class="mt-[64px] mb-[40px] hidden lg:flex">
      <HomeButtons
        v-for="button in topButtons"
        :id="button.id"
        :key="button.label"
        :to="button.path"
        :icon="button.icon"
        :label="button.longLabel"
        :disabled="button.disabled"
        :is-new="button.isNew"
        :is-external-link="button.isExternalLink"
        :data-event-tracking="button.dataEventTracking"
      />
    </div>
  </div>
</template>

<script>
import HomeButtons from '@/components/HomePage/HomeButtons.vue'
import ColoredPhotoDrop from '@/components/icons/ColoredPhotoDrop.vue'
import ColoredSeating from '@/components/icons/ColoredSeating.vue'
import ColoredCalendar from '@/components/icons/ColoredCalendar.vue'

export default {
  components: { HomeButtons },
  data () {
    return {
      topButtons: [
        { id: 'seating', label: 'Seating', longLabel: 'Seating', path: '/check-in', icon: ColoredSeating, dataEventTracking: 'seatsfinder-homepage' },
        { id: 'calendar', label: 'Calendar', longLabel: 'Calendar', path: '/calendar', icon: ColoredCalendar, dataEventTracking: 'calendar-homepage' },
        { id: 'photoDrop', label: 'PhotoDrop', longLabel: 'PhotoDrop', path: '/photodrop', icon: ColoredPhotoDrop, dataEventTracking: 'photo-drop' },
      ],
    }
  },
}
</script>

<style scoped>
.buttons-card {
  background: white;
  height: 116px;
  border-radius: 16px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -46px;
  margin-bottom: 40px;
}
</style>
