<template>
  <div
    class="
      dropdown
      absolute
      flex flex-col
      border border-[#056DFF]
      bg-white
      top-[38px]
      text-left
      rounded-b-[4px]
      max-h-[200px]
      overflow-auto
      z-10
    "
    :class="widthClasses"
  >
    <div v-if="options.length > 0">
      <div v-if="placeholder" :class="placeholderClasses">
        {{ placeholder }}
      </div>
      <div v-for="(option, index) in options" :key="index">
        <div
          @click="select(option)"
          :data-testid="`dropdown-option-${index + 1}`"
          class="px-[12px] py-[8px] w-full flex justify-between"
          :class="
            getValue(option) == localValue
              ? 'bg-[#E1E8FF] text-black'
              : 'hover:bg-[#E7EDFE] hover:text-black'
          "
        >
          {{ labelField ? option[labelField] : option }}

          <Tag
            v-if="manualLast && index === options.length - 1"
            :class="manualColorClass"
          >
            Manual
          </Tag>
        </div>
      </div>
    </div>
    <div v-else :class="placeholderClasses">
      {{ noOptionsText }}
    </div>
  </div>
</template>

<script>
import HasOptions from '@/mixins/HasOptions'
import Tag from '@/components/design/Tag'

export default {
  components: {
    Tag,
  },

  mixins: [HasOptions],

  props: {
    widthClasses: {
      type: String,
      default: 'w-full left-0',
    },
    manualLast: {
      type: Boolean,
      default: false,
    },
    manualColorClass: {
      type: String,
      default: 'bg-[#D2DBFB] text-[#101010]',
    },
  },

  computed: {
    placeholderClasses() {
      return 'px-[12px] py-[8px] hover:bg-[#E7EDFE] hover:text-black'
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
