<template>
  <svg
    v-if="isMobile"
    width="344"
    height="7"
    viewBox="0 0 344 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2.86429C3.701 1.52621 7.47601 2.44448 9.2615 2.44448C25.2439 2.44448 41.227 2.38565 57.2093 2.44448C127.613 2.70365 185.336 7.40244 255.784 3.37738C274.87 2.28689 306.344 5.47739 325.374 3.37738C330.811 2.7774 336.293 2.02468 341.758 2.02468C342.671 2.02468 340.641 1.84713 341.575 2.44448"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
  <svg
    v-else
    width="1093"
    height="12"
    viewBox="0 0 1093 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4.16597C7.44916 0.812643 19.5424 3.11391 25.2623 3.11391C76.462 3.11391 127.664 2.96647 178.863 3.11391C404.403 3.76341 589.319 15.539 815 5.45183C876.142 2.71898 976.97 10.7146 1037.93 5.45183C1055.35 3.94823 1072.91 2.06185 1090.42 2.06185C1093.34 2.06185 1086.84 1.6169 1089.83 3.11391"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  computed: {
    isMobile () {
      return window.innerWidth < 413
    },
  },
}
</script>
