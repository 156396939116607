import { isPlatform } from "@ionic/vue";

export function getApiUrl() {
  const isAndroid = isPlatform("android") && !isPlatform("mobileweb");
  if (process.env.VUE_APP_NODE_ENV == "local")
    return !isAndroid ? "http://localhost:8100" : "http://10.0.2.2:8100";

  return process.env.VUE_APP_API_URL;
}

export function isNativeNotWeb() {
  const isNative = isPlatform("android") || isPlatform("ios");
  const isNativeBrowser = isPlatform("mobileweb");

  return isNative && !isNativeBrowser;
}

export function isIosNativeOnly() {
  const isIos = isPlatform("ios");
  const isNativeBrowser = isPlatform("mobileweb");

  return isIos && !isNativeBrowser;
}
