<template>
  <FDLayout>
  <div class="flex flex-col w-full overscroll-y overflow-y-scroll overflow-x-hidden justify-start">
    <div class="h-[100vh] w-[100vw] overscroll-y-none">
      <div class="pt-[123px] px-[24px] sm:px-0 sm:max-w-[678px] mx-auto">
        <FDTitle year="2013" />
        <p
          class="font-source-sans-3 text-[19px] sm:text-[20px] leading-[28px] sm:leading-[30px] mt-[41px] sm:mt-[56px]"
        >
          As much as I wish I can be<br class="sm:hidden" />
          your real 24/7 father, <br class="sm:hidden" />it can never be.<br />
          However, I can do better.<br />
          I can lead you to the ONE who can be your Father.<br />
        </p>
        <BibleQuote
          title="Psalm 68:5–6 (The Message)"
          quote="God is a Father of the fatherless, champion of widows...
        God makes homes for the homeless, leads prisoners to freedom..."
          class="my-[48px]"
        />
        <p class="font-source-sans-3 text-[19px] sm:text-[20px] leading-[28px]">
          Remember... in Christ, from your generation onwards, it will be different.
        </p>
        <FDSignOff class="pt-[48px]" year="2013" />
      </div>

      <FDFooter
        back-text="2017 letter"
        forward-text="2014 letter"
        back-slug="/hogcfd/2017"
        forward-slug="/hogcfd/2014"
        class="mt-[48px]"
      />
      <div class="pt-[96px] mt-[96px] lg:mt-0" />
    </div>
  </div>
</FDLayout>
</template>

<script>
import FDSignOff from '@/components/hogcfd/FDSignOff'
import FDFooter from '@/components/hogcfd/FDFooter.vue'
import FDTitle from '@/components/hogcfd/FDTitle'
import BibleQuote from '@/components/hogcfd/BibleQuote.vue'
import FDLayout from '@/components/FDLayout.vue'

export default {
  components: { FDTitle, FDSignOff, BibleQuote, FDFooter, FDLayout },
}
</script>
