<template>
  <FDLayout>
  <div
    class="flex flex-col w-full overscroll-y-none overflow-y-scroll overflow-x-hidden justify-start"
  >
    <div class="h-[100vh] w-[100vw] overscroll-y-none">
      <div class="pt-[123px] px-[24px] sm:px-0 sm:max-w-[678px] mx-auto">
        <FDTitle year="2016" />
        <p
          class="font-source-sans-3 text-[19px] sm:text-[20px] leading-[28px] sm:leading-[30px] mt-[41px] sm:mt-[56px]"
        >
          This Father’s Day, I write to all of you who are not celebrating this occasion.<br />
          For the past 4 years, I have been writing to you as a father would to his children. But
          this year, I am writing with the aim to change your mindset.<br /><br />
          If there is a title to this little message, <br class="sm:hidden" />it will be – <br />
          The advantage of being fatherless.<br />
          In a perfect world, it is always good to have a father.<br />
          However, in a fallen world, I have also observed that sometimes –<br
            class="hidden sm:block"
          />
          an absent father is better than a bad father.<br />
          A void in the heart is better than a thorn in the flesh.<br /><br />
          For those of you who are fatherless, you don’t start from a<br class="hidden sm:block" />
          disadvantage. <br class="sm:hidden" />You actually start on a neutral.<br />
          Sometimes family carries both blessing and baggage.<br />
          So you may not enjoy the blessing but you certainly will not be<br
            class="hidden sm:block"
          />
          burdened by the baggage.<br />
          You have no big shoes to fill.<br />
          You have no footsteps to follow.<br />
          You don’t live under a shadow.<br />
          You don’t have a family name or tradition to uphold.<br /><br />
          I have seen the hopes and fears of parents holding their children hostage.<br />
          I have seen family expectations and traditions taking their children prisoner.<br /><br />
          The life jacket for one generation can become a straitjacket for the next.<br />
          The life jacket of a stable job for parents can become a straitjacket for the
          entrepreneurial spirit of the children.<br />
          The life jacket of financial security for one generation can become
          <br class="hidden sm:block" />
          a straitjacket for the spiritual dreams and idealism of the next generation.<br /><br />
          There would not be Apple if Steve Jobs had not dropped out of college.<br />
          There would not be freedom for Israel <br class="sm:hidden" />if Moses had not left the
          palace.<br />
          There would not be HOGC, if I had <br class="sm:hidden" />taken over my father’s business
          <br class="sm:hidden" />20 years ago.<br />
          There would not be a youth church, if Pastor Lia had not quit her job as a journalist.<br /><br />
          Thus, in this sense, my dear fatherless friends, you have an advantage.<br />
          You are free to paint on the white canvas of your life.<br />
          You can live your dreams unencumbered by the burden of the previous generation.<br />
          You are free.<br />
          So be free.<br />
          Dream big dreams.<br />
          Live for Jesus.<br />
          Change the world!<br /><br />
          and oh… you don’t need to have “daddy issues” too.<br />
          You have a Father in heaven.
        </p>
        <FDSignOff class="mt-[48px]" year="2016" />
      </div>

      <FDFooter
        back-text="2015 letter"
        forward-text="2017 letter"
        back-slug="/hogcfd/2015"
        forward-slug="/hogcfd/2017"
        class="mt-[48px]"
      />
      <div class="pt-[96px] mt-[96px] lg:mt-0" />
    </div>
  </div>
</FDLayout>
</template>

<script>
import FDSignOff from '@/components/hogcfd/FDSignOff'
import FDTitle from '@/components/hogcfd/FDTitle'
import FDFooter from '@/components/hogcfd/FDFooter'
import FDLayout from '@/components/FDLayout.vue'

export default {
  components: {
    FDTitle,
    FDFooter,
    FDSignOff,
    FDLayout
  },
}
</script>
