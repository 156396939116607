<template>
  <div
    class="w-full h-full flex justify-center items-center bg-black bg-opacity-50"
  >
    <div
      v-on-clickaway="closeFilterModal"
      class="bg-[#ffffff] w-full h-full flex flex-col sm:justify-center md:w-[856px] md:max-h-[700px] rounded-t-xl md:rounded-[8px]"
      :class="{
        'md:max-h-[700px]': isCurrBoardAdmin,
        'md:max-h-[600px]': !isCurrBoardAdmin,
      }"
    >
      <div class="fixed top-0 sm:relative w-full bg-white">
        <div
          class="flex flex-row justify-between border-b px-[24px] py-[12px] sm:px-[40px] sm:pt-[32px] sm:pb-[24px] border-[#D3D8DE]"
        >
          <p
            class="text-stone-800 text-[16px] leading-[20px] font-normal cursor-pointer sm:hidden"
            @click="closeFilterModal"
          >
            Cancel
          </p>
          <p class="text-[20px] leading-[24px] font-bold">Filter</p>
          <p
            class="text-stone-800 text-[16px] leading-[20px] font-normal cursor-pointer sm:hidden"
            @click="resetFilter"
          >
            Reset
          </p>
          <button
            class="ml-auto pr-1 relative hidden sm:block"
            @click="closeFilterModal"
          >
            <img
              src="../../../../public/assets/icons/calendar/close-button.svg"
              alt=""
            />
          </button>
        </div>
      </div>
      <section
        class="filter-options-container px-[24px] pt-[48px] sm:px-[40px] sm:pt-[16px]"
      >
        <!-- <YearFilter :year-list="sortDecendingYear" @year-selected="updateYearFilterValue" /> -->
        <div>
          <StatusFilter
            :read-filter-status="readFilterValue"
            :is-unread-selected="isUnreadSelected"
            :is-read-selected="isReadSelected"
            :is-all-selected="isAllSelected"
            :update-toggle="updateToggle"
          />
        </div>
        <div v-if="isCurrBoardAdmin" class="border-t border-[#D3D8DE]">
          <FilterAccordian
            title="Tags"
            :selected="selectedTags"
            class="pb-[16px] md:pb-[24px]"
          >
            <div
              class="filter-options max-h-[calc(100vh-308px)] sm:max-h-[150px] sm:pb-0 overflow-y-scroll overflow-x-hidden"
            >
              <div class="flex flex-col gap-y-[16px]">
                <div
                  v-for="(tag, index) in tags"
                  :key="index"
                  class="flex flex-row"
                >
                  <input
                    :id="index"
                    v-model="tagChecked"
                    type="checkbox"
                    :name="tag"
                    :value="tag"
                  />
                  <label
                    :for="tag"
                    class="ml-2 font-normal text-[16px] leading-[20px]"
                    >{{ tag }}</label
                  >
                </div>
              </div>
            </div>
          </FilterAccordian>
        </div>
        <div
          v-if="myZoneFilterValue !== 'myzone'"
          class="border-t border-[#D3D8DE]"
        >
          <FilterAccordian
            title="Messages from"
            :selected="selectedZones"
            :class="{
              'pb-[16px]': isCurrBoardAdmin,
              'pb-[48px] md:pb-[24px]': !isCurrBoardAdmin,
            }"
          >
            <div
              class="filter-options sm:pb-0 overflow-y-scroll overflow-x-hidden"
              :class="{
                'max-h-[calc(100vh-500px)] sm:max-h-[150px] pb-[120px]':
                  isCurrBoardAdmin,
                'max-h-[calc(100vh-400px)] sm:max-h-[150px] pb-[60px]':
                  !isCurrBoardAdmin,
              }"
            >
              <div class="flex flex-row mb-[16px]">
                <input
                  id="allChurch"
                  v-model="selectAllChurch"
                  type="checkbox"
                  value="church"
                  name="Church"
                  :checked="selectAllChurch || zoneFilters.length === 0"
                  :disabled="zoneChecked.length === 0"
                  :class="{ 'disabled-checkbox': zoneChecked.length === 0 }"
                  @change="selectingAllChurch"
                />
                <label
                  for="allChurch"
                  class="ml-2 font-normal text-[16px] leading-[20px]"
                >
                  Church
                </label>
              </div>
              <div
                class="flex flex-row flex-wrap justify-between overflow-y-scroll overflow-x-hidden"
              >
                <div
                  v-for="(zone, index) in zoneList"
                  :key="index"
                  class="flex flex-row mb-[16px] ml-[16px] w-[35vw] sm:w-[45%]"
                >
                  <input
                    :id="zone"
                    v-model="zoneChecked"
                    type="checkbox"
                    :name="zone"
                    :value="zone"
                  />
                  <label
                    :for="zone"
                    class="ml-2 font-normal text-[16px] leading-[20px]"
                    >{{ shortenZoneName(zone) }}</label
                  >
                </div>
              </div>
            </div>
          </FilterAccordian>
        </div>
      </section>
      <div
        class="w-full bg-white border-t border-gray-400 fixed bottom-0 sm:relative py-4 sm:py-6 flex flex-row justify-center sm:justify-between sm:px-[40px] rounded-b-xl"
      >
        <p
          class="text-[#27251F] text-xl font-normal leading-normal cursor-pointer hidden sm:block"
          @click="resetFilter"
        >
          Reset
        </p>
        <BaseButton
          class="button border-4 border-solid object-center"
          :style="{
            'background-color': currBoard.themeColour,
            'border-color': currBoard.themeColour,
            color: currBoard.themeTextColour,
          }"
          solid-classes="text-black font-semibold"
          data-event-tracking="apply-zone-filter"
          @click="applyFilter"
        >
          Apply Filter
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BaseButton from "@/components/design/BaseButton";
import StatusFilter from "@/components/Board/Filter/StatusFilter";
import FilterAccordian from "@/components/Board/Filter/FilterAccordian.vue";

export default {
  components: {
    FilterAccordian,
    StatusFilter,
    BaseButton,
  },
  data() {
    return {
      zoneList: [],
      zoneChecked: [],
      tagList: [],
      tagChecked: [],
      readFilterStatus: "",
      selectAllChurch: true,
      yearFilterValue: null,
    };
  },
  computed: {
    ...mapState("board", [
      "currBoard",
      "showFilterModal",
      "zoneFilters",
      "myZoneFilterValue",
      "readFilterValue",
      "isCurrBoardAdmin",
      "tags",
      "tagFilters",
    ]),
    ...mapState("lookups", ["zoneCgMapping"]),
    autoFillChecked() {
      if (this.zoneFilters.length > 0) {
        return this.zoneFilters;
      } else {
        return [];
      }
    },
    isAllSelected() {
      return this.readFilterStatus === "all";
    },
    isReadSelected() {
      return this.readFilterStatus === "read";
    },
    isUnreadSelected() {
      return this.readFilterStatus === "unread";
    },
    selectedZones() {
      if (this.zoneChecked.length === 0) {
        return "Church";
      }
      const maxVisibleZones = 5;
      const selectedCount = this.zoneChecked.length;
      if (selectedCount <= maxVisibleZones) {
        return this.zoneChecked.join(", ");
      }
      const visibleZones = this.zoneChecked
        .slice(0, maxVisibleZones)
        .join(", ");
      const remainingCount = selectedCount - maxVisibleZones;
      return `${visibleZones}, +${remainingCount} more`;
    },
    selectedTags() {
      if (this.tagChecked.length === 0) {
        return "None";
      }
      const visibleZones = this.tagChecked.join(",");
      return `${visibleZones}`;
    },
  },
  async created() {
    this.readFilterStatus = this.readFilterValue;
    await this.$store.dispatch("lookups/fetchZoneCgMapping");
    if (this.isCurrBoardAdmin) {
      await this.$store.dispatch("board/fetchTags");
    }
    const zoneMap = this.zoneCgMapping;
    this.zoneList = Array.from(new Set(Object.values(zoneMap))).filter(
      (zone) => zone !== "PIE/VISITORS" && !zone.includes("OLD")
    );
    if (this.zoneChecked.length === 0) {
      this.selectAllChurch = true;
    }
    // Defaults the filter value to the current board's year
    this.yearFilterValue = this.currBoard.year;
  },
  mounted() {
    if (this.zoneFilters.length > 0) {
      this.zoneChecked = this.zoneFilters;
    }
    if (this.tagFilters.length > 0) {
      this.tagChecked = this.tagFilters;
    }
  },
  methods: {
    updateReadFilterStatus(newValue) {
      this.readFilterStatus = newValue;
    },
    updateYearFilterValue(year) {
      this.yearFilterValue = year;
    },
    shortenZoneName(zone) {
      const zoneName = zone.split(" ");
      let finalZoneName = zoneName[0];
      if (zoneName.length > 0) {
        for (let i = 1; i < zoneName.length; i++) {
          finalZoneName += zoneName[i].charAt(0).toUpperCase();
        }
      }
      return finalZoneName;
    },
    closeFilterModal() {
      this.$store.dispatch("board/updateShowFilterModal", false);
    },
    resetFilter() {
      this.zoneChecked = [];
      this.tagChecked = [];
      this.readFilterStatus = "all";
      this.$store.dispatch(
        "board/updateReadFilterValue",
        this.readFilterStatus
      );
      // this.readFilterStatus = 'all'
    },
    applyFilter() {
      this.$store.dispatch(
        "board/updateReadFilterValue",
        this.readFilterStatus
      );
      this.$store.dispatch("board/updateZoneFilterValue", this.zoneChecked);
      this.$store.dispatch("board/updateTagFilterValue", this.tagChecked);
      this.$store.dispatch("board/updateShowFilterModal", false);
      this.$store.dispatch("board/updateCurrBoardYear", this.yearFilterValue);
      this.zoneChecked = this.zoneFilters;
    },
    updateToggle(e) {
      this.readFilterStatus = e.target.value;
    },
    selectingAllChurch() {
      if (this.zoneChecked.length > 0) {
        this.zoneChecked = [];
      }
      this.selectAllChurch = true;
    },
    updateZoneFilterValue(value) {
      const index = this.zoneChecked.indexOf(value);
      if (index !== -1) {
        this.zoneChecked.splice(index, 1);
      } else {
        this.zoneChecked.push(value);
      }
      this.selectAllChurch = false;
    },
    updateTagFilterValue(value) {
      const index = this.tagChecked.indexOf(value);
      if (index !== -1) {
        this.tagChecked.splice(index, 1);
      } else {
        this.tagChecked.push(value);
      }
    },
  },
};
</script>

<style scoped lang="scss">
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 4px;
  border: 0.5px solid;
  accent-color: #6245f4;
}

.disabled-checkbox {
  accent-color: #6245f4;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #b6bec8;
  border-radius: 12px;
}

@media (max-width: 389px) {
  .filter-options-container {
    max-height: 100vh;
    overflow-y: scroll;

    .filter-options {
      max-height: none;
    }
  }
}
</style>
