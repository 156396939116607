<template>
  <div class="text-left inline-block">
    <InputLabel v-bind="{ labelClasses }" :for="id">
      {{ label }}
    </InputLabel>

    <slot />

    <InputError v-bind="{ errorMessageClasses }">
      {{ error }}
    </InputError>

    <InputHelper v-bind="{ helperClasses }">
      {{ helper }}
    </InputHelper>
  </div>
</template>

<script>
import InputError from '@/components/design/InputError'
import InputHelper from '@/components/design/InputHelper'
import InputLabel from '@/components/design/InputLabel'
import HasInputClassProps from '@/mixins/HasInputClassProps'

export default {
  mixins: [HasInputClassProps],

  components: {
    InputLabel,
    InputError,
    InputHelper,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    helper: {
      type: String,
      default: null,
    },
  },
}
</script>
