<template>
  <div>
    <div class="flex flex-row cursor-pointer">
      <TagIcon :is-tag="isTag" :fill="iconColour" />
      <p
        class="ml-[4px] text-[16px] leading-[24px] font-semibold text-[#373E49]"
      >
        Tag Message
      </p>
    </div>
    <div
      v-for="(tag, index) in tags"
      :key="index"
      class="flex flex-row mt-[12px] ml-[32px]"
    >
      <input
        :id="tag.tagId"
        v-model="tag.isSelected"
        type="checkbox"
        :name="tag.tagName"
        :value="tag.tagId"
        :checked="tag.isSelected"
        @change="selectTag(tag)"
      />
      <label :for="tag" class="ml-2 font-normal text-[16px] leading-[20px]">{{
        tag.tagName
      }}</label>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import TagIcon from "../../../../public/assets/icons/board/TagIcon";

export default {
  components: {
    TagIcon,
  },
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMouseOver: false,
    };
  },
  computed: {
    ...mapState("board", ["currBoard", "currPost"]),
    ...mapState("user", ["token"]),
    iconColour() {
      return "#373E49";
    },
    isTag() {
      // Check if at least one tag is selected
      return this.tags.some((tag) => tag.isSelected);
    },
  },
  methods: {
    async selectTag(tag) {
      const requestBody = { tagId: Number(tag.tagId) };

      if (!tag.isSelected) {
        // If the tag is now unselected, send a DELETE request
        await axios.request(
          process.env.VUE_APP_API_URL +
            `/board/${this.currPost.boardId}/posts/${this.currPost.id}/untag`,
          {
            method: "post",
            data: { requestBody },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      } else {
        // If the tag is now selected, send a POST request
        await axios.request(
          process.env.VUE_APP_API_URL +
            `/board/${this.currPost.boardId}/posts/${this.currPost.id}/tag`,
          {
            method: "post",
            data: { requestBody },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      }
      // Emit updated tags
      const updatedTags = this.tags
        .filter((tag) => tag.isSelected)
        .map((tag) => tag.tagName);
      this.$emit("tags-updated", {
        postId: this.currPost.id,
        tags: updatedTags,
      });
    },
  },
};
</script>

<style scoped>
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 4px;
  border: 0.5px solid;
  accent-color: #6245f4;
}

.disabled-checkbox {
  accent-color: black;
}
</style>
