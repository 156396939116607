<template>
  <svg
    v-if="isFavouriteActive"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.167 21.35L10.717 20.03C5.56699 15.36 2.16699 12.27 2.16699 8.5C2.16699 5.41 4.58699 3 7.66699 3C9.40699 3 11.077 3.81 12.167 5.08C13.257 3.81 14.927 3 16.667 3C19.747 3 22.167 5.41 22.167 8.5C22.167 12.27 18.767 15.36 13.617 20.03L12.167 21.35Z"
      fill="#FF2E00"
    />
  </svg>
  <svg
    v-else
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1 18.55L12 18.65L11.89 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 6 11.07 7.36H12.93C13.46 6 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55ZM16.5 3C14.76 3 13.09 3.81 12 5.08C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.41 2 8.5C2 12.27 5.4 15.36 10.55 20.03L12 21.35L13.45 20.03C18.6 15.36 22 12.27 22 8.5C22 5.41 19.58 3 16.5 3Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isFavouriteActive: {
      type: Boolean,
      required: true,
    },
    fill: {
      type: String,
      default: 'black',
    },
  },
}
</script>
