<template>
  <master-layout>
    <div
      class="md:py-[40px] md:pl-[40px] lg:pr-[30px] sm:px-[24px] py-[24px] lg:py-[56px] lg:pl-[64px] lg:flex-1 lg:overflow-hidden mb-[24px]"
    >
      <section class="px-[24px] sm:px-0 z-10">
        <h1
          class="text-[32px] leading-[40px] sm:text-[48px] sm:leading-[64px] font-bold mb-[4px] text-[#0B0C0F]"
        >
          Happenings
        </h1>
        <p class="text-[16px] leading-[24px] text-[#21252C]">
          Stay in the know about the latest happenings & buzz in church!
        </p>
      </section>

      <section
        v-if="!isLoading && upcomingEvents.length > 0"
        class="sm:mt-[56px] mt-[24px]"
      >
        <h1 class="font-bold text-xl px-[24px] sm:px-0">Upcoming events</h1>
        <HappeningsSlider
          ref="home-page-happenings"
          baseSwiperId="home-page-happenings"
          :events="upcomingEvents"
        />
      </section>

      <section v-if="!isLoading && weeklyEvents.length > 0" class="mt-16">
        <h1 class="font-bold text-xl px-[24px] sm:px-0">Weekly events</h1>
        <HappeningsSlider
          ref="home-page-happenings"
          baseSwiperId="home-page-happenings"
          :events="weeklyEvents"
        />
      </section>
    </div>
  </master-layout>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import HappeningsSlider from "@/components/HappeningsPage/HappeningsSlider.vue";

export default {
  components: {
    HappeningsSlider,
  },
  computed: {
    ...mapState("cms", ["upcomingEvents", "weeklyEvents"]),
    ...mapGetters(["isLoading"]),
  },
  async ionViewWillEnter() {
    await Promise.all([
      this.$store.dispatch("cms/fetchCmsContent", {
        entityName: "upcoming-events",
        stateName: "upcomingEvents",
      }),
      this.$store.dispatch("cms/fetchCmsContent", {
        entityName: "weekly-events",
        stateName: "weeklyEvents",
      }),
      this.$store.dispatch("updates/getUpdates", "all")
    ]);
  },
};
</script>
