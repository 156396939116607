<template>
  <master-layout>
    <QrCode
    class="
      relative
      bg-transparent
      min-h-[calc(var(--app-height)-88px)]
      flex
      justify-center
      lg:flex-1
    "
  />
  </master-layout>
</template>

<script>
import QrCode from '@/components/HomePage/QrCode'

export default {
  components: { QrCode },
  async ionViewWillEnter() {
    await this.$store.dispatch("updates/getUpdates", "all");
  }
}
</script>