<template>
    <master-layout>
        <div class="lg:flex-1 lg:overflow-hidden mb-[24px]">
            <section
                class="pt-[40px] pb-[24px] md:pl-[40px] lg:pr-[30px] px-[24px] lg:pl-[56px] z-10"
            >
                <h1
                    class="text-[32px] leading-[40px] sm:text-[48px] sm:leading-[64px] font-bold text-[#0B0C0F]"
                >
                    Resources
                </h1>
            </section>

            <p
                v-if="loading"
                class="text-gray-500 text-center m-0 pt-[24px] pb-[40px] lg:pr-[30px] lg:pl-[56px]"
            >
                Loading...
            </p>

            <div v-else class="pt-[24px] pb-[40px] lg:pr-[30px] lg:pl-[56px]">
                <template v-for="(section, i) of sections">
                    <section
                        v-if="section.items.length"
                        :key="i"
                        class="sm:mt-[32px] mt-[40px]"
                    >
                        <p
                            class="font-sans not-italic font-bold text-[20px] leading-[24px] text-[0B0C0F] m-0 px-[24px] lg:px-0"
                        >
                            {{ section.title }}
                        </p>

                        <div class="mt-[12px]">
                            <ResourceSlider
                                :resources="section.items"
                                enable-copy
                            />
                        </div>
                    </section>
                </template>
            </div>
        </div>
    </master-layout>
</template>

<script>
import { mapState } from "vuex";
import ResourceSlider from "@/components/ResourcesPage/ResourcesSlider.vue";

export default {
    components: { ResourceSlider },
    computed: {
        ...mapState("cms", [
            "loading",
            "downloads",
            "personalGrowths",
            "highlights",
            "identityResources",
        ]),
        sections() {
            return [
                { title: "Downloads", items: this.downloads },
                { title: "Portals", items: this.personalGrowths },
                { title: "Highlights", items: this.highlights },
                {
                    title: "IDRM & GenerationS Website",
                    items: this.identityResources,
                },
            ];
        },
    },
    async ionViewWillEnter() {
        await Promise.all([
            this.$store.dispatch("cms/fetchCmsContent", {
                entityName: "downloads",
                stateName: "downloads",
            }),
            this.$store.dispatch("cms/fetchCmsContent", {
                entityName: "personal-growths",
                stateName: "personalGrowths",
            }),
            this.$store.dispatch("cms/fetchCmsContent", {
                entityName: "highlights",
                stateName: "highlights",
            }),
            this.$store.dispatch("cms/fetchCmsContent", {
                entityName: "identity-resources",
                stateName: "identityResources",
            }),
            this.$store.dispatch("updates/getUpdates", "all")
        ]);
    },
};
</script>
