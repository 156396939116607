import { mapState } from 'vuex'
import PasswordConditions from '@/components/common/PasswordConditions'
import { REGISTER_STAGE } from '../store/modules/form'

export default {
  components: { PasswordConditions },
  data () {
    return {
      newPassword: '',
      confirmPassword: '',
    }
  },
  computed: {
    ...mapState('form', ['register']),

    passwordValue () {
      const isRegistering = this.register.stage === REGISTER_STAGE.SET_PASSWORD
      return isRegistering ? this.register.password : this.newPassword
    },

    confirmPasswordValue () {
      const isRegistering = this.register.stage === REGISTER_STAGE.SET_PASSWORD
      return isRegistering ? this.register.confirmPassword : this.confirmPassword
    },

    hasEightCharacters () {
      return this.passwordValue.length >= 8
    },

    hasOneUpperCase () {
      return /[A-Z]/.test(this.passwordValue)
    },

    hasOneLowerCase () {
      return /[a-z]/.test(this.passwordValue)
    },

    hasOneNumberOrSymbol () {
      return (
        /\d/.test(this.passwordValue) ||
        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(this.passwordValue)
      )
    },

    passwordsDoNotMatch () {
      // if either field is not yet filled, don't throw an error
      if (!this.passwordValue || !this.confirmPasswordValue) {
        return false
      }

      // both fields are filled, thus throw an error if they don't match
      return this.passwordValue !== this.confirmPasswordValue
    },

    canSave () {
      return (
        this.hasEightCharacters &&
        this.hasOneUpperCase &&
        this.hasOneLowerCase &&
        this.hasOneNumberOrSymbol &&
        this.passwordValue &&
        this.confirmPasswordValue &&
        !this.passwordsDoNotMatch
      )
    },
  },
}
