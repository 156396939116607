export default {
  props: {
    labelClasses: {
      type: [String, Array, Object],
      default: 'text-[14px] leading-[20px] text-[#637083] mb-[4px] block',
    },
    helperClasses: {
      type: [String, Array, Object],
      default: 'text-[16px] text-[#373E49] leading-[24px] mt-[4px] block',
    },
    errorMessageClasses: {
      type: [String, Array, Object],
      default: 'text-[16px] text-[#B80A0A] leading-[24px] mt-[4px] block',
    },
  },
}
