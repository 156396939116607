import axios from "axios";

const INITIAL_STATE = {
  isLoading: false,
  overview: [],
  serviceReplays: {},
}

const state = () => INITIAL_STATE

const mutations = {
  setIsLoading(state, isLoading) {
    state.isServiceReplayLoading = isLoading
  },
  setOverview(state, overview) {
    state.overview = overview
  },
  addServiceReplay(state, newServiceReplay) {
    state.serviceReplays[newServiceReplay.serviceReplayId] = newServiceReplay
  },
}

const actions = {
  async fetchOverview({ state, commit, dispatch, rootState }) {
    try {
      commit('setIsLoading', true)
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + '/cms/service-replay/overview',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      commit('setOverview', data)
      commit('setIsLoading', false)
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch service replay overview', { root: true })
    }
  },
  async fetchServiceReplayById({ state, commit, dispatch, rootState }, { serviceReplayId }) {
    try {
      if (state["serviceReplays"][serviceReplayId]) {
        return
      }
      commit('setIsLoading', true)
      const accessToken = rootState.user.token;
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + '/cms/service-replay/' + serviceReplayId,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      commit('addServiceReplay', data)
      commit('setIsLoading', false)
    } catch (e) {
      dispatch('toast/error', 'Failed to fetch service replay overview', { root: true })
    }
  },
}

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  overview(state) {
    return state.overview
  },
  serviceReplays(state) {
    return state.serviceReplays
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
