import HasLocalValue from '@/mixins/HasLocalValue'

export default {
  mixins: [HasLocalValue],

  props: {
    options: {
      type: Array,
      required: true,
    },
    labelField: {
      type: String,
      default: null,
    },
    valueField: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    noOptionsText: {
      type: String,
      default: 'No options available',
    },
  },

  computed: {
    selectedText() {
      const labelField = this.labelField
      const valueField = this.valueField
      const localValue = this.localValue

      if (!localValue) {
        return this.placeholder
      }

      if (!labelField) {
        return localValue
      }

      const selectedOption = this.options.find((option) => option[valueField] == localValue)

      if (!selectedOption) {
        return localValue
      }

      return selectedOption[labelField]
    },
  },

  methods: {
    getValue(option) {
      return this.valueField ? option[this.valueField] : option
    },
    getLabel(option) {
      return this.valueField ? option[this.labelField] : option
    },
    select(option) {
      this.localValue = this.getValue(option)
      this.close()
    },
  },
}
