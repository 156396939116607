<template>
  <div
    class="
      fixed
      inset-0
      z-[100]
      flex
      items-center
      justify-center
      w-screen
      h-screen
      overflow-y-auto
      modal
      lg:hidden
    "
  >
    <div
      class="
        bg-[#F8F9F9]
        max-w-full
        w-full
        h-full
        flex flex-col
        relative
        overflow-y-auto
        sm:max-w-[441px] sm:h-auto sm:rounded-lg
      "
    >
      <button class="absolute z-50 top-7 right-7" @click="$emit('close')">
        <CancelIcon />
      </button>

      <section class="text-center modal-head min-h-[250px] sm:rounded-t-lg">
        <Avatar class="mx-auto w-[64px] h-[64px] mt-[96px] avatar-profile-modal" />
        <label class="block mt-[16px] font-bold text-[20px] leading-[24px]">
          {{ fullname }}
        </label>
      </section>

      <nav class="max-w-[295px] w-full mx-auto mt-[24px] lg:mt-[48px]">
        <OutlineButton
          v-for="(link, i) in personalLinks"
          :key="i"
          :icon="link.icon"
          :to="link.to"
          :label="link.label"
          :is-external="link.isExternal"
          class="mb-[12px]"
          @clicked-button="$emit('close')"
        />
      </nav>

      <div class="mt-[40px] lg:mt-[80px] text-center">
        <button class="text-lg font-bold text-[#B80A0A]" @click="logoutUser"
          >Log out</button
        >
      </div>

      <div class="py-[24px] mt-auto text-center">
        <a
          class="text-[12px] leading-[20px] text-[#373E49]"
          href="https://privacy.heartofgodchurch.org/"
          target="_blank"
          >Privacy policy</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OutlineButton from '../common/OutlineButton.vue'
import Avatar from '../icons/Avatar.vue'
import CancelIcon from '@/components/icons/Cancel'
import OutlinePasswordLockIcon from '@/components/icons/OutlinePasswordLock'
import OutlineEditProfileIcon from '@/components/icons/OutlineEditProfile'
import OutlineImpressionSlipIcon from '@/components/icons/OutlineImpressionSlip'
import Logout from '@/mixins/Logout.js'

export default {
  components: {
    CancelIcon,
    OutlineButton,
    Avatar,
  },
  data () {
    return {
      personalLinks: [
        { to: '/change-password', label: 'Change password', icon: OutlinePasswordLockIcon },
        { to: '/profile', label: 'Edit Profile', icon: OutlineEditProfileIcon },
        { to: '/impression', label: 'Send Impression Slip', icon: OutlineImpressionSlipIcon },
      ],
    }
  },
  mixins: [Logout],
  computed: {
    ...mapGetters({
      fullname: 'profile/fullname',
    }),
  },
}
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.7);
}

.modal-head {
  background: linear-gradient(135deg, #ffe543 0.13%, #ffcd19 100.13%);
  clip-path: circle(340px at 50% -102px);
}
</style>
