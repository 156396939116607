<template>
  <div>
    <div class="font-bold text-[16px] xxs:text-[18px] leading-[20px]" :class="hasPast ? pastClass : futureDateClass">
      <h3 v-if="startDate == endDate">{{ formatDateNumber(startDate) }}</h3>
      <h3 v-if="startDate != endDate && formatMonth(startDate) == formatMonth(endDate)">
        {{ formatDateNumber(startDate) }} - {{ formatDateNumber(endDate) }}
      </h3>
      <h3 v-if="formatMonth(startDate) != formatMonth(endDate)">
        <div>
          {{ formatDateNumber(startDate) }} {{ formatMonth(startDate) }}
        </div>
        <div>
          - {{ formatDateNumber(endDate) }} {{ formatMonth(endDate) }}
        </div>
      </h3>
    </div>
    <div class="block font-normal text-[14px] leading-[18px] mt-[4px]" :class="hasPast ? pastClass : futureDayClass">
      <span v-if="startDate == endDate">{{ formatDay(startDate) }}</span>
      <span v-if="startDate != endDate && formatMonth(startDate) == formatMonth(endDate)">
        {{ formatDay(startDate) }} - {{ formatDay(endDate) }}
      </span>
      <span v-if="formatMonth(startDate) != formatMonth(endDate)">
        {{ formatDay(startDate) }} - {{ formatDay(endDate) }}
      </span>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    hasPast: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      pastClass: 'text-[#7C8A9C]',
      futureDateClass: 'text-[#0B0C0F]',
      futureDayClass: 'text-[#637083]',
    }
  },
  methods: {
    formatDateNumber (date) {
      if (typeof date === 'string') {
        return dayjs(date).format('D')
      }

      return `${dayjs(date.start).format('D')} - ${dayjs(date.end).format('D')}`
    },
    formatDay (date) {
      if (typeof date === 'string') {
        return dayjs(date).format('ddd')
      }

      return `${dayjs(date.start).format('ddd')} - ${dayjs(date.end).format('ddd')}`
    },
    formatMonth (date) {
      if (typeof date === 'string') {
        return dayjs(date).format('MMM')
      }

      return dayjs(date.start).format('MMM')
    },
  },
}
</script>

<style scoped>
* {
  font-family: 'proxima-nova';
  font-style: normal;
}
</style>
