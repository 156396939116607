<template>
  <div>
    <div v-if="isPinned" class="flex flex-row mb-1">
      <img class="align-middle mr-1" src="../../../../public/assets/icons/board/pinned.svg" />
      <p class="align-middle text-[12px] leading-[22px] text-[#000000] opacity-50">Pinned</p>
    </div>
    <div v-if="isHidden" class="flex flex-row mb-1">
      <img class="align-middle mr-1" src="../../../../public/assets/icons/board/hidden.svg" />
      <p class="align-middle text-[12px] leading-[22px] text-[#000000] opacity-50">Hidden</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isHidden: {
      type: Boolean,
      default: false,
    },
    isPinned: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
