<template>
    <div class="h-full">
        <a
            class="w-full h-full relative rounded-[24px] overflow-hidden"
            :href="link"
            :target="isExternal ? '_blank' : false"
            :data-event-tracking="dataEventTracking"
        >
            <img
                class="block w-full h-full rounded-[24px] overflow-hidden"
                :src="imageSrc"
                :data-event-tracking="dataEventTracking"
            />
            <div
                v-if="enableCopy"
                class="absolute bottom-2 right-2 flex items-center"
            >
                <button
                    v-if="canCopy"
                    class="mr-2 rounded-full"
                    type="button"
                    @click.prevent="copyLink"
                >
                    <img
                        class="block w-[32px] h-[32px]"
                        src="../../../public/assets/icons/resources/copy.svg"
                    />
                </button>
                <img v-else class="w-[32px] h-[32px]" :src="iconSrc" />
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        iconSrc: {
            type: String,
            default: "",
        },
        imageSrc: {
            type: String,
            default: "",
        },
        link: {
            type: String,
            default: "",
        },
        dataEventTracking: {
            type: String,
            default: "",
        },
        canCopy: {
            type: Boolean,
            default: false,
        },
        enableCopy: {
            type: Boolean,
            default: false,
        },
        isExternal: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        copyLink(e) {
            navigator.clipboard.writeText(this.link).then(() => {
                e.target.src = require("../../../public/assets/icons/resources/copied.svg");
            });
        },
    },
};
</script>
