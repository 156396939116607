<template>
  <div class="ml-auto relative flex flex-row items-center">
    <button class="mr-[8px]" @click="updateShowCalendarFilters">
      <div
        v-if="filters.length > 0"
        class="text-xs text-white font-semibold sm:leading-4 md:leading-[16.5px]"
      >
        <p v-if="filters.length == 1" class="absolute left-[15.3px]">
          {{ filters.length }}
        </p>
        <p v-else class="absolute left-[14.3px]">{{ filters.length }}</p>
        <img src="../../../../public/assets/icons/calendar/filtered-icon.svg" alt="filtered-icon" />
      </div>
      <div v-else>
        <img src="../../../../public/assets/icons/calendar/filter-icon.svg" alt="filter-icon" />
      </div>
    </button>
    <!-- <button ref="calendarNotificationsBell" class="w-6 h-6" @click="updateShowCalendarNotifications">
      <img id="notificationCalButton" :src="bellSrc" alt="calendar-notifications-bell-icon" />
    </button> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('calendar', ['filters', 'showCalendarNotifications', 'hasNewNotification']),
    // bellSrc () {
    //   if (this.showCalendarNotifications) {
    //     return require('../../../../public/assets/icons/calendar/calendar-notifications-active-bell.svg')
    //   } else if (this.hasNewNotification) {
    //     return require('../../../../public/assets/icons/calendar/calendar-notifications-dynamic-bell.gif')
    //   } else {
    //     return require('../../../../public/assets/icons/calendar/calendar-notifications-static-bell.svg')
    //   }
    // },
  },
  methods: {
    async updateShowCalendarFilters () {
      await this.$store.dispatch('calendar/updateShowCalendarFilters', true)
    },
    // async updateShowCalendarNotifications () {
    //   await this.$store.dispatch('calendar/updateShowCalendarMenu', false)
    //   await this.$store.dispatch('calendar/updateShowCalendarNotifications', !this.showCalendarNotifications)
    // },
  },
}
</script>
