<template>
  <photo-layout>
    <div id="photo-drop" class="md:pb-[40px] pb-[24px] lg:pb-[56px] lg:flex-1 lg:overflow-hidden mb-[24px]">
      <div v-if="loading" class="w-screen h-screen flex items-center justify-center">
        <Loading />
      </div>
      <PhotosQrCode v-else-if="isPhotosEmpty || isShowQr" />

      <div v-else>
        <PhotoGallery
          v-for="(day, index) in photos"
          :key="index"
          :date="day.date"
          :links="day.links"
          :has-tooltip="index == 0"
          :viewport-width="viewportWidth"
        />
      </div>
    </div>
  </photo-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PhotosQrCode from '@/components/Photo/PhotosQrCode'
import PhotoLayout from '@/components/PhotoLayout.vue'
import PhotoGallery from '@/components/Photo/Gallery.vue'
import Loading from "@/components/common/LoadingState.vue";

export default {
  components: {
    PhotosQrCode,
    PhotoLayout,
    PhotoGallery,
    Loading
  },
  data () {
    return {
      viewportWidth: null,
    }
  },
  computed: {
    ...mapState('photos', ['photos', 'isShowQr', 'loading']),
    ...mapGetters('photos', ['isPhotosEmpty']),
  },
  async ionViewWillEnter () {
    await this.$store.dispatch('photos/fetchPhotos')
    await this.$store.dispatch("updates/getUpdates", "all")
    this.viewportWidth = window.innerWidth
    window.addEventListener('resize', this.checkViewportWidth)
  },
  async ionViewDidLeave () {
    if(this.isShowQr && !this.isPhotosEmpty){
      await this.$store.dispatch('photos/updateIsShowQr', !this.isShowQr)
    }
    window.removeEventListener('resize', this.checkViewportWidth)
  },
  methods: {
    checkViewportWidth () {
      this.viewportWidth = window.innerWidth
    },
  },
}
</script>
