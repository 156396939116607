<template>
  <div class="relative flex m-[8px]">
    <div class="flex items-center h-5">
      <input
        :id="id"
        type="checkbox"
        class="
          form-tick
          rounded-[4px]
          appearance-none
          border border-[#080A6E]
          checked:bg-[#080A6E] checked:border-transparent
          hover:cursor-pointer
          disabled:cursor-default
          disabled:border-[#D3D8DE]
          disabled:bg-[#D3D8DE]
          focus:shadow-blue
        "
        :class="inputSizeClasses"
        :value="val"
        v-model="checked"
        @change="onChange"
        :disabled="disabled"
      />
    </div>
    <div class="ml-[8px] text-sm">
      <label
        :for="id"
        class="block text-black"
        :class="[labelSizeClasses, disabledClasses]"
      >
        <slot />
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default() {
        // create a unique ID
        return 'checkbox-' + Math.floor(Math.random() * 1000)
      },
    },
    value: {
      type: [Array, Number, String, Boolean],
      default: null,
    },
    val: {
      type: [Number, String],
      default: null,
    },
    size: {
      type: String,
      validator(value) {
        return ['small', 'large'].includes(value)
      },
      default: 'small',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checkedProxy: false,
    }
  },

  computed: {
    checked: {
      get() {
        return this.value
      },
      set(val) {
        this.checkedProxy = val
      },
    },
    inputSizeClasses() {
      return {
        small: 'h-[20px] w-[20px] mt-[2px]',
        large: 'h-[24px] w-[24px] mt-[5px]',
      }[this.size]
    },
    labelSizeClasses() {
      return {
        small: 'text-[16px] leading-[24px]',
        large: 'text-[20px] leading-[28px]',
      }[this.size]
    },
    disabledClasses() {
      return this.disabled ? 'text-[#99A4B2] cursor-default' : 'cursor-pointer'
    },
  },

  methods: {
    onChange() {
      this.$emit('update:modelValue', this.checkedProxy)
    },
  },
}
</script>

<style>
.form-tick:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}
</style>
