<template>
  <section v-if="justForYous.length > 0 && !isLoading" class="z-10">
    <div class="flex flex-row items-center justify-between mb-[8px]">
      <h2
        class="text-[18px] leading-[24px] text-[#0B0C0F] font-medium not-italic"
      >
        Just for you
      </h2>
      <ion-button
        id="open-modal"
        expand="block"
        fill="clear"
        size="small"
        class="text-[14px] leading-[20px] text-[#6245F4] cursor-pointer underline"
        >View All</ion-button
      >
    </div>
    <div class="flex flex-row flex-wrap gap-x-[12px] lg:gap-x-[12px]">
      <ColoredButton
        v-for="(content, i) in justForYous"
        :key="i"
        :icon="content.icon"
        :to="content.link"
        :label="content.label"
        :is-external="content.isExternal"
        :data-event-tracking="content.dataEventTracking"
        class="mb-[8px] lg:mb-[12px] pl-[4px] w-[72px]"
      />
    </div>
    <ion-modal
      ref="modal"
      trigger="open-modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 1]"
    >
      <div class="block flex flex-col gap-x-8 px-[24px]">
        <ion-content>
        <h2
          class="mt-[24px] mb-[12px] text-[18px] leading-[24px] text-[#0B0C0F] font-bold not-italic"
        >
          Resources
        </h2>
        <div class="flex flex-row flex-wrap gap-x-[12px] lg:gap-x-[12px]">
          <ColoredButton
            v-for="(content, i) in justForYous"
            :key="i"
            :icon="content.icon"
            :to="content.link"
            :label="content.label"
            :is-external="content.isExternal"
            :data-event-tracking="content.dataEventTracking"
            class="mb-[8px] lg:mb-[12px] pl-[4px] w-[72px] lg:w-[264px]"
            @click="dismiss"
          />
        </div>
        <h2
          class="mb-[12px] text-[18px] leading-[24px] text-[#0B0C0F] font-bold not-italic"
        >
          Others
        </h2>
        <div class="flex flex-row flex-wrap gap-x-[12px] lg:gap-x-[12px]">
          <ColoredButton
            v-for="(content, i) in justForYous"
            :key="i"
            :icon="content.icon"
            :to="content.link"
            :label="content.label"
            :is-external="content.isExternal"
            :data-event-tracking="content.dataEventTracking"
            class="mb-[8px] lg:mb-[12px] pl-[4px] w-[72px] lg:w-[264px]"
            @click="dismiss"
          />
        </div>
        <h2
          class="mb-[12px] text-[18px] leading-[24px] text-[#0B0C0F] font-bold not-italic"
        >
          Portals
        </h2>
        <div class="flex flex-row flex-wrap gap-x-[12px] lg:gap-x-[12px]">
          <ColoredButton
            v-for="(content, i) in justForYous"
            :key="i"
            :icon="content.icon"
            :to="content.link"
            :label="content.label"
            :is-external="content.isExternal"
            :data-event-tracking="content.dataEventTracking"
            class="mb-[8px] lg:mb-[12px] pl-[4px] w-[72px] lg:w-[264px]"
            @click="dismiss"
          />
        </div>
      </ion-content>
      </div>
    </ion-modal>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ColoredButton from "@/components/HomePage/ColoredButtonNew.vue";
import { IonButton, IonModal, IonContent } from "@ionic/vue";

export default {
  components: {
    ColoredButton,
    IonButton,
    IonModal,
    IonContent,
  },
  computed: {
    ...mapState("cms", ["justForYous"]),
    ...mapGetters("loader", ["isLoading"]),
  },
  methods: {
    dismiss() {
        this.$refs.modal.$el.dismiss();
      },
  }
};
</script>
<style scoped>
.block {
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 24px 24px;
  border-top-right-radius: 24px 24px;
}

ion-modal {
  --height: auto;
  --border-radius: 24px 24px 0px 0px !important;
  --backdrop-opacity: 0.8;
  --ion-backdrop-color: #1f1f29cc;
}

ion-content {
  --offset-bottom: auto!important;
  --overflow: hidden;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
