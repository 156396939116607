<template>
  <div>
    <router-link
      v-if="!isExternal"
      class="flex items-center flex-row justify-center relative w-[64px] h-[64px] hover:bg-[#F8F7FF] overflow-hidden"
      :to="to"
      :data-event-tracking="dataEventTracking"
    >
      <img class="z-[1]" :src="icon" alt="" />
      <img
        class="absolute right-[0px] bottom-0 z-0"
        src="../../../public/assets/icons/home/circle-bg.svg"
      />
    </router-link>
    <a
      v-else
      class="flex items-center flex-row justify-center relative w-[64px] h-[64px] hover:bg-[#F8F7FF] overflow-hidden"
      :href="to"
      target="_blank"
      :data-event-tracking="dataEventTracking"
    >
      <img class="z-[1]" :src="icon" alt="" />
      <img
        class="absolute right-[0px] bottom-0 z-0"
        src="../../../public/assets/icons/home/circle-bg.svg"
      />
    </a>
    <div class="z-[1] mt-[4px] line-clamp-2 font-normal text-[14px] leading-[20px] text-center text-[#4D5766] hover:text-[#0B0C0F]">{{
        label
      }}</div>
  </div>
</template>

<script>
export default {
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: null,
      required: true,
    },
    icon: {
      type: String,
      default: null,
      required: true,
    },
    dataEventTracking: {
      type: String,
      default: null,
    },
  },
};
</script>
