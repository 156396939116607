<template>
  <birthday-layout>
    <div>
    <BoardContent v-if="this.isYearSelected" class="pt-[152px] md:pt-[200px]" />
    <BoardYearContent v-else class="pt-[64px]" />
    <WriteMessageButton />
  </div>
  </birthday-layout>
</template>

<script>
import { mapState } from 'vuex'
import BirthdayLayout from "@/components/BirthdayLayout.vue";
import BoardContent from '@/components/Board/Main/BoardContent'
import BoardYearContent from '@/components/Board/Main/BoardYearContent'
import WriteMessageButton from '@/components/Board/Main/WriteMessageButton'

export default {
  components: {
    BoardContent,
    BoardYearContent,
    WriteMessageButton,
    BirthdayLayout
  },
  computed: {
    ...mapState('board', ['currBoard', 'isYearSelected']),
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("updates/getUpdates", "all");
  },
}
</script>
