<template>
  <ion-page>
    <ion-content fullscreen="false" class="birthday">
      <main>
        <div class="w-full h-full min-h-screen bg-[#F0F2F4]">
          <!-- <Loading v-if="isLoading" />
          <div v-else> -->
          <Transition name="slide">
            <SideBar v-if="showSideBar" :is-main-page="true" />
          </Transition>
          <div :class="opaqueClass">
            <div
              class="fixed w-full z-10"
              :class="{ 'pt-[44px] h-[96px]': isIos() }"
            >
              <BoardHeader
                v-if="$route.path !== '/birthdayboard/message'"
                :show-board-change="true"
              />
              <FilterBar v-if="showFilterBar" />
            </div>
            <slot class="flex-1 pb-16 lg:pb-0" test="test"> </slot>
          </div>
        </div>
        <!-- </div> -->
      </main>
    </ion-content>
  </ion-page>
</template>
<script>
import { IonPage, IonContent } from "@ionic/vue";
import { mapState } from "vuex";
import Loading from "@/components/common/LoadingState";
import BoardHeader from "@/components/Board/Main/BoardHeader";
import SideBar from "@/components/Board/Main/SideBar";
import FilterBar from "@/components/Board/Filter/FilterBar";
import { isIosNativeOnly } from "@/utils/platform-check";

export default {
  components: {
    IonPage,
    IonContent,
    Loading,
    BoardHeader,
    SideBar,
    FilterBar,
  },

  computed: {
    ...mapState("board", [
      "isLoading",
      "currBoard",
      "showSideBar",
      "isYearSelected",
    ]),
    opaqueClass() {
      return this.showSideBar ? "brightness-50 bg-[#F0F2F4] h-[100vh]" : "";
    },
    sideBarActive() {
      return this.showSideBar ? "no-scroll" : "";
    },
    showFilterBar() {
      // Don't show when we are writing message
      if (this.$route.path === "/birthdayboard/message") return false;
      // if path is main page, we return base on whether is Year selected or not
      if (this.$route.path === "/birthdayboard") return this.isYearSelected;
      // else always return true
      return true;
    },
    isIos() {
      return isIosNativeOnly;
    },
  },
  async mounted() {
    await this.$store.dispatch("board/fetchBoardThemes");
    let cookieBoard = "";
    if (this.$cookies.get("cookieBoard") === undefined) {
      cookieBoard = null;
    } else {
      cookieBoard = this.$cookies.get("cookieBoard");
    }
    const query = this.$route;
    console.log('query', query)
    const regex = /\?(.+)/;
    const match = query.fullPath.match(regex);
    if (match) {
      const queryParameter = match[1];
      if (queryParameter === "spl") {
        await this.fetchBoard(1);
      }
      if (queryParameter === "spg") {
        await this.fetchBoard(2);
      }
      if (queryParameter === "sph") {
        await this.fetchBoard(3);
      }
      if (queryParameter === "spc") {
        await this.fetchBoard(4);
      }
      if (queryParameter === "splyn") {
        await this.fetchBoard(5);
      }
    } else {
      await this.$store.dispatch("board/fetchBoards", null, cookieBoard);
      await this.$store.dispatch(
        "board/fetchIsCurrBoardAdmin",
        this.currBoard ? this.currBoard.id : 0
      );
    }
  },
  async created() {
    await Promise.all([this.$store.dispatch("lookups/fetchZoneCgMapping")]);
  },
  methods: {
    async fetchBoard(queryBoardId) {
      console.log("fetchBoard", queryBoardId);
      await this.$store.dispatch("board/fetchBoards", queryBoardId);
      await this.$store.dispatch("board/fetchIsCurrBoardAdmin", queryBoardId);
      this.$router.replace({ fullPath: this.$route.path });
    },
  },
};
</script>
<style scoped>
.no-scroll {
  overflow: hidden;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.5s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
