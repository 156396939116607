import axios from "axios";

let BOARD_THEME_COLOUR_MAP = {};
let BOARD_THEME_TEXT_COLOUR_MAP = {};
let BOARD_HOVER_COLOUR_MAP = {};
let BOARD_HOVER_TEXT_COLOUR_MAP = {};
let BOARD_NAVBAR_BACKGROUND_MAP = {};
let BOARD_SIDEBAR_BACKGROUND_MAP = {};
let BOARD_SELECT_NAME_MAP = {};

const state = () => ({
  isLoading: true,
  formHeader: null,
  submitButton: null,
  currPost: null,
  isBoardToastOpen: false,
  boardToastMessage: "",
  filePreview: "",
  isCurrBoardAdmin: false,
  boards: [],
  showSideBar: false,
  showOptionsModal: false,
  showFilterModal: false,
  currBoard: {},
  readFilterValue: "all",
  myZoneFilterValue: "all",
  tags: [],
  zoneFilters: [],
  tagFilters: [],
  showDeletePage: false,
  isYearSelected: false,
});

const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setFormHeader(state, formHeader) {
    state.formHeader = formHeader;
  },
  setSubmitButton(state, submitButton) {
    state.submitButton = submitButton;
  },
  setCurrPost(state, currPost) {
    state.currPost = currPost;
  },
  setIsBoardToastOpen(state, isBoardToastOpen) {
    state.isBoardToastOpen = isBoardToastOpen;
  },
  setBoardToastMessage(state, message) {
    state.boardToastMessage = message;
  },
  setFilePreview(state, filePreview) {
    state.filePreview = filePreview;
  },
  setIsCurrBoardAdmin(state, isCurrBoardAdmin) {
    state.isCurrBoardAdmin = isCurrBoardAdmin;
  },
  setBoards(state, boards) {
    boards.forEach((board) => {
      board.selectName = BOARD_SELECT_NAME_MAP[board.name];
      board.year = board.yearList[board.yearList.length - 1];
      board.themeColour = BOARD_THEME_COLOUR_MAP[board.name][board.year];
      board.themeTextColour =
        BOARD_THEME_TEXT_COLOUR_MAP[board.name][board.year];
      board.hoverColour = BOARD_HOVER_COLOUR_MAP[board.name][board.year];
      board.hoverTextColour =
        BOARD_HOVER_TEXT_COLOUR_MAP[board.name][board.year];
      board.navbarBackground =
        BOARD_NAVBAR_BACKGROUND_MAP[board.name][board.year];
      board.sidebarBackground =
        BOARD_SIDEBAR_BACKGROUND_MAP[board.name][board.year];
    });
    state.boards = boards;
  },
  setShowSideBar(state, showSideBar) {
    state.showSideBar = showSideBar;
  },
  setShowOptionsModal(state, showOptionsModal) {
    state.showOptionsModal = showOptionsModal;
  },
  setShowFilterModal(state, showFilterModal) {
    state.showFilterModal = showFilterModal;
  },
  setCurrBoard(state, currBoard) {
    state.currBoard = currBoard;
  },
  setReadFilterValue(state, readFilterValue) {
    state.readFilterValue = readFilterValue;
  },
  setMyZoneFilterValue(state, myZoneFilterValue) {
    state.myZoneFilterValue = myZoneFilterValue;
  },
  setZoneFilters(state, zoneFilters) {
    state.zoneFilters = zoneFilters;
  },
  setTags(state, tags) {
    state.tags = tags;
  },
  setTagFilters(state, tagFilters) {
    state.tagFilters = tagFilters;
  },
  setDeletePage(state, showDeletePage) {
    state.showDeletePage = showDeletePage;
  },
  setIsYearSelected(state, isYearSelected) {
    state.isYearSelected = isYearSelected;
  },
  setCurrBoardYear(state, year) {
    state.currBoard.year = year;
    // Update theme colour and text colur based on design
    state.currBoard.themeColour =
      BOARD_THEME_COLOUR_MAP[state.currBoard.name][year];
    state.currBoard.themeTextColour =
      BOARD_THEME_TEXT_COLOUR_MAP[state.currBoard.name][year];
  },
};

const actions = {
  updateFormHeader({ commit }, formHeader) {
    commit("setFormHeader", formHeader);
  },
  updateSubmitButton({ commit }, submitButton) {
    commit("setSubmitButton", submitButton);
  },
  updateCurrPost({ commit }, currPost) {
    commit("setCurrPost", currPost);
  },
  updateIsBoardToastOpen({ commit }, isBoardToastOpen) {
    commit("setIsBoardToastOpen", isBoardToastOpen);
    if (isBoardToastOpen) {
      setTimeout(() => {
        commit("setIsBoardToastOpen", false);
      }, 3000);
    }
  },
  updateBoardToastMessage({ commit }, message) {
    commit("setBoardToastMessage", message);
  },
  updateFilePreview({ commit }, filePreview) {
    commit("setFilePreview", filePreview);
  },
  async fetchIsCurrBoardAdmin({ commit, rootState }, boardId) {
    const accessToken = rootState.user.token;
    const { data } = await axios.get(
      process.env.VUE_APP_API_URL + `/board/${boardId}/is-admin`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    commit("setIsCurrBoardAdmin", data.isBoardAdmin);
    commit("setIsLoading", false);
  },
  async fetchBoards({ commit, rootState }, queryBoardId, cookieBoard = null) {
    commit("setIsLoading", true);
    const accessToken = rootState.user.token;
    const { data } = await axios.get(process.env.VUE_APP_API_URL + "/board", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    commit("setBoards", data);
    if (queryBoardId) {
      const board = data.find(
        (board) => parseInt(board.id) === parseInt(queryBoardId)
      );
      commit("setCurrBoard", board);
    } else if (cookieBoard) {
      const board = data.find(
        (board) => parseInt(board.id) === parseInt(cookieBoard.id)
      );
      commit("setCurrBoard", board);
      commit("setCurrBoardYear", cookieBoard.year);
      commit("setIsYearSelected", cookieBoard.isYearSelected);
    } else {
      const board = data.find((board) => board.isActive);
      commit("setCurrBoard", board);
    }
  },
  async fetchTags({ commit, state, rootState }) {
    if (!state.isCurrBoardAdmin) return;
    const boardId = state.currBoard?.id;
    if (!boardId) return;
    const accessToken = rootState.user.token;
    const { data } = await axios.get(
      process.env.VUE_APP_API_URL + `/board/${boardId}/posts/all-tags`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    commit("setTags", data);
  },
  async fetchBoardThemes({ commit, rootState }) {
    commit("setIsLoading", true);
    const accessToken = rootState.user.token;
    const { data } = await axios.get(
      process.env.VUE_APP_API_URL + "/cms/board/themes",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    data.forEach((theme) => {
      let yearBoardThemeColour = {};
      let yearBoardThemeTextColour = {};
      let yearBoardHoverColour = {};
      let yearBoardHoverTextColour = {};
      let yearBoardNavBarBackground = {};
      let yearBoardSideBarBackground = {};

      // Update theme colour, text colour and background based on year
      theme.BoardYearConfig.forEach((year) => {
        yearBoardThemeColour[year.Year] = year.BoardThemeColour;
        yearBoardThemeTextColour[year.Year] = year.BoardThemeTextColour;
        yearBoardHoverColour[year.Year] = year.BoardHoverColour;
        yearBoardHoverTextColour[year.Year] = year.BoardHoverTextColour;
        yearBoardNavBarBackground[year.Year] = {
          desktop: year.NavBarDesktopBackground?.url,
          mobile: year.NavBarMobileBackground?.url,
          tablet: year.NavBarTabletBackground?.url,
        };
        yearBoardSideBarBackground[year.Year] = {
          desktop: year.SideBarDesktopBackground?.url,
          mobile: year.SideBarMobileBackground?.url,
          tablet: year.SideBarTabletBackground?.url,
        };
      });

      // Set theme colour, text colour and background for each SP
      BOARD_THEME_COLOUR_MAP[theme.Name] = yearBoardThemeColour;
      BOARD_THEME_TEXT_COLOUR_MAP[theme.Name] = yearBoardThemeTextColour;
      BOARD_HOVER_COLOUR_MAP[theme.Name] = yearBoardHoverColour;
      BOARD_HOVER_TEXT_COLOUR_MAP[theme.Name] = yearBoardHoverTextColour;
      BOARD_NAVBAR_BACKGROUND_MAP[theme.Name] = yearBoardNavBarBackground;
      BOARD_SIDEBAR_BACKGROUND_MAP[theme.Name] = yearBoardSideBarBackground;
      BOARD_SELECT_NAME_MAP[theme.Name] = {
        emoji: theme.emoji,
        name: theme.Name,
        det: theme.det,
      };
    });
  },
  updateShowSideBar({ commit }, showSideBar) {
    commit("setShowSideBar", showSideBar);
  },
  updateShowOptionsModal({ commit }, showOptionsModal) {
    commit("setShowOptionsModal", showOptionsModal);
  },
  updateShowFilterModal({ commit }, showFilterModal) {
    commit("setShowFilterModal", showFilterModal);
  },
  updateCurrBoard({ commit }, currBoard) {
    commit("setCurrBoard", currBoard);
  },
  updateReadFilterValue({ commit }, readFilterValue) {
    commit("setReadFilterValue", readFilterValue);
  },
  updateMyZoneFilterValue({ commit }, myZoneFilterValue) {
    commit("setMyZoneFilterValue", myZoneFilterValue);
  },
  updateZoneFilterValue({ commit }, zoneFilters) {
    commit("setZoneFilters", zoneFilters);
  },
  updateTagFilterValue({ commit }, tagFilters) {
    commit("setTagFilters", tagFilters);
  },
  updateDeletePage({ commit }, showDeletePage) {
    commit("setDeletePage", showDeletePage);
  },
  updateCurrBoardYear({ commit }, year) {
    commit("setCurrBoardYear", year);
  },
  updateIsYearSelected({ commit }, isYearSelected) {
    commit("setIsYearSelected", isYearSelected);
  },
  resetFilters({ commit }) {
    commit("setMyZoneFilterValue", "all");
    commit("setReadFilterValue", "all");
    commit("setZoneFilters", []);
    commit("setTagFilters", []);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
