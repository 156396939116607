<template>
  <span
    class="inline-flex items-center leading-[20px] px-[8px]"
    :class="[largeClasses, roundedClasses]"
  >
    <slot />
    <CloseButton
      v-if="closeable"
      class="cursor-pointer"
      :class="closeSizeClasses"
      @click.native="$emit('closed')"
    />
  </span>
</template>

<script>
import CloseButton from '@/components/icons/CloseButton.vue'

export default {
  components: { CloseButton },

  props: {
    large: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    largeClasses () {
      return this.large ? 'text-[14px] py-[4px]' : 'text-[12px]'
    },
    roundedClasses () {
      return this.rounded ? 'rounded-[24px]' : 'rounded-[8px]'
    },
    closeSizeClasses () {
      return this.large
        ? 'w-[10px] h-[10px] ml-[9px]'
        : 'w-[8px] h-[8px] ml-[8px] mr-[-2px]'
    },
  },
}
</script>
