<template>
  <FilterAccordian title="Status" :selected="label(readFilterStatus)" class="mb-[12px]">
    <div class="flex flex-row mb-[24px]">
      <input
        id="all"
        type="radio"
        name="status"
        value="all"
        :checked="isAllSelected"
        @change="updateToggle"
      />
      <label for="all" class="ml-2 font-normal text-[16px] leading-[20px] sm:text-[20px] sm:leading-[24px]">All</label>
    </div>
    <div class="flex flex-row mb-[24px]">
      <input
        id="read"
        type="radio"
        name="status"
        value="read"
        :checked="isReadSelected"
        @change="updateToggle"
      />
      <label for="read" class="ml-2 font-normal text-[16px] leading-[20px] sm:text-[20px] sm:leading-[24px]">Read</label>
    </div>
    <div class="flex flex-row mb-[24px]">
      <input
        id="unread"
        type="radio"
        name="status"
        value="unread"
        :checked="isUnreadSelected"
        @change="updateToggle"
      />
      <label for="unread" class="ml-2 font-normal text-[16px] leading-[20px] sm:text-[20px] sm:leading-[24px]">Unread</label>
    </div>
  </FilterAccordian>
</template>

<script>
import FilterAccordian from '@/components/Board/Filter/FilterAccordian'

export default {
  components: {
    FilterAccordian,
  },
  props: {
    isUnreadSelected: {
      type: Boolean,
    },
    isReadSelected: {
      type: Boolean,
    },
    isAllSelected: {
      type: Boolean,
    },
    readFilterStatus: {
      type: String,
    },
    updateToggle: {
      type: Function,
    },
  },
  methods: {
    label (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
  },
}
</script>

<style scoped>
input[type='radio'] {
  accent-color: #6245F4;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
/* @media (min-width: 640px) {
    input[type='radio'] {
    width: 24px;
    height: 24px;
  }
} */
</style>
