<template>
  <div>
    <p class="rounded-[8px] bg-[#E5FFF2] px-[8px] text-[#00B259]">{{ tagName }}</p>
  </div>
</template>

<script>
export default {
  props: {
    tagName: {
      type: String,
      default: '',
    },
  },

}
</script>

<style></style>
