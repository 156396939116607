<template>
  <div>
    <a
      v-if="isExternal"
      :href="link"
      target="_blank"
      :data-event-tracking="dataEventTracking"
    >
      <BaseButton
        class="absolute bottom-[20px]"
        solid-classes="bg-[#6245F4] text-white text-xs border-0 hover:bg-[#3B4CEF] sm:text-[16px] sm:leading-[24px]"
        :data-event-tracking="dataEventTracking"
      >
        {{ buttonText }}
      </BaseButton>
    </a>

    <nuxt-link v-else :to="link" :data-event-tracking="dataEventTracking">
      <BaseButton
        class="absolute bottom-[20px]"
        solid-classes="bg-[#6245F4] text-white text-xs border-0 hover:bg-[#3B4CEF] sm:text-[16px] sm:leading-[24px]"
        :data-event-tracking="dataEventTracking"
      >
        {{ buttonText }}
      </BaseButton>
    </nuxt-link>
  </div>
</template>

<script>
import BaseButton from "@/components/design/BaseButton.vue";

export default {
  components: {
    BaseButton,
  },
  props: {
    buttonText: {
      type: String,
      default: "",
    },
    dataEventTracking: {
      type: String,
      default: "",
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: "",
    },
  },
};
</script>
