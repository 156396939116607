<template>
  <master-layout>
    <div
      class="pt-[36px] px-[24px] lg:px-[0] lg:flex lg:flex-1 mt-[48px] min-h-[calc(var(--app-height)-88px)]"
    >
      <div v-if="isLoadingWhitelist" class="w-screen h-screen flex items-center justify-center">
        <Loading />
      </div>
      <ProtectedContent v-else-if="resource" :resource="resource" />
      <PasswordEntry
        v-else
        :is-loading="isLoadingPassword"
        :res-status="resStatus"
        @enter-password="getResourceWithPassword"
      />
    </div>
  </master-layout>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Loading from "@/components/common/LoadingState.vue";
import ProtectedContent from "@/components/ProtectedPage/ProtectedContent.vue";
import PasswordEntry from "@/components/ProtectedPage/PasswordEntry.vue";

export default {
  components: {
    Loading,
    ProtectedContent,
    PasswordEntry,
  },
  data() {
    return {
      isLoadingPassword: false,
      isLoadingWhitelist: true,
      resource: undefined,
      resStatus: 0,
    };
  },
  computed: {
    slug() {
      return this.$route.params.name;
    },
    ...mapState("user", ["token"]),
  },
  async ionViewWillEnter() {
    try {
      const response = await axios.get(
        process.env.VUE_APP_API_URL + `/cms/protected/${this.slug}/whitelist`,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.resource = response.data;
    } catch (e) {
      if (
        e.response &&
        (e.response.status === 403 || e.response.status === 500)
      ) {
        this.$router.push({ path: "/" });
      }
    } finally {
      this.isLoadingWhitelist = false;
    }
  },
  methods: {
    async getResourceWithPassword(password) {
      if (this.isLoadingPassword) return;
      this.isLoadingPassword = true;
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + `/cms/protected`,
          {
            password,
            slug: this.slug,
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.resource = response.data;
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.resStatus = e.response.status;
        }
      } finally {
        this.isLoadingPassword = false;
      }
    },
  },
};
</script>
