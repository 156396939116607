<template>
  <div class="bg-white card-shadow responsive-card" @click="onClick">
    <div class="card-text-position">
      <p class="card-normal-text">Messages in</p>
      <p class="card-bold-text">{{ year }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    year: {
      type: Number,
      required: true,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
<style scoped lang="scss">
.card-shadow {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}

.responsive-card {
  position: relative;
  width: 168px;
  height: 168px;
  border-radius: 1rem;
  // padding: 6rem 1rem 1rem 1rem;

  .card-text-position {
    position: absolute;
    left: 1rem;
    bottom: 1rem;

    .card-normal-text {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    .card-bold-text {
      font-size: 2rem;
      line-height: 2rem;
      font-weight: 800;
    }
  }
}

@media (max-width: 389px) {
  .responsive-card {
    width: 35vw;
    height: 35vw;
    border-radius: 0.5rem;

    .card-text-position {
      position: absolute;
      left: 1rem;
      bottom: 1rem;

      .card-normal-text {
        font-size: 1rem;
        line-height: 1rem;
      }
      .card-bold-text {
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: 800;
      }
    }
  }
}
</style>
