<template>
  <div
    class="flex flex-col items-start p-0 h-16 flex-none order-1 flex-grow-0 w-[279px] gap-[12px]"
  >
    <div
      class="flex flex-row items-center p-0 w-[196px] h-6 flex-none order-none flex-grow-0 gap-3"
    >
      <input
        v-if="isLeadersCalendar"
        id="leader"
        type="radio"
        name="selection"
        value="leaderCalendar"
        checked
        @change="filterChanged('leader')"
      />
      <input
        v-else
        id="leader"
        type="radio"
        name="selection"
        value="leaderCalendar"
        @change="filterChanged('leader')"
      />
      <label v-if="isLeadersCalendar" for="leader" class="selected"> Leaders calendar </label>
      <label v-else for="leader" class="unselected"> Leaders calendar </label>
    </div>
    <hr class="h-0 flex-none order-1 flex-grow-0 w-[279px]" />
    <div class="flex flex-row items-center p-0 w-[196px] h-6 flex-none order-3 flex-grow-0 gap-3">
      <input
        v-if="isLeadersCalendar"
        id="church"
        type="radio"
        name="selection"
        value="churchCalendar"
        @change="filterChanged('church')"
      />
      <input
        v-else
        id="church"
        type="radio"
        name="selection"
        value="churchCalendar"
        checked
        @change="filterChanged('church')"
      />
      <label v-if="isLeadersCalendar" for="church" class="unselected"> Church calendar </label>
      <label v-else for="church" class="selected"> Church calendar </label>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('calendar', ['isLeadersCalendar']),
  },
  methods: {
    filterChanged (category) {
      this.$emit('cal_category', category)
    },
  },
}
</script>

<style scoped>
input[type='radio'] {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: 1px solid;
  border-radius: 100px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.selected {
  width: 160px;
  height: 24px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #6245f4;
  gap: 4px;
  flex: none;
  flex-grow: 0;
}
.unselected {
  width: 160px;
  height: 24px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0b0c0f;
  gap: 4px;
  flex: none;
  flex-grow: 0;
}
#leader {
  accent-color: #6245f4;
}
#church {
  accent-color: #6245f4;
}
hr {
  border: 1px solid #d3d8de;
}
</style>
