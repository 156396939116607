<template>
  <div
    class="flex flex-row cursor-pointer"
    @click="click"
    @mouseover="isMouseOver = true"
    @mouseout="isMouseOver = false"
  >
    <DeleteIcon v-if="isDelete" :fill="iconColour" />
    <EditIcon v-if="isEdit" :fill="iconColour" />
    <PinIcon v-if="isPin" :isPin="isPinned" :fill="iconColour" />
    <HideIcon v-if="isHide" :isHide="isHidden" :fill="iconColour" />
    <p
      class="ml-[4px] text-[16px] leading-[24px] font-semibold"
      :class="[isDelete ? 'text-[#B80A0A]' : 'text-[#373E49]']"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DeleteIcon from "../../../../public/assets/icons/board/DeleteIcon";
import EditIcon from "../../../../public/assets/icons/board/EditIcon";
import HideIcon from "../../../../public/assets/icons/board/HideIcon";
import PinIcon from "../../../../public/assets/icons/board/PinIcon";

export default {
  components: {
    DeleteIcon,
    EditIcon,
    HideIcon,
    PinIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    isDelete: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isHide: {
      type: Boolean,
      default: false,
    },
    isPin: {
      type: Boolean,
      default: false,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    isPinned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMouseOver: false,
    };
  },
  computed: {
    ...mapState("board", ["currBoard"]),
    iconColour() {
      return this.isDelete ? "#B80A0A" : "#373E49";
    },
  },
  methods: {
    click() {
      this.$emit("click", true);
    },
  },
};
</script>
