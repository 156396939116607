<template>
  <svg
    v-if="isMobile"
    width="234"
    height="6"
    viewBox="0 0 234 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.00066 2.8726C60.3398 2.32039 118.729 1.23092 177.04 2.5812C195.377 3.00583 213.772 4.28165 232.169 4.10751"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>

  <svg
    v-else
    width="455"
    height="6"
    viewBox="0 0 455 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.00066 4.3433C116.44 3.26008 230.982 1.63909 345.349 2.45878C381.315 2.71655 417.386 3.82506 453.474 3.48347"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  computed: {
    isMobile () {
      return window.innerWidth < 413
    },
  },
}
</script>
