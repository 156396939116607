export default {
  methods: {
    async toastFieldErrors (e) {
      let msg = e.message
      if (e.response && e.response.data && e.response.data.message) {
        msg = e.response.data.message.length && Array.isArray(e.response.data.message) ? e.response.data.message[0] : e.response.data.message
      }
      this.error = true
      await this.$store.dispatch('toast/error', msg, { root: true })
    },
  },
}
