<template>
  <master-layout id="calendar-top" :enable-scroll-event="true" @on-scroll="handleScroll($event)">
    <div class="flex-1 min-h-screen">
      <div
        v-if="isCmsLoading && isProfileLoading"
        class="w-screen h-screen flex items-center justify-center"
      >
        <Loading />
      </div>
      <div class="pt-[16px]" v-else>
        <FilterBar
          class="filterbar"
          :class="{ 'filterbar--hidden': !showFilterBar }"
        />
        <CalendarSection
          v-if="months.length > 0"
          :filtered-calendar-events="filteredCalendarEvents"
          :months="months"
          :scroll-top="scrollValue"
          class="pt-[50px]"
        />
        <p
          v-if="months.length > 0"
          class="text-[#0B0C0F] text-center pb-[25px] bg-[#F8F9F9]"
        >
          You’re all caught up! Stay tuned for more updates 😁
        </p>
      </div>
      <p
        v-if="months.length === 0"
        class="text-[#acacac] text-center mt-[24px]"
      >
        No dates to show.
      </p>
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import Loading from "@/components/common/LoadingState.vue";
import CalendarSection from "@/components/Calendar/Events/CalendarSection.vue";
import FilterBar from "@/components/Calendar/Menu/FilterBar.vue";

export default {
  components: {
    Loading,
    CalendarSection,
    FilterBar,
  },
  data() {
    return {
      scrollValue: 0,
    };
  },
  computed: {
    ...mapState("calendar", ["filters", "showFilterBar", "isLeadersCalendar"]),
    ...mapState("cms", ["calendarEvents", "calendarNotifications"]),
    ...mapState("profile", {
      isProfileLoading: (state) => state.loading,
      isLeader: (state) => state.isLeader,
    }),
    ...mapState("cms", {
      isCmsLoading: (state) => state.loading,
    }),
    months() {
      return Object.keys(this.filteredCalendarEvents);
    },
    filteredCalendarEvents() {
      let data = this.calendarEvents;
      let filtered = {};
      let items = Object.values(data).flat();

      if (!this.isLeadersCalendar) {
        items = items.filter((item) => !item.isLeadersOnly);
      }

      if (this.filters.length > 0) {
        items.forEach((item) => {
          const startMonthYear = this.getItemStartMonthYear(item);
          if (
            this.filters.some((filterName) =>
              item.tags.some((tag) => tag.name === filterName)
            )
          ) {
            filtered = this.toggleCalendar(filtered, item, startMonthYear);
          }
        });
      } else {
        items.forEach((item) => {
          const startMonthYear = this.getItemStartMonthYear(item);
          filtered = this.toggleCalendar(filtered, item, startMonthYear);
        });
      }
      data = filtered;
      return data;
    },
  },
  async ionViewWillEnter() {
    await Promise.all([
      this.$store.dispatch("cms/fetchCmsContent", {
        entityName: "calendar/events",
        stateName: "calendarEvents",
      }),
      this.$store.dispatch("cms/fetchCmsContent", {
        entityName: "calendar/filters",
        stateName: "calendarEventFilters",
      }),
      this.$store.dispatch("cms/fetchCmsContent", {
        entityName: "calendar/last-updated-date",
        stateName: "calendarLastUpdatedDate",
      }),
      this.$store.dispatch("cms/fetchCmsContent", {
        entityName: "calendar/download-link",
        stateName: "calendarDownloadLink",
      }),
      this.$store.dispatch("cms/fetchCmsContent", {
        entityName: "calendar/notifications",
        stateName: "calendarNotifications",
      }),
      this.$store.dispatch("updates/getUpdates", "all")
    ]);
  },
  methods: {
    handleScroll(ev) {
      this.scrollValue = ev.detail.scrollTop;
    },
    getItemStartMonthYear(item) {
      const startDate = new Date(item.startDate);
      const startMonth = startDate.getUTCMonth() + 1;
      const startYear = startDate.getUTCFullYear();
      const startMonthYear = startYear + "-" + startMonth;
      return startMonthYear;
    },
    getItemEndMonthYear(item) {
      const endDate = new Date(item.endDate);
      const endMonth = endDate.getUTCMonth() + 1;
      const endYear = endDate.getUTCFullYear();
      const endMonthYear = endYear + "-" + endMonth;
      return endMonthYear;
    },
    toggleCalendar(filtered, item, monthYear) {
      if (filtered[monthYear]) {
        if (!filtered[monthYear].includes(item)) {
          filtered[monthYear].push(item);
        }
      } else {
        filtered[monthYear] = [item];
      }
      return filtered;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "proxima-nova";
  font-style: normal;
}
.filterbar {
  transition: 0.3s all ease-out !important;
}
.filterbar.filterbar--hidden {
  transform: translate3d(0, -100%, 0);
}
@media only screen and (max-width: 1024px) {
  p {
    padding-bottom: 95px;
  }
}
</style>
