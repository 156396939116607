<template>
  <master-layout>
    <div v-if="isLoading" class="w-screen h-screen flex items-center justify-center">
        <Loading />
    </div>
    <div
      v-else
      class="pt-[48px] px-[24px] pb-[65px] md:pt-[48px] md:pl-10 md:pb-[pb-[65px] lg:pr-[30px] lg:pl-[56px] lg:flex-1 lg:overflow-hidden"
    >
      <section>
        <h1
          class="text-[32px] leading-[40px] sm:text-[48px] sm:leading-[64px] font-bold mb-[8px] sm:mb-[4px] text-[#0B0C0F]"
        >
          Service replay
        </h1>
        <p class="text-[16px] leading-[24px] text-[#21252C]">
          Missed the last live worship service? Replay videos for the weekend
          will be available from Sunday onwards!
        </p>
      </section>

      <section class="flex flex-col sm:flex-row gap-[56px] mt-[40px]">
        <div
          v-for="(card, i) in overview"
          :key="i"
          class="max-w-full rounded-[16px] shadow-lg sm:max-w-[456px] sm:min-w-[355px]"
        >
          <div class="p-[24px]">
            <p class="mb-[16px] text-[24px] leading-[32px] font-bold">
              {{ card.title }}
            </p>
            <ul>
              <li
                v-for="(link, i) in card.links"
                :key="link.serviceReplayId"
                :class="{ 'mt-[24px] sm:mt-[16px]': i > 0 }"
              >
                <router-link
                  class="video-link font-semibold flex items-center rounded-[12px] pt-[11px] px-[24px] md:px-[16px] pb-[13px]"
                  :to="`/service-replay/${link.serviceReplayId}`"
                >
                  <img
                    src="../../../public/assets/images/service-replay/play.png"
                  />
                  <span
                    class="ml-[12px] md:ml-[16px] text-[16px] leading-[24px] text-[#0B0C0F]"
                    >{{ link.label }}</span
                  >
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import Loading from "@/components/common/LoadingState.vue";

export default {
  components: {
    Loading,
  },
  computed: {
    ...mapState("serviceReplay", {
      isLoading: (state) => state.isLoading,
      overview: (state) => state.overview,
    }),
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("serviceReplay/fetchOverview");
    await this.$store.dispatch("updates/getUpdates", "all");
  },
};
</script>

<style scoped>
.video-link {
  background: linear-gradient(180deg, #f8f9f9 0%, #e6e1ff 146.43%);
}
</style>
