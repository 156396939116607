<template>
  <section
    class="w-full max-w-[327px] sm:max-w-[740px] mx-auto text-center mt-[84px] md:mt-[133px]"
  >
    <h1 class="font-pt-mono text-[24px] md:text-[32px] font-bold mb-[32px] leading-[36px]">
      2024 Father’s Day
    </h1>
    <p class="font-pt-mono text-[16px] leading-[128%] mb-[28px]">
      I have asked some young men and women who have similar backgrounds but are now grown up (some
      are already married), to share their stories with you. They broke out and broke through. I am
      so proud of them.<br />
      As you listen to them, it is a sneak preview of your amazing future. Let hope arise within
      you.
      <br /><br />
      Your best days are ahead of you.<br />
      You will have an amazing future like them.<br />
      You just need to stay close to Jesus and church like them.
    </p>

    <p class="font-pt-mono text-[16px] leading-[128%] font-bold">
      Love and Respect,<br />
      Pastor How
    </p>
  </section>
</template>

<script>
export default {}
</script>

<style scoped lang="scss"></style>
