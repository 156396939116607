<template>
  <div
    class="w-[100vw] md:rounded-lg shadow cursor-pointer relative"
    :class="hiddenClass"
  >
    <div class="p-[24px]">
      <PostInfo
        v-if="isCurrBoardAdmin"
        :is-pinned="isPinned"
        :is-hidden="isHidden"
      />
      <div class="flex flex-row justify-between">
        <div class="max-w-[294px] sm:max-w-[246px]">
          <MemberProfile
            :member="member"
            :is-anon="isAnon"
            :board-id="boardId"
            :year-created-at="yearCreatedAt"
            :tags="tags"
          />
          <MemberProfile
            v-if="isCurrBoardAdmin && isAnon"
            :member="member"
            :is-anon="false"
            :year-created-at="yearCreatedAt"
            :tags="tags"
            class="opacity-20"
          />
          <div
            v-if="!readMore"
            class="cursor-text hidden sm:block"
            @mouseover="isCardHovered = true"
            @mouseout="isCardHovered = false"
            @click="readMoreDesktopOpen"
          >
            <p
              class="text-clip overflow-hidden break-words whitespace-pre-line"
            >
              {{ truncatedMessage }}
            </p>
            <p
              v-if="isMessageTooLong"
              class="mt-[24px] underline cursor-pointer"
              :style="{
                color: isCardHovered ? currBoard.themeColour : '#6245F4',
              }"
            >
              Read More
            </p>
          </div>
          <!--   Possible to check here if device is mobile; if mobile DO NOT execute this readMore expansion   -->
          <div v-else class="mt-[24px] cursor-text hidden sm:block">
            <p
              class="text-clip overflow-hidden break-words whitespace-pre-line"
            >
              {{ message }}
            </p>
          </div>

          <div
            class="cursor-text sm:hidden"
            @mouseover="isCardHovered = true"
            @mouseout="isCardHovered = false"
            @click="readMoreMobileOpen"
          >
            <p
              class="text-clip overflow-hidden break-words whitespace-pre-line"
            >
              {{ truncatedMessage }}
            </p>
            <p
              v-if="isMessageTooLong"
              class="mt-[24px] underline cursor-pointer"
              :style="{
                color: isCardHovered ? currBoard.themeColour : '#6245F4',
              }"
            >
              Read More
            </p>
          </div>
        </div>
        <div>
          <MiniCTASection
            v-if="!isMe"
            :is-favourite="isFavourite"
            :is-read="isRead"
            :favourite="favourite"
            :read="markRead"
            :is-read-active="isReadActive"
            :is-favourite-active="isFavouriteActive"
            class="mb-[16px]"
          />
          <Ellipsis :is-me="isMe" @open-options-modal="openOptionsModal" />
        </div>
      </div>
      <Transition name="slide">
        <div
          v-if="readMore"
          class="w-[100vw] sm:hidden z-50 mt-[44px] overscroll-contain fixed bg-white h-screen top-0 left-0 flex flex-col rounded-t-2xl"
        >
          <div
            class="h-[48px] w-full items-center flex flex-row-reverse px-[24px] py-3"
            @click="readMoreMobileClose"
          >
            <CloseIcon />
          </div>
          <div
            class="h-[calc(100vh-150px)] overflow-y-scroll px-[24px] space-y-[24px] md:pb-[112px] pb-[164px] overscroll-contain"
          >
            <div class="flex flex-row justify-between">
              <MemberProfile
                :member="member"
                :is-anon="isAnon"
                :board-id="boardId"
                :year-created-at="yearCreatedAt"
              />
              <Ellipsis :is-me="isMe" @open-options-modal="openOptionsModal" />
            </div>
            <p class="break-words whitespace-pre-line">{{ message }}</p>
            <PhotoPopup
              v-show="attachmentUrlList.length > 0"
              :images="formatPhoto(attachmentUrlList)"
              :single-thumbnail="true"
            />
          </div>
          <div class="fixed bottom-0 w-full bg-white">
            <PostCTASection
              :is-favourite="isFavourite"
              :is-read="isRead"
              :favourite="favourite"
              :read="markRead"
              :is-read-active="isReadActive"
              :is-favourite-active="isFavouriteActive"
              :is-me="isMe"
            />
          </div>
        </div>
      </Transition>
      <div class="relative">
        <PhotoPopup
          v-show="attachmentUrlList.length > 0"
          :images="formatPhoto(attachmentUrlList)"
          :single-thumbnail="true"
        />
        <img
          v-if="attachmentUrlList.length > 1"
          src="../../../../public/assets/icons/board/multi-attach.svg"
          class="absolute w-[50px] h-[50px] top-8 right-2 pointer-events-none"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import CloseIcon from "../../../../public/assets/icons/board/CloseIcon.vue";
import PostCTASection from "@/components/Board/Card/PostCTASection";
import MiniCTASection from "@/components/Board/Card/MiniCTASection";
import MemberProfile from "@/components/Board/Card/MemberProfile";
import Ellipsis from "@/components/Board/Card/Ellipsis";
import PostInfo from "@/components/Board/Card/PostInfo";
import PhotoPopup from "@/components/Photo/PhotosPopup";

export default {
  components: {
    MemberProfile,
    Ellipsis,
    PostInfo,
    PostCTASection,
    CloseIcon,
    MiniCTASection,
    PhotoPopup,
  },
  props: {
    member: {
      type: Object,
      required: false,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
    attachmentUrlList: {
      type: Array,
      default: () => [],
    },
    isMe: {
      type: Boolean,
      default: false,
    },
    postId: {
      type: Number,
      required: true,
    },
    boardId: {
      type: Number,
      required: true,
    },
    isPinned: {
      type: Boolean,
      default: false,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    isAnon: {
      type: Boolean,
      default: false,
    },
    isFavourite: {
      type: Boolean,
      default: false,
    },
    isRead: {
      type: Boolean,
      default: false,
    },
    yearCreatedAt: {
      type: Number,
      default: 0,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      readMore: false,
      isCardHovered: false,
      isFavouriteHover: false,
      isReadHover: false,
      ellipsisPressed: false,
      isPostFavourited: this.isFavourite,
      isPostRead: this.isRead,
      isFavouriteActive: this.isFavourite,
      isReadActive: this.isRead,
      isSelectingPhotos: false,
    };
  },
  computed: {
    ...mapState("board", [
      "currPost",
      "currBoard",
      "isCurrBoardAdmin",
      "isBoardToastOpen",
    ]),
    ...mapState("user", ["token"]),
    truncatedMessage() {
      if (this.message.length > 200) {
        return this.message.substring(0, 200) + "...";
      } else {
        return this.message;
      }
    },
    isMessageTooLong() {
      return this.message.length > 200;
    },
    hiddenClass() {
      return this.isCurrBoardAdmin && this.isHidden ? "opacity-20" : "";
    },
  },
  methods: {
    async readMoreDesktopOpen() {
      this.readMore = true;
      this.isPostRead = true;
      this.isReadActive = true;
      if (this.isPostRead) {
        await axios.request(
          process.env.VUE_APP_API_URL + `/board/${this.boardId}/posts/read`,
          {
            method: "patch",
            data: { postIds: [this.postId] },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      }
    },
    async readMoreMobileOpen() {
      this.readMore = true;
      this.isPostRead = true;
      this.isReadActive = true;
      if (this.isPostRead) {
        await axios.request(
          process.env.VUE_APP_API_URL + `/board/${this.boardId}/posts/read`,
          {
            method: "patch",
            data: { postIds: [this.postId] },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      }
      document.body.classList.add("overflow-hidden");
    },
    readMoreMobileClose() {
      this.readMore = false;
      document.body.classList.remove("overflow-hidden");
    },
    openOptionsModal() {
      this.$store.dispatch("board/updateCurrPost", {
        attachmentUrl: this.attachmentUrl,
        id: this.postId,
        boardId: this.boardId,
        isMe: this.isMe,
        member: this.member,
        message: this.message,
        isPinned: this.isPinned,
        isHidden: this.isHidden,
        isAnon: this.isAnon,
      });
      this.$emit("open-options", true);
    },
    async favourite() {
      this.isPostFavourited = !this.isPostFavourited;
      this.isFavouriteActive = !this.isFavouriteActive;
      if (this.isPostFavourited) {
        await axios.request(
          process.env.VUE_APP_API_URL +
            `/board/${this.boardId}/posts/${this.postId}/favourite`,
          {
            method: "patch",
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      } else {
        await axios.request(
          process.env.VUE_APP_API_URL +
            `/board/${this.boardId}/posts/${this.postId}/unfavourite`,
          {
            method: "patch",
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      }
    },
    async markRead() {
      this.isPostRead = !this.isPostRead;
      this.isReadActive = !this.isReadActive;
      if (this.isPostRead) {
        await axios.request(
          process.env.VUE_APP_API_URL + `/board/${this.boardId}/posts/read`,
          {
            method: "patch",
            data: { postIds: [this.postId] },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      } else {
        await axios.request(
          process.env.VUE_APP_API_URL + `/board/${this.boardId}/posts/unread`,
          {
            method: "patch",
            data: { postId: this.postId },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
      }
    },
    openDeletePage() {
      this.$store.dispatch("board/updateDeletePage", true);
    },
    openPopup() {
      this.isSelectingPhotos = true;
    },
    formatPhoto(photos) {
      return photos.map((photo, index) => {
        return {
          src: photo,
          thumbnail: index === 0 ? photo : null,
          w: 8,
          h: 8,
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
.slide-enter,
.slide-leave-to {
  transform: translate(0, 100%);
}
.slide-enter-active,
.slide-leave-active {
  transition: 0.8s ease-in-out;
}

::v-deep div[itemscope="itemscope"] img[itemprop="thumbnail"] {
  display: inherit !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 8px;
  margin-top: 24px;
}
</style>
