<template>
  <div id="calendarSection" class="pb-[16px] px-[24px]" style="background: #f8f9f9">
    <div ref="container" class="lg:flex lg:flex-col lg:items-center">
      <div
        v-for="(monthKey, monthIndex) in months"
        :key="monthIndex"
        :ref="formatMonth(monthKey).split(' ').join('').toLowerCase()"
        :data-label="formatMonth(monthKey)"
        class="lg:place-items-center pt-[40px]"
        :class="{ 'last-item': monthIndex === months.length - 1 }"
      >
        <div :id="monthKey" class="">
          <h1 class="font-bold text-2xl">
            {{ formatMonth(monthKey) }}
          </h1>
          <div
            v-for="(event, eventIndex) in filteredCalendarEvents[monthKey]"
            :key="eventIndex"
            :ref="`${monthKey}-${eventIndex}`"
            class="w-full mt-4 lg:mt-[12px] lg:w-[418px]"
          >
            <EventCard
              :title="event.title"
              :description="event.description"
              :event-time="event.eventTime"
              :event-location="event.eventLocation"
              :button-text="event.buttonText"
              :is-external="event.isExternal"
              :link="event.link"
              :data-event-tracking="event.dataEventTracking"
              :start-date="event.startDate"
              :end-date="event.endDate"
              :tags="event.tags"
              :image-src="event.imageSrc"
              :is-called-out="event.isCalledOut"
              :is-leaders-only="event.isLeadersOnly"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import EventCard from "@/components/Calendar/Events/EventCard.vue";

export default {
  components: {
    EventCard,
  },
  props: {
    filteredCalendarEvents: {
      type: Object,
      default: () => {},
    },
    months: {
      type: Array,
      default: () => [],
    },
    scrollTop: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      lastScrollPosition: 0,
      height: 0,
    };
  },
  computed: {
    ...mapState("calendar", [
      "currentMonth",
      "isFirstScroll",
      "isScrollToMonth",
    ]),
  },
  watch: {
    async isScrollToMonth() {
      if (this.isScrollToMonth) {
        const name = this.currentMonth.split(" ").join("").toLowerCase();
        const extraSpace = window.innerWidth < 1024 ? 110 : 130;
        const elem = document.getElementById('calendar-top')
        elem.scrollToPoint(0, this.$refs[name][0]?.offsetTop - extraSpace, 1000)
        await this.$store.dispatch("calendar/updateIsScrollToMonth", false);
      }
    },
    scrollTop(newValue, oldValue) {
      this.onScroll();
      this.scrollSpy();
    },
    filteredCalendarEvents() {
      this.autoScroll();
    },
    async height() {
      await this.$store.dispatch("calendar/updateIsFirstScroll", true);
      await this.$store.dispatch("calendar/updateShowFilterBar", true);
      this.autoScroll();
    },
  },
  mounted() {
    // window.addEventListener("scroll", this.onScroll);
    // window.addEventListener("scroll", this.scrollSpy);
    // this.onScroll();
    // this.scrollSpy();
    this.autoScroll();
  },
  updated() {
    this.height = this.$refs.container.clientHeight;
    if (!this.currentMonth) {
      this.autoScroll();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("scroll", this.scrollSpy);
  },
  methods: {
    formatMonth(monthKey) {
      return dayjs(monthKey).format("MMMM YYYY");
    },
    async autoScroll() {
      console.log('in autoscroll')
      const extraSpace = window.innerWidth < 1024 ? 179 : 199; // TODO: refactor to dynamically get filter bar height
      let name = "";

      for (const month of this.months) {
        const events = this.filteredCalendarEvents[month];

        await this.$nextTick();
        for (let j = 0; j < events.length; j++) {
          const event = events[j];
          const hasPast = dayjs().isAfter(event.endDate, "day");

          if (!hasPast) {
            console.log('in IF statement')
            name = month + "-" + j;
            // window.scrollTo({
            //   top: this.$refs[name][0]?.offsetTop - extraSpace,
            //   behavior: "instant",
            // });
            const elem = document.getElementById('calendar-top')
            elem.scrollToPoint(0, this.$refs[name][0]?.offsetTop - extraSpace, 1000)
            return;
          }
        }
      }
    },
    async scrollSpy() {
      const elements = Array.from(this.$refs.container.children);

      if (this.scrollTop === 0) {
        await this.$store.dispatch(
          "calendar/updateCurrentMonth",
          elements[0].dataset.label
        );
      } else if (
        this.scrollTop + window.innerHeight ===
        document.body.scrollHeight
      ) {
        await this.$store.dispatch(
          "calendar/updateCurrentMonth",
          elements[elements.length - 1].dataset.label
        );
      } else {
        const extraSpace = window.innerWidth < 1024 ? 112 : 132;
        elements.forEach(async (element) => {
          if (this.scrollTop >= element.offsetTop - extraSpace) {
            await this.$store.dispatch(
              "calendar/updateCurrentMonth",
              element.dataset.label
            );
          }
        });
      }
    },
    async onScroll() {
      if (this.isFirstScroll) {
        await this.$store.dispatch("calendar/updateIsFirstScroll", false);
        await this.$store.dispatch("calendar/updateShowFilterBar", true);
      } else {
        const currentScrollPosition =
          this.scrollTop || document.documentElement.scrollTop;
        if (currentScrollPosition < 0) {
          return;
        }
        if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
          return;
        }
        if (currentScrollPosition < this.lastScrollPosition){
          await this.$store.dispatch("calendar/updateShowFilterBar", true);
        } else {
          await this.$store.dispatch("calendar/updateShowCalendarMenu", false);
          await this.$store.dispatch("calendar/updateShowFilterBar", false);
        }
        this.lastScrollPosition = currentScrollPosition;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "proxima-nova";
  font-style: normal;
}

.last-item {
  min-height: calc(100vh - 68px);

  @media (min-width: 1024px) {
    min-height: calc(100vh - 88px);
  }
}
</style>
