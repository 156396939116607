import { directive as onClickaway } from 'vue3-click-away'

export default {
  directives: {
    onClickaway,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showingDropdown: false,
    }
  },

  computed: {
    showingDropdownClasses() {
      return 'border-[#056DFF] bg-white rounded-b-none'
    },
  },

  methods: {
    toggle() {
      // if disabled, always hide the dropdown
      if (this.disabled) {
        this.showingDropdown = false
        return
      }

      this.showingDropdown = !this.showingDropdown
    },

    close() {
      this.showingDropdown = false
    },
  },
}
