<template>
  <div class="flex flex-col items-center">
    <div class="lg:max-w-[1056px] md:max-w-[700px] w-full px-4">
      <p class="font-bold text-[24px] leading-[32px] mb-6 mt-8">Birthday Messages</p>
      <div class="flex flex-row flex-wrap gap-4">
        <div v-for="(year, index) in sortDecendingYear" :key="index">
          <YearCard :year="year" :onClick="() => selectYear(year)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import YearCard from '@/components/Board/YearCard/YearCard.vue'
export default {
  components: {
    YearCard,
  },
  data () {
    return {
      selectedYear: '',
    }
  },
  computed: {
    ...mapState('board', ['currBoard']),
    sortDecendingYear () {
      const sortYears = JSON.parse(JSON.stringify(this.currBoard.yearList))
      sortYears.sort()
      sortYears.reverse()
      return sortYears
    },
  },
  methods: {
    selectYear (year) {
      this.$store.dispatch('board/updateCurrBoardYear', year)
      this.$store.dispatch('board/updateIsYearSelected', true)
      this.$cookies.set(
        'cookieBoard',
        JSON.stringify({ id: this.currBoard.id, year, isYearSelected: true }),
      )
    },
  },
}
</script>
