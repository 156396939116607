<template>
    <VuePictureSwipe ref="pictureSwipe" :items="images" @click="openGallery" />
</template>

<script>
import PhotoSwipeLightbox from '../../../node_modules/photoswipe/dist/photoswipe-lightbox.esm'
import VuePictureSwipe from 'vue3-picture-swipe'

export default {
  name: 'PhotosPopup',
  components: { VuePictureSwipe },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      subheaderText: '',
      isModalTwoVisible: false,
      isEnter: false,
      items: this.images,
      options: {
        index: 0,
        pinchToClose: false,
        closeOnScroll: false,
        closeOnVerticalDrag: false,
        preload: [1, 2],
        closeEl: true,
        captionEl: false,
        fullscreenEl: false,
        zoomEl: true,
        shareEl: false,
        counterEl: true,
        arrowEl: true,
        preloaderEl: true,
        spacing: 0.6,
        allowPanToNext: true,
        clickToCloseNonZoomable: false,
        getDoubleTapZoom: (isMouseClick, item) => {
          if (isMouseClick) {
            return 1.5
          } else {
            return item.initialZoomLevel < 0.7 ? 1 : 1.5
          }
        },
      },
    }
  },
  methods: {
    openGallery () {
      // const pswpElement = document.querySelectorAll('.pswp')[0]
      const gallery = new PhotoSwipeLightbox(
        this.items,
        this.options,

      )
      gallery.init()
    },
  },
}
</script>
