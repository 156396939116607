<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.11935 1.11893C0.96078 1.2775 0.96078 1.5346 1.11935 1.69317L5.42597 5.99979L1.11893 10.3068C0.960357 10.4654 0.960357 10.7225 1.11893 10.8811C1.2775 11.0396 1.5346 11.0396 1.69317 10.8811L6.00021 6.57403L10.3072 10.881C10.4658 11.0396 10.7229 11.0396 10.8815 10.881C11.04 10.7225 11.04 10.4654 10.8815 10.3068L6.57445 5.99979L10.881 1.6932C11.0396 1.53462 11.0396 1.27753 10.881 1.11895C10.7225 0.960382 10.4654 0.960382 10.3068 1.11895L6.00021 5.42555L1.69359 1.11893C1.53502 0.960357 1.27792 0.960357 1.11935 1.11893Z"
      fill="#21252C"
      stroke="black"
    />
  </svg>
</template>
