<template>
  <master-layout>
    <div
      class="mx-[24px] md:mx-[32px] lg:ml-[56px] pt-8 mb-[20px] md:mt-[40px]"
      :class="{ 'pt-[60px]': isIos() }"
    >
      <router-link to="/updates"
        ><img src="../../../public/assets/icons/go-back-chevron.svg"
      /></router-link>
    </div>

    <section
      class="mx-[24px] md:mx-[32px] lg:ml-[56px] font-aktiv-grotesk font-bold"
    >
      <h4 class="text-[24px] leading-[32px] mb-[4px] text-black md:mb-[32px]">
        {{ update.title }}
      </h4>
    </section>
    <div class="mx-6 md:mx-[32px] lg:mx-[56px]">
      <div
        v-if="update.postedTime != ''"
        class="flex justify-end font-aktiv-grotesk"
      >
        <p class="tracking-wide text-xs text-grey-100">
          {{ formatPostedTime(update.postedTime) }}
        </p>
      </div>
      <div>
        <div class="mb-3 font-aktiv-grotest-regular">
          <p
            class="tracking-wide text-[16px] leading-[24px] pr-3 text-grey-100 font-aktiv-grotesk"
          >
            <html-to-vue :html="update.descriptionFull" />
          </p>
        </div>
      </div>
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import { isIosNativeOnly } from "@/utils/platform-check";
import moment from "moment";
import momentts from "moment-timezone";

export default {
  name: "UpdatesDetailsPage",
  data() {
    return {
      update: null,
      months: [
        ["Jan", "January"],
        ["Feb", "February"],
        ["Mar", "March"],
        ["Apr", "April"],
        ["May", "May"],
        ["Jun", "June"],
        ["Jul", "July"],
        ["Aug", "August"],
        ["Sep", "September"],
        ["Oct", "October"],
        ["Nov", "November"],
        ["Dec", "December"],
      ],
    };
  },
  computed: {
    ...mapState("updates", ["updates"]),
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("updates/getUpdates", "all");
    await this.$store.dispatch("updates/markReadUpdate", this.$route.params.id);
    for (let i = 0; i < this.updates.length; i++) {
      console.log("this.updates[i].id", this.updates[i].id);
      if (this.updates[i].id == this.$route.params.id) {
        this.update = this.updates[i];
      }
    }
    console.log("this.update", this.update);
  },
  methods: {
    isIos() {
      return isIosNativeOnly();
    },
    formatPostedTime(time) {
      momentts.tz.setDefault("Asia/Singapore");
      // return moment(time).calendar()
      const currentDate = momentts();
      // const currentDate = '2022-09-03T16:00:00.000Z'
      const modifiedDateArray = momentts(String(time))
        .format("DD MM hh:mm a")
        .split(" ");
      let finalDateString = null;
      if (
        currentDate.startOf("day").toString() ===
        momentts(time).startOf("day").toString()
      ) {
        finalDateString =
          "TODAY, " + modifiedDateArray[2] + modifiedDateArray[3].toUpperCase();
      } else {
        const monthsIndex = modifiedDateArray[1] - 1;
        modifiedDateArray[1] = this.months[monthsIndex][0].toUpperCase();
        finalDateString =
          modifiedDateArray[0] +
          " " +
          modifiedDateArray[1].toUpperCase() +
          ", " +
          modifiedDateArray[2] +
          modifiedDateArray[3].toUpperCase();
      }
      return finalDateString;
    },
  },
};
</script>
