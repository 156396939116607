<template>
  <div
    class="cursor-pointer relative flex items-center py-[8px] px-[16px] rounded-full border-[1px] border-solid border-[#6245F4] hover:bg-[#F0F2F4]"
    :style="{ backgroundColor: selectedFilters ? '#6245F4' : '' }"
    data-event-tracking="zone-filter"
    @click="openFilterModal"
  >
    <FilterIcon
      class="h-[24px] w-[24px] mr-[4px]"
      :fill="selectedFilters ? '#D3D8DE' : '#6245F4'"
    />
    <p
      class="text-[16px] leading-[20px]"
      :style="{ color: selectedFilters ? '#D3D8DE' : '#6245F4' }"
    >
      Filter
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterIcon from "../../../../public/assets/icons/board/FilterIcon";
export default {
  components: {
    FilterIcon,
  },
  computed: {
    ...mapState("board", ["zoneFilters", "tagFilters", "readFilterValue"]),
    selectedFilters() {
      return (
        this.zoneFilters.length > 0 ||
        this.tagFilters.length > 0 ||
        this.readFilterValue !== "all"
      );
    },
  },
  methods: {
    openFilterModal() {
      this.$store.dispatch("board/updateShowFilterModal", true);
    },
  },
};
</script>
