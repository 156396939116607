<template>
  <FDLayout>
  <div
    class="flex flex-col w-full overscroll-y-none overflow-y-scroll overflow-x-hidden justify-start items-center text-center"
  >
    <div class="sm:max-w-[740px] blob-padding overscroll-y-contain pt-[81px] sm:pt-[131px]">
      <h1 class="font-pt-mono text-[20px] leading-[26px] font-bold">
        Father’s day letters <br class="sm:hidden" />(2013 - 2017)
      </h1>
      <p class="font-pt-mono text-[16px] leading-[21px] mt-[32px]">
        For the past 10 years, I have been writing to youths as a father would to his children. Some
        who first received my letters when they were 15 years old, today they are 25. My greatest
        fulfilment is to see them all grown up, mature, loving and serving Jesus. And also to
        witness God’s promises come true for their lives. So I can now say to another generation -
        to you – these same words, and this time with the certainty of the rising sun, that
      </p>
      <div class="mt-[28px] mb-[40px] sm:my-[37px] relative">
        <p class="font-pt-mono text-[20px] leading-[40px] font-bold">
          “From you and your generation onwards, it will be different.”
        </p>
        <img src="../../../public/assets/icons/hogcfd/circle-drawn.svg" class="absolute circle-drawn" />
        <img src="../../../public/assets/icons/hogcfd/underline-1.svg" class="absolute underline-drawn-1" />
        <img src="../../../public/assets/icons/hogcfd/underline-2.svg" class="absolute underline-drawn-2" />
      </div>
      <p class="font-pt-mono text-[16px] leading-[21px]">
        I hear that many of them re-read these letters especially during their difficult days. So
        this year, I have decided to re-release the letters from the first 5 years (2013-2017) to
        another generation. I pray that you will be encouraged.
      </p>
      <p class="font-pt-mono text-[16px] leading-[21px] font-bold mt-[28px]">
        Love and Respect,<br />Pastor How
      </p>
      <div class="mt-[48px] md:mt-[40px] flex flex-wrap justify-center md:justify-between">
        <div
          v-for="(subpage, index) in currResource.content"
          :key="index"
          class="mb-[24px] sm:mb-[32px]"
        >
          <router-link :to="`/${subpage.slug}`">
            <img :src="subpage.thumbnail.url" class="w-[340px] h-[243px]" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="mt-[96px]" />
  </div>
</FDLayout>
</template>

<script>
import { mapState } from 'vuex'
import FDLayout from '@/components/FDLayout.vue'

export default {
  components: {
    FDLayout,
  },
  computed: {
    ...mapState('protected', ['currResource']),
  },
}
</script>
<style scoped>
.blob-padding {
  padding-left: 24px;
  padding-right: 24px;
}

.circle-drawn {
  top: 81px;
  right: 15%;
}
.underline-drawn-1 {
  bottom: 42px;
  left: 6%;
  width: 75%;
}
.underline-drawn-2 {
  bottom: 82px;
  left: 38%;
}

@media (min-width: 372px) {
  .circle-drawn {
    right: 25%;
  }
  .underline-drawn-1 {
    bottom: 42px;
    left: -4px;
    width: 75%;
  }
  .underline-drawn-2 {
    bottom: 82px;
    left: 40%;
  }
}
@media (min-width: 396px) {
  .circle-drawn {
    right: 32%;
  }
  .underline-drawn-1 {
    bottom: 80px;
    left: 20%;
    width: 80%;
  }
  .underline-drawn-2 {
    bottom: 44px;
    left: 16%;
    width: 30%;
  }
}
@media (min-width: 420px) {
  .circle-drawn {
    top: 39px;
    right: 1%;
  }
  .underline-drawn-1 {
    bottom: 40px;
    left: 23%;
    width: 297px;
  }
  .underline-drawn-2 {
    display: none;
  }
}
@media (min-width: 450px) {
  .circle-drawn {
    right: 5%;
  }
}
@media (min-width: 480px) {
  .circle-drawn {
    right: 8%;
  }
}
@media (min-width: 504px) {
  .circle-drawn {
    right: 22%;
  }
  .underline-drawn-1 {
    left: 15%;
  }
}
@media (min-width: 540px) {
  .circle-drawn {
    right: 28%;
  }
}
@media (min-width: 600px) {
  .circle-drawn {
    right: 35%;
  }
  .underline-drawn-1 {
    left: 12%;
  }
}
@media (min-width: 636px) {
  .circle-drawn {
    right: 41%;
  }
}
@media (min-width: 700px) {
  .underline-drawn-1 {
    left: 18%;
  }
}
@media (min-width: 780px) {
  .blob-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .circle-drawn {
    top: 0px;
    right: 4px;
  }
  .underline-drawn-1 {
    bottom: 0px;
    left: 8%;
    width: 297px;
  }
}
</style>
