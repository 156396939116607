<template>
  <master-layout>
    <section
      class="theme-impression-bg relative bg-[#ffd73e] lg:bg-transparent min-h-[calc(var(--app-height)-(68px+63px))] lg:min-h-[calc(var(--app-height)-88px)] flex justify-center lg:flex-1"
    >
      <img
        src="../../../public/assets/icons/chevron-left-white.svg"
        class="absolute top-[24px] left-6 lg:hidden cursor-pointer theme-back-button"
        alt="Back icon"
        @click="back"
      />

      <div
        class="theme-impression pt-0 mb-[40px] lg:pt-0 rounded-[24px] h-[573px] w-[327px] sm:h-[547px] sm:w-[407px] sm:px-0 lg:bg-[#FFD629] lg:mt-36"
      >
        <div class="my-12 mx-8 m sm:m-16">
          <h1 class="text-[20px] leading-[24px] font-bold text-center">
            We'd love to hear from you!
          </h1>

          <BaseSelect
            v-model="query.cg"
            class="mt-[16px] w-full"
            :options="options"
            value-field="name"
            label-field="name"
            placeholder="Select a CG"
            width-classes="border-0 w-full"
            text-classes="w-full text-center"
          />

          <div class="p-[24px] mt-[16px] text-center bg-[#ffffff] rounded-lg">
            <h4 class="text-[16px] leading-[20px]">Impression Slip</h4>

            <div
              class="inline-block border-[3px] border-[#6245F4] rounded-[16px] my-[16px] p-[16px]"
            >
              <qrcode-vue :value="formLink" :size="qrsize()" level="H" />
            </div>

            <!-- <div>
          <BaseButton
            class="h-[40px] text-[16px] leading-[24px] py-[10px]"
            solid-classes="bg-[#6245F4] text-white"
          >
            Share
          </BaseButton>
        </div> -->
          </div>
        </div>
      </div>
    </section>
  </master-layout>
</template>

<script>
import BaseSelect from "@/components/design/BaseSelect.vue";
import QrcodeVue from "qrcode.vue";
import { mapGetters } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  components: {
    BaseSelect,
    QrcodeVue,
    // BaseButton,
  },

  data() {
    return {
      query: {
        access: "inperson",
        cg: this.$store.getters["profile/cgName"],
      },
    };
  },

  computed: {
    ...mapGetters("profile", ["cgName"]),
    options() {
      const items = Object.entries(this.$store.state.lookups.cgMapping);

      return items.map((item) => ({
        id: item[1],
        name: item[0],
      }));
    },
    formLink() {
      const query = {
        ...this.query,
        zone: this.$store.state.lookups.zoneCgMapping[this.query.cg],
        username: this.user.nickname,
      };
      const queryString = new URLSearchParams(query).toString();

      return `https://myfirstvisit.paperform.co/?${queryString}`;
    },
  },

  watch: {
    cgName(newName) {
      this.query.cg = newName;
    },
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("updates/getUpdates", "all");
  },
  setup() {
    const { user } = useAuth0();
    return {
      user,
    };
  },
  methods: {
    back() {
      this.$router.push("/");
    },

    qrsize() {
      if (window.innerWidth <= 640) {
        return 151;
      } else {
        return 180;
      }
    },
  },
};
</script>
