<template>
  <FDLayout>
  <div
    class="flex flex-col w-full overscroll-y-none overflow-y-scroll overflow-x-hidden justify-start"
  >
    <div class="h-[100vh] w-[100vw] overscroll-y-none">
      <div class="pt-[123px] px-[24px] sm:px-0 sm:max-w-[678px] mx-auto">
        <FDTitle year="2014" />
        <p
          class="font-source-sans-3 text-[19px] sm:text-[20px] leading-[28px] sm:leading-[30px] mt-[41px] sm:mt-[56px]"
        >
          On this Father's Day, I pause to thank God for you.<br />
          I am proud of who you are and whom you are becoming.<br />
          My desire for you is that you live a great life. I pray that you have an abundant life on
          earth and rewarding eternal life in heaven.<br /><br />
          If I were writing to Rinnah or my son, this is what I would write.<br /><br />
          <b>1. Be hardworking.</b><br />
          Tell yourself:
          <b>“Today I do what others won’t so that tomorrow I do what others can’t.”</b><br />
          Remember, success happens when opportunity meets preparation and abilities. Be ready!<br /><br />
          <b>2. Be grateful.</b><br />
          Never forget where you came from.<br />
          Never forget how far God has <br class="sm:hidden" />brought you.<br />
          Never forget how God rescued you.<br />
          Never forget the family in church who loved you.<br />
          Never forget the people who believed in you when you were a nobody.<br />
          Out of 10 lepers healed by Jesus, only 1 came back to worship Him.<br />
          Be the ONE.<br /><br />
          <b>3. Live for the cause of Christ.</b><br />
          William Carey, the great missionary to India, said -
          <b
            >"I am not afraid of failure. I am only afraid of succeeding at things that don't
            matter."</b
          ><br />
          Live for things that matter in eternity.<br />
          You are never a failure when you succeed in Christ.<br /><br />
          <b>From your generation onwards, it <br class="sm:hidden" />will be different.</b><br />
          I am eagerly waiting to celebrate your success and hear your wow stories.<br /><br />
          Happy Father's day.
        </p>
        <FDSignOff class="mt-[48px]" year="2014" />
      </div>
      <FDFooter
        back-text="2013 letter"
        forward-text="2015 letter"
        back-slug="/hogcfd/2013"
        forward-slug="/hogcfd/2015"
        class="mt-[48px]"
      />
      <div class="pt-[96px] mt-[96px] lg:mt-0" />
    </div>
  </div>
</FDLayout>
</template>

<script>
import FDTitle from '@/components/hogcfd/FDTitle'
import FDSignOff from '@/components/hogcfd/FDSignOff'
import FDFooter from '@/components/hogcfd/FDFooter'
import FDLayout from '@/components/FDLayout.vue'

export default {
  components: {
    FDTitle,
    FDSignOff,
    FDFooter,
    FDLayout,
  },
}
</script>
