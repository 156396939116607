<template>
  <nav
    class="z-10 flex bg-[#ffffff] border-t border-t-[#cfcfcf] lg:hidden justify-evenly mt-auto sticky bottom-0 nav-shadow"
  >
    <BottomNavButton
      v-for="(button, i) in filteredButtons"
      :key="i"
      :class="navButtonWidth"
      :label="button.label"
      :to="button.to"
      :icon="button.icon"
      :is-external="button.isExternal"
      :data-event-tracking="button.dataEventTracking"
      @click="openProfileModal(button.label)"
    />
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BottomNavButton from "./BottomNavButton.vue";
import HomeIcon from "@/components/icons/Home";
import HappeningsIcon from "@/components/icons/Happenings";
import ResourcesIcon from "@/components/icons/Resources";
import GivingsIcon from "@/components/icons/Givings";
import LibraryIcon from "@/components/icons/Library";
import ProfileIcon from "@/components/icons/Profile";

export default {
  components: { BottomNavButton },
  data() {
    return {
      buttons: [
        {
          to: "/home",
          icon: HomeIcon,
          label: "Home",
          dataEventTracking: "home-bottom",
        },
        {
          to: "/calendar",
          icon: LibraryIcon,
          label: "Calendar",
          dataEventTracking: "calendar-bottom",
        },
        {
          to: "/happenings",
          icon: HappeningsIcon,
          label: "Happenings",
          dataEventTracking: "happenings-bottom",
        },
        {
          to: this.$route.path,
          icon: ProfileIcon,
          label: "Profile",
          dataEventTracking: "profile-bottom",
        },
        {
          to: "https://mygiving.heartofgodchurch.org",
          icon: GivingsIcon,
          label: "MyGiving",
          minAge: 17,
          minPastoralStatus: 4,
          isExternal: true,
          dataEventTracking: "mygiving-bottom",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      age: "profile/age",
    }),
    ...mapGetters("profile", ["pastoralRoleId"]),
    ...mapState("lookups", ["pastoralMapping"]),
    filteredButtons() {
      return this.buttons
        .filter((b) => !b.minAge || this.age > b.minAge)
        .filter(
          (b) =>
            !b.minPastoralStatus ||
            this.pastoralMapping[this.pastoralRoleId] >= b.minPastoralStatus
        );
    },
    navButtonWidth() {
      if (this.filteredButtons.length === 4) return "w-1/4";
      if (this.filteredButtons.length === 5) return "w-1/5";
      return "w-1/" + this.filteredButtons.length;
    },
  },
  methods: {
    async openProfileModal(buttonLabel) {
      if (buttonLabel === "Profile") {
        await this.$store.commit("profile/setShowingProfileModal", true);
      }
    },
  },
};
</script>
<style scoped>
.nav-shadow {
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
}
</style>
