<template>
    <div
      class="
        z-[99]
        w-[100vw]
        h-[calc(--app-height)]
        fixed
        inset-0
        bg-black
        bg-opacity-50
        flex
        flex-col
        items-center
        justify-end
        md:justify-center
      "
    >
      <slot></slot>
    </div>
</template>

<script>
export default {
  mounted () {
    this.lockBackgroundScroll()
  },
  destroyed () {
    this.unlockBackgroundScroll()
  },
  methods: {
    lockBackgroundScroll () {
      document.body.classList.add('overflow-hidden')
    },
    unlockBackgroundScroll () {
      document.body.classList.remove('overflow-hidden')
    },
  },
}
</script>
