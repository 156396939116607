<template>
  <div class="relative bg-transparent flex justify-center lg:flex-1">
    <div v-if="loading" class="mt-[114px] lg:mt-[144px]">Loading...</div>
    <div v-else-if="personalId" class="flex flex-col mt-[30px] lg:mt-[144px]">
      <p class="font-bold mx-auto text-[32px] leading-[40px] font-[700] text-[#0B0C0F] text-center mb-[8px]">
        PhotoDrop
      </p>
      <p class="max-width-[327px] mx-[24px] text-[16px] mb-[32px] leading-[24px] font-[400] text-[#21252C] text-center max-w-[300px] lg:mb-[56px] lg:text-[20px] lg:font-[300] lg:max-w-[400px]">
        Show this QR code to a photographer wearing a white PhotoDrop shirt! <br /><br /> Come back later to view your photos! 🙂
      </p>
      <div
        class="qr-container w-[287px] h-[287px] drop-shadow-photoqr bg-[#FFFFFF] rounded-[80px] flex items-center justify-center mx-auto"
      >
        <div
        class="bg-[#ffffff] rounded-[60px] h-[247px] w-[247px] flex items-center justify-center"
      >
        <qrcode-vue :value="personalId" :size="190" level="H" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="m-4 w-[335px] h-[288px] lg:m-8 lg:w-[400px] lg:h-[248px] mt-[114px] lg:mt-[144px]"
    >
      <h1 class="text-[24px] leading-8 not-italic font-bold text-[##0B0C0F]">
        Oops, you’re missing something!
      </h1>
      <p class="mt-4 text-[20px] leading-6 not-italic font-normal text-[##0B0C0F]">
        {{ errorMsg }}
      </p>
      <BaseButton
        v-if="!dob || !uin"
        full-width
        style="line-height: 24px"
        class="mt-10"
        solid-classes="bg-[#6245F4] text-white"
        @click="editProfile"
      >
        Edit Profile
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import BaseButton from '@/components/design/BaseButton.vue'

export default {
  name: 'PhotosQrCode',
  components: {
    QrcodeVue,
    BaseButton,
  },
  computed: {
    ...mapState('profile', ['dob', 'uin', 'personalId', 'loading']),
    errorMsg () {
      let details = ''
      if (!this.dob && !this.uin) {
        details = 'last 4 characters of your NRIC/FIN and Date of Birth'
      } else if (!this.dob) {
        details = 'Date of Birth'
      } else if (!this.uin) {
        details = 'last 4 characters of your NRIC/FIN'
      } else if (!this.personalId) {
        return 'There a hiccup in generating your Personal ID. Fret not, our friendly IT team will be in touch with you over email / SMS.'
      }
      return `Update your ${details} under ‘Edit Profile’ to view your unique QR code here!`
    },
  },
  methods: {
    editProfile () {
      this.$router.push('/profile')
    },
  },
}
</script>

<style>
.qr-container {
  background-image: linear-gradient(to bottom right, #E04162 12%, #A63DD4, #2028F5, #8232F5);
}
</style>
