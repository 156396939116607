<template>
  <div v-click-away="closeDropdown" class="relative">
    <div
      class="w-[115px] cursor-pointer border-[1px] rounded-[8px] px-[10px] py-[6px] flex items-center justify-between"
      :class="{ 'rounded-b-[0px]': showDropdown }"
      :style="{ 'border-color': currBoard.themeTextColour }"
      data-event-tracking="pastor-filter"
      @click="toggleDropdown"
    >
      <div class="flex items-center">
        <div class="mr-[4px]">{{ currBoard.selectName.emoji }}</div>
        <h1
          class="text-[20px] leading-[24px] font-light"
          :style="{ color: currBoard.themeTextColour }"
        >
          {{ currBoard.name }}
        </h1>
      </div>
      <ChevronUp
        class="rotate-180"
        :class="{ 'rotate-0': showDropdown }"
        :fill="currBoard.themeTextColour"
      />
    </div>
    <ul
      v-if="showDropdown"
      class="absolute top-full left-0 bg-[#ffffff] flex flex-col w-[115px] text-[20px] leading-[24px] border-[1px] border-t-0 rounded-b-[8px] drop-shadow"
    >
      <li
        v-for="board in boards"
        :key="board.boardId"
        class="px-[8px] py-[12px] cursor-pointer last:rounded-b-[8px]"
        :class="[hoverStyle, selectedStyle(board.name)]"
        :data-event-tracking="board.selectName.det"
        @click="updateCurrBoard(board)"
      >
        {{ board.selectName.emoji }} {{ board.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChevronUp from "../../../../public/assets/icons/board/ChevronUp";

export default {
  components: {
    ChevronUp,
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    ...mapState("board", ["boards", "currBoard"]),
    hoverStyle() {
      const hoverColour = "hover:bg-[" + this.currBoard.hoverColour + "]";
      const hoverTextColour =
        "hover:text-[" + this.currBoard.hoverTextColour + "]";
      return [hoverColour, hoverTextColour];
    },
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
    async updateCurrBoard(selectedBoard) {
      this.$cookies.set(
        "cookieBoard",
        JSON.stringify({
          id: selectedBoard.id,
          year: selectedBoard.year,
          isYearSelected: false,
        })
      );
      this.toggleDropdown();
      this.$store.dispatch("board/updateCurrBoard", selectedBoard);
      this.$store.dispatch("board/resetFilters");
      await this.$store.dispatch(
        "board/fetchIsCurrBoardAdmin",
        selectedBoard.id
      );
    },
    selectedStyle(name) {
      if (name === this.currBoard.name) {
        const themeColour = "bg-[" + this.currBoard.themeColour + "]";
        const themeTextColour = "text-[" + this.currBoard.themeTextColour + "]";
        return [themeColour, themeTextColour];
      }
    },
  },
};
</script>
