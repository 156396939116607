<template>
  <div
    class="bg-[#F0F2F4] h-[calc(var(--app-height)-200px)] w-[100vw] px-[24px] pt-[98px] flex flex-col items-center"
  >
    <img
      :src="require(`../../../public/assets/icons/board/${image}.svg`)"
      class="w-[88px]"
    />
    <p class="leading-[24px] font-bold text-center mt-[16px] max-w-[342px]">
      {{ title }}
    </p>
    <p class="leading-[24px] text-center mt-[8px] max-w-[342px]">
      {{ body }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
      default: "mailbox",
    },
    title: {
      type: String,
      required: true,
      default: null,
    },
    body: {
      type: String,
      required: true,
      default: null,
    },
  },
};
</script>

<style scoped></style>
