<template>
  <div
    class="popup-background fixed left-0 lg:left-[316px] top-0 right-0 bottom-0 flex z-50 lg:justify-center"
  >
    <div class="flex flex-col">
      <div
        class="bg-[#ffffff] w-[194px] px-[8px] h-[27px] rounded-[8px] relative top-[78px] lg:top-[96px] left-[16px] lg:left-[-115px] flex justify-center items-center"
      >
        <button class="h-5 font-bold text-[16px] leading-[20px] text-[#6245F4] font-proxima">
          <div>Leaders calendar</div>
        </button>
      </div>
      <div
        class="popup px-[12px] pt-[8px] w-[205px] h-[128px] rounded-[8px] relative top-[95px] lg:top-[115px] left-[16px] lg:left-[-115px]"
      >
        <p class="font-normal text-[16px] leading-[20px] text-[#0B0C0F]">
          <i>New!</i> Switch between the Church Calendar and Leaders’ Calendar here!
        </p>
        <div class="absolute bottom-0 right-0 pr-[12px] pb-[8px]">
          <BaseButton
            solid-classes="mt-[12px] flex-1 h-10 bg-[#6245F4] justify-center items-center"
            @click="$emit('open-toggle-calendar-popup')"
          >
            <p class="font-semibold text-base text-[#F8F9F9]">Next</p>
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/design/BaseButton.vue'

export default {
  components: {
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>
.popup-background {
  background-color: rgba(0, 0, 0, 0.6);
}

.popup {
  background: #ffffff;
}

.popup:before {
  content: '';
  position: absolute;
  top: -12px;
  left: 52px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 16px solid #ffffff;
  z-index: 1;
}
</style>
