<template>
  <InputField v-bind="{ labelClasses, helperClasses, errorMessageClasses, label, helper, error, id }">
    <div
      class="
        relative
        flex
        items-center
        h-[40px]
        border
        rounded-[8px]
        px-[12px]
        text-[16px]
        leading-[24px]
        text-[#373E49]
        cursor-pointer
        hover:border-lgray hover:ring-0 hover:outline-none
      "
      :class="[
        widthClasses,
        computedDisabledClasses,
        {
          [showingDropdownClasses]: showingDropdown,
        },
      ]"
      @click.self="toggle"
      v-click-away="close"
    >
      <span class="max-w-[90%]" :class="textClasses" @click.self="toggle">
        {{ selectedText }}
      </span>
      <CaretIcon
        class="ml-auto h-[6px]"
        :class="{
          'rotate-180': showingDropdown,
          'opacity-60': disabled,
        }"
        @click.self="toggle"
      />
      <OptionsDropdown
        v-show="showingDropdown"
        v-bind="$props"
        v-model="localValue"
        width-classes="w-[calc(100%+2px)] left-[-1px]"
        @close="close"
      />
    </div>
  </InputField>
</template>

<script>
import CaretIcon from '@/components/icons/Caret.vue'
import OptionsDropdown from '@/components/design/OptionsDropdown'
import InputField from '@/mixins/InputField'
import HasOptions from '@/mixins/HasOptions'
import ShowsDropdown from '@/mixins/ShowsDropdown'
import HasInputClassProps from '@/mixins/HasInputClassProps'

export default {
  components: {
    CaretIcon,
    OptionsDropdown,
  },

  mixins: [HasOptions, ShowsDropdown, InputField, HasInputClassProps],

  props: {
    disabledClasses: {
      type: String,
      default: 'border-[#D3D8DE] bg-[#F0F2F4]',
    },
    textClasses: {
      type: String,
      default: 'truncate',
    },
    widthClasses: {
      type: String,
      default: 'min-w-[263px]',
    },
  },

  computed: {
    computedDisabledClasses() {
      return this.disabled
        ? this.disabledClasses
        : 'border-[#637083] bg-[#F8F9F9]'
    },
  },
}
</script>

<style lang="postcss" scoped>
.dropdown {
  scrollbar-gutter: stable;
}

.dropdown::-webkit-scrollbar {
  width: 10px;
}

.dropdown::-webkit-scrollbar-track {
  background-color: transparent;
}

.dropdown::-webkit-scrollbar-thumb {
  background-color: #d3d8de;
  border-radius: 8px;
  border: 3px solid white;
}
</style>
