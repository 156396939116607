import { createRouter, createWebHistory } from "@ionic/vue-router";
import { isNativeNotWeb } from "@/utils/platform-check";
import { createAuthGuard } from "@auth0/auth0-vue";
import { auth0 } from "@/utils/auth0";

import Login from "../pages/login/index.vue";
import Home from "../pages/home/index.vue";
import Happenings from "../pages/happenings/index.vue";
import Resources from "../pages/resources/index.vue";
import Calendar from "../pages/calendar/index.vue";
import Photodrop from "../pages/photodrop/index.vue";
import ChangePassword from "../pages/change-password/index.vue";
import Impression from "../pages/impression/index.vue";
import Profile from "../pages/profile/index.vue";
import MyQR from "../pages/my-qr/index.vue";
import ServiceReplay from "../pages/service-replay/index.vue";
import ServiceReplayDetail from "../pages/service-replay/_id.vue";
import BirthdayBoard from "../pages/birthday-board/index.vue";
import BirthdayBoardMessage from "../pages/birthday-board/message.vue";
import BirthdayBoardFavouriteMessages from "../pages/birthday-board/favourite-messages.vue";
import BirthdayBoardHiddenMessages from "../pages/birthday-board/hidden-messages.vue";
import BirthdayBoardMyMessages from "../pages/birthday-board/my-messages.vue";
import BirthdayBoardPinnedMessages from "../pages/birthday-board/pinned-messages.vue";
import ProtectedPage from "../pages/protected/_name.vue";
import EInvitesPage from "../pages/e-invites/index.vue";
import Library from "../pages/library/index.vue";
import Milestones from "../pages/milestones/index.vue";
import Updates from "../pages/updates/index.vue";
import UpdateDetails from "../pages/updates/_id.vue";
import MD2023 from "../pages/hogcmd/md2023.vue";
import MD20241 from "../pages/hogcmd/md2024-1.vue";
import MD20242 from "../pages/hogcmd/md2024-2.vue";
import MD20243 from "../pages/hogcmd/md2024-3.vue";
import MD20244 from "../pages/hogcmd/md2024-4.vue";
import MD20245 from "../pages/hogcmd/md2024-5.vue";
import FathersDay from "../pages/hogcfd/index.vue";
import FD2013 from "../pages/hogcfd/2013.vue";
import FD2014 from "../pages/hogcfd/2014.vue";
import FD2015 from "../pages/hogcfd/2015.vue";
import FD2016 from "../pages/hogcfd/2016.vue";
import FD2017 from "../pages/hogcfd/2017.vue";
import FD2024 from "../pages/hogcfd/2024.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/happenings",
    component: Happenings,
  },
  {
    path: "/resources",
    component: Resources,
  },
  {
    path: "/calendar",
    component: Calendar,
  },
  {
    path: "/photodrop",
    component: Photodrop,
  },
  {
    path: "/change-password",
    component: ChangePassword,
  },
  {
    path: "/impression",
    component: Impression,
  },
  {
    path: "/my-qr",
    component: MyQR,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/service-replay",
    component: ServiceReplay,
  },
  {
    path: "/service-replay/:id",
    component: ServiceReplayDetail,
  },
  {
    path: "/birthdayboard",
    component: BirthdayBoard,
  },
  {
    path: "/birthdayboard/message",
    component: BirthdayBoardMessage,
  },
  {
    path: "/birthdayboard/favourite-messages",
    component: BirthdayBoardFavouriteMessages,
  },
  {
    path: "/birthdayboard/hidden-messages",
    component: BirthdayBoardHiddenMessages,
  },
  {
    path: "/birthdayboard/my-messages",
    component: BirthdayBoardMyMessages,
  },
  {
    path: "/birthdayboard/pinned-messages",
    component: BirthdayBoardPinnedMessages,
  },
  {
    path: "/protected/:name",
    component: ProtectedPage,
  },
  {
    path: "/e-invites",
    component: EInvitesPage,
  },
  {
    path: "/library",
    component: Library,
  },
  {
    path:"/milestones",
    component: Milestones,
  },
  {
    path: "/updates",
    component: Updates,
  },
  {
    path: "/updates/:id",
    component: UpdateDetails,
  },
  {
    path: "/md2023",
    component: MD2023,
  },
  {
    path: "/md2024-1",
    component: MD20241,
  },
  {
    path: "/md2024-2",
    component: MD20242,
  },
  {
    path: "/md2024-3",
    component: MD20243,
  },
  {
    path: "/md2024-4",
    component: MD20244,
  },
  {
    path: "/md2024-5",
    component: MD20245,
  },
  {
    path: "/hogcfd",
    component: FathersDay,
  },
  {
    path: "/hogcfd/2013",
    component: FD2013,
  },
  {
    path: "/hogcfd/2014",
    component: FD2014,
  },
  {
    path: "/hogcfd/2015",
    component: FD2015,
  },
  {
    path: "/hogcfd/2016",
    component: FD2016,
  },
  {
    path: "/hogcfd/2017",
    component: FD2017,
  },
  {
    path: "/hogcfd/2024",
    component: FD2024,
  },
];

routes.forEach((route) => {
  // these are for those page no need logins
  const excludeGuardPaths = ["/", "/privacy-policy", "/terms-of-use"];
  // these are for web only
  if (
    !excludeGuardPaths.includes(route.path) &&
    process.env.VUE_APP_NODE_ENV !== "local" &&
    !isNativeNotWeb()
  )
  route.beforeEnter = createAuthGuard({
    redirectLoginOptions: {
      appState: {
        returnTo: window.location.pathname, // here
      },
    },
  });
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach(async (to, from) => {
//   const excludeGuardPaths = [
//     "/",
//     "/privacy-policy",
//     "/terms-of-use",
//   ];
//   console.log("inside router before Each");
//   if (process.env.VUE_APP_NODE_ENV === "local") {
//     if (!localStorage.getItem("token") && to.path !== "/login")
//       return { path: "/login" };
//     return;
//   }

//   console.log("login - before");
//   if (
//     !excludeGuardPaths.includes(to.path) &&
//     process.env.VUE_APP_NODE_ENV !== "local" &&
//     to.path !== "/login" &&
//     isNativeNotWeb()
//   ) {
//     console.log("login - before use auth0");
//     console.log("login - ", auth0);
//     console.log("login - is Authent", auth0.isAuthenticated.value);
//     // Check if the user is authenticated
//     if (!auth0.isAuthenticated.value) {
//       // If the user is not authenticated, redirect to the login page
//       console.log("login - before forward login");

//       // Redirect to the login page if the user is not authenticated
//       if (!auth0.isAuthenticated.value && to.path !== "/login") {
//         console.log("login from path - ", from.path);
//         console.log("login from query - ", from.query);
//         console.log("login from hash - ", from.hash);
//         // Get the path from store.state

//         return { path: "/login" };
//       }

//       return { path: "/login" };
//     }
//     console.log("after use auth0");
//   }
// });

export default router;
