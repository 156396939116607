<template>
  <div v-if="showSection" class="w-full p-[24px] border-t-[1px] border-[#D3D8DE]">
    <div class="flex justify-between gap-[12px]">
      <div
        class="cursor-pointer grid justify-items-center w-full"
        :hover="currBoard.themeColour"
        data-event-tracking="favourite"
        @click="favouriteToggle"
        @mouseover="isFavouriteHover = true"
        @mouseout="isFavouriteHover = false"
      >
        <FavouriteIcon :is-favourite-active="isFavouriteActive" class="mb-[8px]" :fill="checkFavHover" />
        <p
          class="text-[16px] leading-[20px] font-[#373E49] font-semibold"
          :style="{ color: checkFavHover }"
        >
          Favourite
        </p>
      </div>
      <div
        class="cursor-pointer grid justify-items-center w-full"
        :hover="currBoard.themeColour"
        data-event-tracking="mark-as-read"
        @click="readToggle"
        @mouseover="isReadHover = true"
        @mouseout="isReadHover = false"
      >
        <MarkAsReadIcon :is-read-active="isReadActive" class="mb-[8px]" :fill="checkReadHover" />
        <p
          class="text-[16px] leading-[20px] font-[#373E49] font-semibold whitespace-nowrap"
          :style="{ color: checkReadHover }"
        >
          {{ !!isReadActive ? 'Mark as Unread' : 'Mark as Read' }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FavouriteIcon from '../../../../public/assets/icons/board/FavouriteIcon'
import MarkAsReadIcon from '../../../../public/assets/icons/board/MarkAsReadIcon'

export default {
  components: {
    FavouriteIcon,
    MarkAsReadIcon,
  },
  props: {
    isFavourite: {
      type: Boolean,
      default: false,
    },
    isRead: {
      type: Boolean,
      default: false,
    },
    favourite: {
      type: Function,
    },
    read: {
      type: Function,
    },
    isReadActive: {
      type: Boolean,
    },
    isFavouriteActive: {
      type: Boolean,
    },
    isMe: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isPostFavourited: this.isFavourite,
      isPostRead: this.isRead,
      isFavouriteHover: false,
      isReadHover: false,
      isMoreHover: false,
      readMore: false,
      favouriteToggle: this.favourite,
      readToggle: this.read,
      showSection: !this.isMe,
    }
  },
  computed: {
    ...mapState('board', ['currBoard', 'currPost']),
    checkFavHover () {
      return this.isFavouriteHover ? this.currBoard.themeColour : '#373E49'
    },
    checkReadHover () {
      return this.isReadHover ? this.currBoard.themeColour : '#373E49'
    },
    checkMoreHover () {
      return this.isMoreHover ? this.currBoard.themeColour : '#373E49'
    },
  },
}
</script>
