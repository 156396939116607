<template>
  <div class="inline-flex items-center">
    <span v-if="$slots.left" class="text-[16px] leading-[20px]">
      <slot name="left" />
    </span>
    <div
      class="relative inline-flex flex-shrink-0 h-[32px] transition-colors duration-200 ease-in-out border-[4px] border-transparent rounded-full w-[64px] focus:outline-none"
      :class="[disabledClasses, valueClasses]"
      @click="toggle"
    >
      <span
        aria-hidden="true"
        class="inline-block w-[24px] h-[24px] transition duration-200 ease-in-out transform rounded-full shadow pointer-events-none ring-0"
        :class="[
          localValue ? 'translate-x-[32px]' : 'translate-x-0',
          disabledCenterClasses,
        ]"
      />
    </div>
    <span v-if="$slots.right" class="text-[16px] leading-[20px]">
      <slot name="right" />
    </span>
  </div>
</template>

<script>
import HasLocalValue from '@/mixins/HasLocalValue.js'

export default {
  mixins: [HasLocalValue],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    disabledClasses() {
      if (this.disabled) {
        return this.localValue ? 'bg-[#80B3FF] cursor-default' : 'bg-[#D3D8DE]'
      } else {
        return 'cursor-pointer'
      }
    },
    valueClasses() {
      return this.localValue ? 'bg-[#CCE0FF]' : 'bg-[#F0F2F4]'
    },
    disabledCenterClasses() {
      if (this.disabled && ! this.localValue) {
        return 'bg-[#99A4B2]'
      }

      return 'bg-[#056DFF]'
    },
  },

  methods: {
    toggle() {
      if (!this.disabled) {
        this.localValue = !this.localValue
      }
    },
  },
}
</script>
