<template>
  <div>
    <p class="source-sans-3 font-bold text-[20px] leading-[32px] mb-[4px] sm:mb-[8px]">
      {{ title }}
    </p>
    <p class="box-border border-l-[4px] border-[#4D5766B2] px-[20px] py-[8px] whitespace-pre-line">
      <span class="text-[19px] sm:text-[20px] leading-[28px] sm:leading-[30px] italic">{{ quote }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    quote: {
      type: String,
      required: true,
    },
  },
}
</script>
