<template>
  <master-layout id="layout">
    <div v-if="isLoading" class="w-screen h-screen flex items-center justify-center">
      <Loading />
    </div>
    <div v-else>
      <div v-if="showDetailsPage">
        <img id="back-button" src="../../../public/assets/icons/chevron-left-white.svg"
          class="absolute top-[36px] left-6 lg:hidden cursor-pointer theme-back-button scroll-mt-[32px]" alt="Back icon"
          @click="back" />
        <DetailsPage />
      </div>
      <div v-else
        class="md:py-[40px] md:pl-[40px] lg:pr-[30px] sm:px-[24px] py-[24px] lg:py-[56px] lg:pl-[64px] lg:flex-1 lg:overflow-hidden mb-[24px]">
        <section class="px-[24px] sm:px-0 z-10 mt-[12px] mb-[32px]">
          <h1 class="text-[32px] leading-[48px] sm:text-[48px] sm:leading-[64px] font-bold mb-[8px] text-[#0B0C0F]">
            Milestones
          </h1>
          <p class="text-[16px] leading-[24px] text-[#21252C]">
            Kickstart your journey & track all your growth milestones here as a
            Christian! 👣
          </p>
        </section>

        <section class="flex flex-col items-center justify-center">
          <div class="grid grid-cols-2 gap-1">
            <div v-for="(track, index) in tracks" :key="index">
              <TrackCard v-if="track.milestones.length > 0 || track.isComingSoon" :track="track"
                @open-details-page="scrollPage" />
            </div>
          </div>
        </section>

      </div>
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import TrackCard from "@/components/Milestones/TrackCard.vue";
import Loading from "@/components/common/LoadingState.vue";
import DetailsPage from "@/components/Milestones/DetailsPage.vue";

export default {
  components: { TrackCard, Loading, DetailsPage },
  computed: {
    ...mapState("milestones", ["tracks", "isLoading", "showDetailsPage"]),
  },
  methods: {
    back() {
      this.$store.dispatch("milestones/updateShowDetailsPage", false);
    },
    scrollPage() {
      this.$nextTick(() => {
        const backButton = document.getElementById("back-button");
        if (backButton) {
          backButton.scrollIntoView();
        }
      });
    }
  },
  async ionViewWillEnter() {
    await this.$store.dispatch("milestones/updateShowDetailsPage", false);
    await this.$store.dispatch("milestones/getMilestones");
    await this.$store.dispatch("updates/getUpdates");
  },
};
</script>
