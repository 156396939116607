<template>
  <div
    class="font-normal text-base leading-6 mt-2"
    :class="hasPast ? pastClass : futureClass"
  >
    <div v-if="eventTime" class="flex items-center">
      <img class="h-full" :src="eventTimeIcon" alt="time icon" />
      <p class="ml-1">
        {{ eventTime }}
      </p>
    </div>

    <div v-if="eventLocation" class="flex items-center">
      <img class="h-full" :src="eventLocationIcon" alt="location icon" />
      <p class="ml-1">
        {{ eventLocation }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasPast: {
      type: Boolean,
      default: false,
    },
    eventTime: {
      type: String,
      default: null,
    },
    eventLocation: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pastClass: "text-[#7C8A9C]",
      futureClass: "text-[#4D5766]",
    };
  },
  computed: {
    eventTimeIcon() {
      return this.hasPast
        ? require("../../../../public/assets/icons/calendar/event-time-past.svg")
        : require("../../../../public/assets/icons/calendar/event-time-future.svg");
    },
    eventLocationIcon() {
      return this.hasPast
        ? require("../../../../public/assets/icons/calendar/event-location-past.svg")
        : require("../../../../public/assets/icons/calendar/event-location-future.svg");
    },
  },
};
</script>

<style scoped>
* {
  font-family: "proxima-nova";
  font-style: normal;
}
</style>
