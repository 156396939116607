<template>
  <InputField v-bind="{ labelClasses, helperClasses, errorMessageClasses, label, id, helper, error }">
    <input
      v-model="localValue"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      class="
        h-[40px]
        border border-[#637083]
        bg-[#F8F9F9]
        rounded-[8px]
        px-[12px]
        text-[16px]
        leading-[24px]
        w-full
        text-[#373E49]
        placeholder-[#373E49]
        focus:border-[#056DFF] focus:bg-white focus:ring-0 focus:outline-none
        hover:ring-0 hover:outline-none
      "
      :class="[inputClasses, errorClasses, disabledClasses]"
      :style="inputStyle"
      @click="$emit('click')"
      @blur="$emit('blur')"
      v-bind="$attrs"
    />
    <span
      v-if="characterCount"
      data-testid="character-count"
      class="
        text-[16px] text-[#637083]
        leading-[24px]
        mt-[4px]
        text-right
        float-right
      "
    >
      Character count {{ characterCountText }}
    </span>
  </InputField>
</template>

<script>
import InputField from '@/mixins/InputField'
import HasLocalValue from '@/mixins/HasLocalValue'
import HasInputClassProps from '@/mixins/HasInputClassProps'
import { IonInput } from '@ionic/vue';

export default {

  components:{
    IonInput
  },

  mixins: [HasLocalValue, InputField, HasInputClassProps],

  props: {
    inputClasses: {
      type: [String, Object, Array],
      default: null,
    },
    disabledClasses: {
      type: String,
      default: 'disabled:bg-[#F0F2F4]',
    },
    placeholder: {
      type: String,
      default: null,
    },
    characterCount: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    inputStyle: {
      type: String,
      default: '',
    },
  },

  computed: {
    characterCountText () {
      const characterCount = this.localValue ? this.localValue.length : 0
      const max = this.max
      if (!max) {
        return characterCount
      }

      return `${characterCount} / ${max}`
    },
  },
}
</script>
