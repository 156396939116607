<template>
  <div>
    <div
      class="flex flex-row p-[4px] bg-[#FFFFFF] rounded-[12px]"
      style="box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.10)"
    >
      <div
        class="cursor-pointer py-[8px] px-[24px] rounded-[8px] leading-[20px] font-semibold mr-[4px]"
        :style="{
          'background-color': myZoneFilterValue === 'all' ? currBoard.themeColour : '#FFFFFF',
          color: myZoneFilterValue === 'all'? currBoard.themeTextColour : '#637083',
          'box-shadow': myZoneFilterValue === 'all'? '0px 1px 4px 0px rgba(0, 0, 0, 0.25)' : 'none',
        }"
        data-event-tracking="church-view"
        @click="updateMyZoneFilterValue ('all')"
      >
        Church
      </div>
      <div
        class="cursor-pointer py-[8px] px-[24px] rounded-[8px] leading-[20px] font-semibold"
        :style="{
          'background-color': myZoneFilterValue === 'myzone'? currBoard.themeColour : '#FFFFFF',
          color: myZoneFilterValue === 'myzone' ? currBoard.themeTextColour : '#637083',
          'box-shadow': myZoneFilterValue === 'myzone'? '0px 1px 4px 0px rgba(0, 0, 0, 0.25)' : 'none',
        }"
        data-event-tracking="my-zone-view"
        @click="updateMyZoneFilterValue ('myzone')"
      >
        My Zone
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('board', ['boards', 'currBoard', 'myZoneFilterValue']),
  },
  methods: {
    updateMyZoneFilterValue (value) {
      this.$store.dispatch('board/updateMyZoneFilterValue', value)
      this.$store.dispatch('board/updateReadFilterValue', 'all')
      this.$store.dispatch('board/updateZoneFilterValue', [])
      this.$store.dispatch('board/updateTagFilterValue', [])
    },
  },
}
</script>

<style scoped></style>