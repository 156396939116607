<template>
  <div
    v-click-away="closeSideBar"
    class="fixed right-0 top-0 w-4/5 drop-shadow-[0_1px_25px_rgba(0,0,0,0.25)] sm:w-[312px] h-screen p-[32px] z-50"
    :style="{
      'background-image': `url(${bgImageURL})`,
      'background-size': bgImageSize,
      'background-color': currBoard.themeColour,
    }"
  >
    <div class="flex justify-end mb-[40px]" @click="closeSideBar">
      <CloseIcon
        class="h-[24px] w-[24px] cursor-pointer"
        :fill="currBoard.themeTextColour"
      />
    </div>
    <div v-for="menuItem in menuItems" :key="menuItem.id">
      <div
        class="pb-[32px] cursor-pointer flex"
        :data-event-tracking="menuItem?.det"
        @click="clickItem(menuItem.slug)"
      >
        <BackToBoard
          v-if="menuItem.logo === 'back-to-board'"
          class="h-[24px] w-[24px] mr-[14px]"
          :fill="currBoard.themeTextColour"
        />
        <MyMessages
          v-if="menuItem.logo === 'my-messages'"
          class="h-[24px] w-[24px] mr-[14px]"
          :fill="currBoard.themeTextColour"
        />
        <FavouriteMessages
          v-if="menuItem.logo === 'favourite-messages'"
          class="h-[24px] w-[24px] mr-[14px]"
          :fill="currBoard.themeTextColour"
        />
        <PinnedMessages
          v-if="menuItem.logo === 'pinned-messages'"
          class="h-[24px] w-[24px] mr-[14px]"
          :fill="currBoard.themeTextColour"
        />
        <HiddenMessages
          v-if="menuItem.logo === 'hidden-messages'"
          class="h-[24px] w-[24px] mr-[14px]"
          :fill="currBoard.themeTextColour"
        />
        <HOGCLogo
          v-if="menuItem.logo === 'logo-hogc'"
          class="h-[24px] w-[24px] mr-[14px]"
          :fill="currBoard.themeTextColour"
        />
        <h5
          class="font-bold text-[20px] leading-[24px]"
          :style="{ color: currBoard.themeTextColour }"
        >
          {{ menuItem.title }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CloseIcon from "../../../../public/assets/icons/board/CloseIcon";
import BackToBoard from "../../../../public/assets/icons/board/sidebar/BackToBoard";
import FavouriteMessages from "../../../../public/assets/icons/board/sidebar/FavouriteMessages";
import HOGCLogo from "../../../../public/assets/icons/board/sidebar/HOGCLogo";
import HiddenMessages from "../../../../public/assets/icons/board/sidebar/HiddenMessages";
import MyMessages from "../../../../public/assets/icons/board/sidebar/MyMessages";
import PinnedMessages from "../../../../public/assets/icons/board/sidebar/PinnedMessages";

export default {
  components: {
    CloseIcon,
    BackToBoard,
    FavouriteMessages,
    HOGCLogo,
    HiddenMessages,
    MyMessages,
    PinnedMessages,
  },
  props: {
    isMainPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowWidth: 0,
    };
  },
  computed: {
    ...mapState("board", ["currBoard", "isCurrBoardAdmin", "showSideBar"]),
    menuItems() {
      return [
        {
          title: "Back to Main Board",
          logo: "back-to-board",
          slug: "birthdayboard",
          isAdmin: false,
        },
        {
          title: "My Messages",
          logo: "my-messages",
          slug: "birthdayboard/my-messages",
          isAdmin: false,
          det: "my-messages",
        },
        {
          title: "Favourite Messages",
          logo: "favourite-messages",
          slug: "birthdayboard/favourite-messages",
          isAdmin: false,
          det: "favourite-messages",
        },
        {
          title: "Pinned Messages",
          logo: "pinned-messages",
          slug: "birthdayboard/pinned-messages",
          isAdmin: true,
        },
        {
          title: "Hidden Messages",
          logo: "hidden-messages",
          slug: "birthdayboard/hidden-messages",
          isAdmin: true,
        },
        {
          title: "Back to HOGC One",
          logo: "logo-hogc",
          slug: "",
          isAdmin: false,
        },
      ].filter((menuItem) => {
        if (menuItem.isAdmin) {
          return this.isCurrBoardAdmin;
        }
        if (this.isMainPage) {
          return menuItem.slug !== "birthdayboard";
        }
        return true;
      });
    },
    bgImageURL() {
      return this.getBgImageURL();
    },
    bgImageSize() {
      return this.getBgImageSize();
    },
  },
  created() {
    this.setWindowWidth();
    window.addEventListener("resize", this.setWindowWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.setWindowWidth);
  },
  methods: {
    clickItem(slug) {
      this.closeSideBar();
      this.$router.push({ path: `/${slug}` });
    },
    closeSideBar() {
      this.$store.dispatch("board/updateShowSideBar", false);
    },
    getBgImageURL() {
      if (this.windowWidth < 768) {
        return this.currBoard.sidebarBackground.mobile;
      } else if (this.windowWidth >= 768 && this.windowWidth < 1024) {
        return this.currBoard.sidebarBackground.tablet;
      } else if (this.windowWidth >= 1024) {
        return this.currBoard.sidebarBackground.desktop;
      }
    },
    getBgImageSize() {
      if (this.windowWidth < 768) {
        return 'cover'
      } else if (this.windowWidth >= 768 && this.windowWidth < 1024) {
        return 'cover'
      } else if (this.windowWidth >= 1024) {
        return '312px 100vh'
      }
    },
    setWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.slide-out-enter,
.slide-out-leave-to {
  transform: translate(100%, 0);
}

.slide-out-active,
.slide-leave-active {
  transition: 0.8s ease-in-out;
}
</style>
