<template>
  <div
    class="root has-shadow fixed w-full bg-[#ffffff] z-10 px-[24px] lg:px-0"
    :class="{ open: showCalendarMenu }"
  >
    <CalendarToggle />
    <div class="flex lg:max-w-[418px] w-full mx-auto pt-1 pb-3">
      <CalendarMonth />
      <CalendarOptions ref="calendarOptions" />
    </div>
    <CalendarMenu v-if="showCalendarMenu" />
    <CalendarFiltersModal v-show="showCalendarFilters" />
    <!-- <CalendarNotificationsModal v-show="showCalendarNotifications" ref="calendarNotificationsModal" v-click-away="clickawayNotification"/> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CalendarMenu from '@/components/Calendar/Menu/CalendarMenu'
import CalendarMonth from '@/components/Calendar/Menu/CalendarMonth'
import CalendarOptions from '@/components/Calendar/Menu/CalendarOptions'
import CalendarFiltersModal from '@/components/Calendar/Menu/CalendarFiltersModal'
// import CalendarNotificationsModal from '@/components/Calendar/Notifications/CalendarNotificationsModal'
import CalendarToggle from '@/components/Calendar/Toggle/CalendarToggle'

export default {
  components: {
    CalendarMenu,
    CalendarMonth,
    CalendarOptions,
    CalendarToggle,
    CalendarFiltersModal,
    // CalendarNotificationsModal,
  },
  computed: {
    ...mapState('calendar', ['showCalendarFilters', 'showCalendarMenu', 'showCalendarNotifications']),
  },
  watch: {
    showCalendarFilters (isShowingModal) {
      this.updateOverflowHidden(isShowingModal)
    },
    // showCalendarNotifications (isShowingModal) {
    //   this.updateOverflowHidden(isShowingModal)
    // },
  },
  // mounted () {
  //   this.setNotificationsModalPosition()
  //   window.addEventListener('resize', this.setNotificationsModalPosition)
  // },
  // beforeDestroy () {
  //   window.removeEventListener('resize', this.setNotificationsModalPosition)
  // },
  methods: {
    updateOverflowHidden (isShowingModal) {
      if (isShowingModal) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    },
    // setNotificationsModalPosition () {
    //   if (window.innerWidth > 1024) {
    //     const { calendarNotificationsModal, calendarOptions } = this.$refs
    //     const targetRect = calendarOptions.$el.getBoundingClientRect()
    //     const targetX = targetRect.left - 316
    //     const targetY = targetRect.top - 10
    //     calendarNotificationsModal.$el.style.left = targetX + 'px'
    //     calendarNotificationsModal.$el.style.top = targetY + 'px'
    //   } else {
    //     const { calendarNotificationsModal } = this.$refs
    //     calendarNotificationsModal.$el.style.left = '0px'
    //     calendarNotificationsModal.$el.style.top = '0px'
    //   }
    // },
    // async clickawayNotification (event) {
    //   const isButtonPressed = event.target.id === 'notificationCalButton'
    //   if (!isButtonPressed) {
    //     await this.$store.dispatch('calendar/updateShowCalendarNotifications', false)
    //   }
    // },
  },
}
</script>

<style scoped>
.root.has-shadow {
  border-radius: 0px 0px 24px 24px;
  transition: border-radius 150ms ease-in;
  box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.08);
  --tw-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.08);
}

.root.has-shadow.open {
  border-radius: 0;
  z-index: 30;
}
@media (min-width: 1024px) {
  .root.has-shadow {
    border-radius: 0;
  }
}
</style>
