<template>
  <birthday-layout>
  <div>
    <BoardContent class="pt-[152px] md:pt-[200px]" :is-my-messages="true" />
    <WriteMessageButton />
  </div>
</birthday-layout>
</template>

<script>
import BoardContent from '@/components/Board/Main/BoardContent'
import BirthdayLayout from "@/components/BirthdayLayout.vue";

import WriteMessageButton from '@/components/Board/Main/WriteMessageButton'

export default {
  components: {
    BoardContent,
    BirthdayLayout,
    WriteMessageButton,
  },
}
</script>
