<template>
  <div
    v-if="isShowing"
    ref="interactElement"
    :class="{
      isAnimating: isInteractAnimating,
      isCurrent: isCurrent,
    }"
    class="swipe-card cursor-pointer relative b-8 border-white z-40"
    :style="{ transform: transformString }"
  >
    <div class="absolute w-full h-full" :class="getBorderClass()"></div>
    <img
      style="max-width: unset !important"
      :src="card.img"
      :class="getClass()"
      :alt="altText"
      @click="handleClickImage"
    />
    <p
      v-if="countryName"
      class="absolute text-black bottom-3 lg:bottom-5 text-center text-16-lh-20 lg:text-20-lh-32"
    >
      {{ countryName }}
    </p>
  </div>
</template>

<script>
import interact from 'interact.js'
import { gsap } from '@/libraries/gsap'

const ACCEPT_CARD = 'cardAccepted'
const REJECT_CARD = 'cardRejected'
const SKIP_CARD = 'cardSkipped'
const HANDLE_CLICK_IMAGE = 'handleClickImage'

export default {
  static: {
    interactMaxRotation: 10,
    interactOutOfSightXCoordinate: 2000,
    interactOutOfSightYCoordinate: 0,
    // interactYThreshold: 50,
    interactXThreshold: 100,
  },
  props: {
    altText: {
      type: String,
      default: '',
    },
    card: {
      type: Object,
      required: true,
    },
    countryName: {
      type: String,
      default: '',
      required: false,
    },
    isCurrent: {
      type: Boolean,
      required: true,
    },
    isSection: { type: String, required: false, default: '' },
    dragHorizontalDistance: {
      type: Number,
      default: 200,
    },
    dragVerticalDistance: {
      type: Number,
      default: -50,
    },
  },

  data () {
    return {
      isShowing: true,
      isInteractAnimating: true,
      isInteractDragged: null,
      interactPosition: {
        x: 0,
        y: 0,
        rotation: 0,
      },
      isMoveBack: false,
      isMouseEvent: false,
    }
  },

  computed: {
    transformString () {
      if (!this.isInteractAnimating || this.isInteractDragged) {
        const { x, y, rotation } = this.interactPosition
        return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`
      }

      return null
    },
  },

  mounted () {
    const element = this.$refs.interactElement

    interact(element).draggable({
      onstart: () => {
        this.isInteractAnimating = false
        this.$emit('pickCard')
      },

      onmove: (event) => {
        const { interactMaxRotation, interactXThreshold } = this.$options.static
        const x = this.interactPosition.x + event.dx
        const y = this.interactPosition.y + event.dy

        let rotation = interactMaxRotation * (x / interactXThreshold)

        if (rotation > interactMaxRotation) rotation = interactMaxRotation
        else if (rotation < -interactMaxRotation) rotation = -interactMaxRotation

        this.interactSetPosition({ x, y, rotation })
      },

      onend: () => {
        const { x } = this.interactPosition
        const { interactXThreshold } = this.$options.static
        this.isInteractAnimating = true

        if (x > interactXThreshold) {
          // gsap.to(this.$refs.interactElement, { x: 2500, duration: 1 })
          this.isMouseEvent = true
          // this.playCard(ACCEPT_CARD)
          this.$emit('releaseCard', ACCEPT_CARD)
        } else if (x < -interactXThreshold) {
          this.isMouseEvent = true
          // this.playCard(REJECT_CARD)
          this.$emit('releaseCard', REJECT_CARD)
        } else {
          this.$emit('releaseCard', -1)
          this.hideCard(true)
          this.resetCardPosition()
        }
      },
    })
  },

  beforeDestroy () {
    interact(this.$refs.interactElement).unset()
  },

  methods: {
    manualDrag () {
      // this.interactSetPosition({ x: 50, y: 0, rotation: 0 })

      gsap.to(this.$refs.interactElement, {
        x: this.dragHorizontalDistance,
        y: this.dragVerticalDistance,
        rotation: Math.floor(Math.random() * 10),
        duration: 0.5,
        // onComplete: () => this.dragBack(),
        onComplete: setTimeout(() => {
          this.dragBack()
        }, 2000),
      })
      // this.resetCardPosition()
    },
    dragBack () {
      gsap.to(this.$refs.interactElement, {
        x: 0,
        y: 0,
        rotation: 0,
        duration: 0.5,
      })
    },
    hideCard (isDelayed = false) {
      // this.isShowing = false
      this.$emit('hideCard', isDelayed)
    },
    moveToOrigin () {
      this.isMoveBack = true
      this.isInteractDragged = false
      this.interactPosition = {
        x: 0,
        y: 0,
        rotation: 0,
      }
      gsap.to(this.$refs.interactElement, {
        x: 0,
        y: 0,
        rotation: Math.floor(Math.random() * 8),
        duration: 0.5,
      })
    },
    moveToOut (defaultDirection) {
      if (this.isInteractAnimating === false) {
        return false
      }
      const timestamp = new Date().getUTCMilliseconds()
      const rand = Math.floor(Math.random(timestamp) * timestamp)
      const mod = rand % 2
      let direction = ACCEPT_CARD
      if (mod === 0) {
        direction = REJECT_CARD
      }
      if (defaultDirection !== null) direction = defaultDirection
      this.playCard(direction)
    },
    playCard (interaction) {
      const { interactOutOfSightXCoordinate, interactOutOfSightYCoordinate, interactMaxRotation } =
        this.$options.static

      this.interactUnsetElement()

      switch (interaction) {
        case ACCEPT_CARD:
          this.interactSetPosition({
            x: interactOutOfSightXCoordinate,
            rotation: interactMaxRotation,
          })
          // this.$emit(ACCEPT_CARD)
          break
        case REJECT_CARD:
          this.interactSetPosition({
            x: -interactOutOfSightXCoordinate,
            rotation: -interactMaxRotation,
          })
          // this.$emit(REJECT_CARD)
          break
        case SKIP_CARD:
          this.interactSetPosition({
            y: interactOutOfSightYCoordinate,
          })
          // this.$emit(SKIP_CARD)
          break
      }
      // this.hideCard()
    },

    interactSetPosition (coordinates) {
      const { x = 0, y = 0, rotation = 0 } = coordinates
      this.interactPosition = { x, y, rotation }
    },

    interactUnsetElement () {
      // interact(this.$refs.interactElement).unset()
      this.isInteractDragged = true
    },

    resetCardPosition () {
      this.interactSetPosition({ x: 0, y: 0, rotation: 0 })
    },

    getClass () {
      return {
        worship_cardimage: this.isSection === 'worship',
        hogc_cardimage: this.isSection === 'hogc',
        generation_cardimage: this.isSection === 'generation',
      }
    },

    getBorderClass () {
      return {
        'border-7.5 lg:border-11 border-white': this.isSection === 'hogc',
      }
    },

    handleClickImage () {
      this.$emit(HANDLE_CLICK_IMAGE)
    },
  },
}
</script>

<style lang="scss" scoped>
.swipe-card {
  -ms-touch-action: none;
  touch-action: none;
  box-shadow: hsla(36, 29%, 17%, 0.2) !important;
}

.hogc_cardimage {
  height: 100%;
}

.generation_cardimage {
  height: 100%;
  width: 100%;
}

.worship_cardimage {
  height: 100%;
}
</style>

<style lang="scss">
@use 'sass:math';

$cardsTotal: 5;
$cardsWidth: 300px;
$cardsPositionOffset: 55vh * 0.06;
$cardsScaleOffset: 0.05;
$defaultTranslation: $cardsPositionOffset * $cardsTotal;
$defaultScale: 1 - ($cardsScaleOffset * $cardsTotal);
$defaultRotate: 20deg;
$fs-card-title: 1.125em;

$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$c-white: #fff;
$c-black: #000;
$c-red-50: #73345e;
$c-red-25: #432958;
$c-navy: #0f0e3d;
$c-text: #8285a7;
$primary-gradient-start: #d94e47;
$primary-gradient-end: #df1165;

@mixin _position($position, $args) {
  @each $dir in top, left, bottom, right {
    $i: index($args, $dir);

    @if $i {
      #{$dir}: nth($args, $i + 1);
    }
  }

  position: $position;
}

@mixin absolute($args) {
  @include _position(absolute, $args);
}

@mixin card() {
  // border-radius: 15px;
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.05);
}

@mixin sizing($args, $prefix: '') {
  $width: if(length($args) == 2, nth($args, 1), $args);
  $height: if(length($args) == 2, nth($args, 2), $args);

  #{$prefix}width: $width;
  #{$prefix}height: $height;
}

@mixin flex-center() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin after() {
  &::after {
    content: '';

    @content;
  }
}

$fs-h2: 1.5rem;
$fw-bold: 700;

.swipe-card {
  @include absolute(0);
  @include sizing(100% 100%);
  @include flex-center();

  @include after() {
    // @include sizing(21px 3px);
    @include absolute(right 0 bottom 11px left 0);

    margin: auto;
    border-radius: 100px;
    background: rgba($c-black, 0.3);
  }

  display: flex;
  margin: auto;
  font-size: $fs-h2;
  font-weight: $fw-bold;
  color: $c-white;
  opacity: 0;
  transform: translateY($defaultTranslation) scale($defaultScale);
  transform-origin: 50%, 100%;
  box-shadow: hsla(36, 29%, 17%, 0.2) !important;
  user-select: none;
  pointer-events: none;
  will-change: transform, opacity;
  // height: 100vw;

  &.isCurrent {
    pointer-events: auto;
  }

  &.isAnimating {
    transition: transform 1s $ease-out-back;
  }
}

.header {
  .swipe-card {
    @include sizing(100% 100%);
  }
}

.cardTitle {
  margin: 0 0 15px;
  font-size: $fs-card-title;
}

@for $i from 1 through $cardsTotal {
  $index: $i - 1;
  $translation: $cardsPositionOffset * $index;
  $scale: 1 - ($cardsScaleOffset * $index);
  $rand: math.div(random($i), $i);
  $rotDir: math.div((math.div($index, 2) - $index * $rand), $i);
  $rotate: $rotDir * $defaultRotate;

  .swipe-card:nth-child(#{$i}) {
    z-index: $cardsTotal - $index;
    opacity: 1;
    transform: rotate($rotate);

    @if $i != 1 {
      // background-image: none;

      @include after() {
        @include sizing(0 0);
      }
    }
  }
}
</style>
