<template>
  <div>
    <label class="mb-[4px] md:mb-[12px]">Have image(s)?</label>

    <div>
      <div class="my-3 md:hidden flex flex-col">
        <BaseButton
          half-width
          class="button max-w-[146px]"
          solid-classes="bg-[#FFFFFF] text-black font-semibold rounded-[24px] "
          :disabled="maximumFilesBool"
          @click="$refs.fileInput.click()"
        >
          <div class="inline-flex items-center">
            <img src="../../../../public/assets/icons/board/add.svg" />
            <span class="ml-[4px]">Add Images</span>
          </div>
        </BaseButton>
        <span v-if="!maximumFilesBool" class="mt-3 text-xs"
          >Upload up to 5 images.</span
        >
        <span v-else class="mt-3 text-xs"
          >Maximum of 5 images has been uploaded.</span
        >
      </div>
      <input
        id="file-upload"
        ref="fileInput"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        class="hidden"
        multiple
        @change="uploadFile"
      />
      <div class="mt-3 mb-6 md:my-4">
        <div
          class="border-dotted border-2 border-[#99A4B2] rounded-md flex md:flex-row flex-wrap gap-4 w-auto md:w-[716px] min-h-[188px] md:min-h-[172px] p-6 h-auto justify-center md:justify-start"
        >
          <div
            v-for="(filePreview, index) in filePreviewList"
            :key="index"
            class="relative"
          >
            <figure
              class="overflow-hidden md:w-[120px] md:h-[120px] w-[140px] h-[140px]"
            >
              <div
                :style="{ backgroundImage: `url(${filePreview})` }"
                class="image rounded-[8px] w-full h-full bg-cover bg-center"
              ></div>
            </figure>
            <img
              class="absolute w-6 h-6 top-2 right-2"
              src="../../../../public/assets/icons/board/remove-button.svg"
              @click="removeFile(index)"
            />
          </div>
          <div
            v-if="!filePreviewList.length"
            class="rounded-md flex md:flex-row flex-wrap gap-4 justify-center md:justify-start"
          >
            <figure
              v-for="n in 5"
              :key="n"
              class="overflow-hidden md:w-[120px] md:h-[120px] w-[140px] h-[140px] rounded-lg"
            >
              <img
                src="../../../../public/assets/images/board/placeholder-image.png"
              />
            </figure>
          </div>
        </div>
      </div>
      <div class="hidden md:block">
        <div class="flex flex-row">
          <BaseButton
            half-width
            class="button"
            solid-classes="bg-[#FFFFFF] text-black font-semibold rounded-[24px] "
            :disabled="maximumFilesBool"
            @click="$refs.fileInput.click()"
          >
            <div class="inline-flex items-center">
              <img src="../../../../public/assets/icons/board/add.svg" />
              <span class="ml-[4px]">Add Images</span>
            </div>
          </BaseButton>
          <span
            v-if="!maximumFilesBool"
            class="ml-4 self-center justify-self-center text-xs"
            >Upload up to 5 images.</span
          >
          <span v-else class="ml-4 self-center justify-self-center text-xs"
            >Maximum of 5 images has been uploaded.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import BaseButton from "@/components/design/BaseButton";
export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      filePreviewList: [],
      currentPictureList: [],
      fileAndUrlObjList: [],
    };
  },
  computed: {
    ...mapState({
      filePreviewState: (state) => state.board.currPost.attachmentUrlList,
      submitButtonState: (state) => state.board.submitButton,
    }),
    ...mapState("user", ["token"]),
    maximumFilesBool() {
      return this.filePreviewList.length >= 5;
    },
  },
  created() {
    if (this.submitButtonState === "Save Changes") {
      // Deep copy the array to prevent state mutation
      this.filePreviewList = JSON.parse(JSON.stringify(this.filePreviewState));
      this.filePreviewList.forEach((file) => {
        this.currentPictureList.push({
          file,
          state: "aws",
        });

        // In cases where user user edit message with pictures, then remove the pictures without uploading new ones
        this.fileAndUrlObjList.push({
          file,
          uploadUrl: file,
          state: "aws",
        });
      });

      this.$emit("set-file-and-url", this.fileAndUrlObjList);
    }
  },
  methods: {
    removeFile(index) {
      this.currentPictureList.splice(index, 1);
      this.filePreviewList.splice(index, 1);
      this.fileAndUrlObjList.splice(index, 1);

      this.$emit("set-file-and-url", this.fileAndUrlObjList);
    },
    async uploadFile() {
      for (let i = 0; i < this.$refs.fileInput.files.length; i++) {
        const fileObj = {
          file: this.$refs.fileInput.files[i],
          state: "local",
        };
        this.currentPictureList.push(fileObj);
        this.filePreviewList.push(
          URL.createObjectURL(this.$refs.fileInput.files[i])
        );
      }

      // Cut down total files to 5 files
      if (this.filePreviewList.length > 5) {
        this.currentPictureList = this.currentPictureList.slice(0, 5);
        this.filePreviewList = this.filePreviewList.slice(0, 5);
      }

      // Get upload urls based on local amount
      let count = 0;
      this.currentPictureList.forEach((fileObj) => {
        if (fileObj.state === "local") {
          count++;
        }
      });
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL + `/board/upload-url/${count}`,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      const uploadUrlList = data;

      // The function after this assumes an empty fileAndUrlObjList
      this.fileAndUrlObjList = [];

      // Add file and upload url to list of objects
      this.currentPictureList.forEach((fileObj) => {
        if (fileObj.state === "aws") {
          this.fileAndUrlObjList.push({
            file: fileObj.file,
            uploadUrl: fileObj.file,
            state: fileObj.state,
          });
        } else {
          this.fileAndUrlObjList.push({
            file: fileObj.file,
            uploadUrl: uploadUrlList.pop(),
            state: fileObj.state,
          });
        }
      });

      this.$emit("set-file-and-url", this.fileAndUrlObjList);
    },
  },
};
</script>

<style scoped>
.button {
  font-size: 16px !important;
  line-height: 22px !important;
  padding: 9px 16px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
  border: none !important;
}
</style>
