<template>
  <div class="modal-overlay pt-[68px] lg:pt-[88px]">
    <div class="modal px-[24px] py-[40px]">
      <div class="clear-m"></div>
      <div class="flex">
        <div class="font-bold text-2xl text-[#0B0C0F]">Filters</div>
        <button class="ml-auto pr-1" @click="close">
          <img src="../../../../public/assets/icons/calendar/close-button.svg" alt="" />
        </button>
      </div>
      <div class="pt-6 pb-[20px]">
        <div v-for="(filter, index) in calendarEventFilters" :key="index" class="flex pb-5">
          <input
            :id="filter"
            v-model="selectedFilters"
            type="checkbox"
            :value="filter"
            class="h-5 w-5 form-tick rounded appearance-none border border-[#403c4c] hover:cursor-pointer"
          />
          <label :for="filter" class="font-normal text-xl leading-6 pl-3">{{ filter }}</label>
        </div>
      </div>
      <div class="flex h-10 gap-[15px] items-start">
        <BaseButton
          :disabled="selectedFilters.length === 0"
          type="outline"
          outline-classes="c-btn flex-1 h-10 border-[1.5px] border-solid border-[#6245F4] justify-center items-center"
          @click="selectedFilters = []"
        >
          <p v-if="selectedFilters.length === 0" class="font-semibold text-base">Clear</p>
          <p v-else class="font-semibold text-base text-[#6245F4]">
            Clear ({{ selectedFilters.length }})
          </p>
        </BaseButton>
        <BaseButton
          solid-classes="flex-1 h-10 bg-[#6245F4] justify-center items-center"
          @click="applySelectedFilters"
        >
          <p class="font-semibold text-base text-[#F8F9F9]">Apply</p>
        </BaseButton>
      </div>
      <div class="clear-m"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseButton from '@/components/design/BaseButton.vue'

export default {
  components: {
    BaseButton,
  },
  data () {
    return {
      selectedFilters: [],
    }
  },
  computed: {
    ...mapState('calendar', ['filters', 'isLeadersCalendar']),
    ...mapState('cms', ['calendarEventFilters']),
  },
  watch: {
    isLeadersCalendar () {
      this.selectedFilters = []
    },
    filters: {
      handler (newFilters) {
        this.selectedFilters = newFilters
      },
      immediate: true,
    },
  },
  methods: {
    async applySelectedFilters () {
      await this.$store.dispatch('calendar/updateShowCalendarMenu', false)
      await this.$store.dispatch('calendar/updateShowCalendarFilters', false)
      await this.$store.dispatch('calendar/updateIsFirstScroll', true)
      await this.$store.dispatch('calendar/updateShowFilterBar', true)
      await this.$store.dispatch('calendar/updateFilters', this.selectedFilters)
    },
    async close () {
      await this.$store.dispatch('calendar/updateShowCalendarFilters', false)
      this.selectedFilters = this.filters
    },
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.57);
  z-index: 200;
}

.modal {
  background-color: white;
  min-height: 300px;
  width: 375px;
  border-radius: 24px;
}

.clear-m {
  clear: both;
}

@media all and (max-width: 375px) {
  .modal {
    background-color: white;
    min-height: 300px;
    width: 80%;
    border-radius: 24px;
  }
}

.form-tick:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: #6245f4;
  background-size: 100% 100%;
  background-position: 50%;
  background-repeat: no-repeat;
}

.c-btn:disabled {
  background-color: transparent;
  border-color: #7c8a9c;
}

.c-btn:disabled p {
  color: #7c8a9c;
}
</style>
