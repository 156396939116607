<template>
  <master-layout>
    <div
      class="md:py-[40px] md:pl-[40px] lg:pr-[30px] sm:px-[24px] py-[24px] lg:py-[56px] lg:pl-[64px] lg:flex-1 lg:overflow-hidden mb-[24px] pl-[24px]"
    >
      <section>
        <h1
          class="text-[32px] leading-[40px] sm:text-[48px] sm:leading-[64px] font-bold mb-[4px] text-[#0B0C0F]"
        >
          E-invites
        </h1>
      </section>
      <div v-if="loading" class="w-screen h-screen flex items-center justify-center">
        <Loading />
      </div>
      <div v-else-if="eInvites.length > 0" class="sm:mt-[16px] mt-[24px]">
        <EInvite
          v-for="invite in eInvites"
          :id="invite.slug"
          :ref="invite.slug"
          :key="invite.eventName"
          class="mb-[32px]"
          :name="invite.eventName"
          :description="invite.eventDescription"
          :assets="invite.assets"
        />
      </div>
      <p v-else class="text-[#acacac] text-center mt-[24px]">
        No e-invites to show.
      </p>
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import Loading from "@/components/common/LoadingState.vue";
import EInvite from "@/components/EInvitesPage/EInvite.vue";
import isIos from "@/mixins/isIos";

export default {
  components: {
    Loading,
    EInvite,
  },
  mixins: [isIos],
  computed: {
    ...mapState("cms", {
      isCmsLoading: (state) => state.loading,
      eInvites: (state) => state.eInvites,
    }),
  },
  async ionViewWillEnter() {
    console.log(this.$route)
    await Promise.all([
      this.$store.dispatch("cms/fetchCmsContent", {
        entityName: "resources/e-invites",
        stateName: "eInvites",
      }),
      this.$store.dispatch("updates/getUpdates", "all")
    ]);
  },
};
</script>
