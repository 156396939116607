<template>
  <div>
    <div v-if="isAnon" class="font-bold mr-2 flex-nowrap h-[24px]">
      Anonymous
    </div>
    <div v-else>
      <div class="mb-4 flex flex-row gap-2">
        <div v-for="(tag, index) in tags" :key="index">
          <Tag :tag-name="tag" />
        </div>
      </div>
      <div class="flex flex-row flex-wrap cursor-text">
        <div
          v-if="$route.path !== '/birthdayboard/my-messages'"
          class="font-bold mr-2 flex-nowrap h-[24px]"
        >
          {{ member.fullName }}
        </div>
        <div
          v-if="$route.path === '/birthdayboard/my-messages'"
          class="flex gap-[16px]"
        >
          <p
            class="px-[8px] rounded-[8px]"
            :style="{
              'background-color': messageNameTag.colour,
              color: messageNameTag.textColour,
            }"
          >
            {{ messageNameTag.name }}
          </p>
          <p class="rounded-[8px] bg-[#F0F2F4] px-[8px]">{{ yearCreatedAt }}</p>
        </div>
        <div
          v-if="$route.path !== '/birthdayboard/my-messages'"
          class="flex flex-row"
          :class="member.fullName.length > 30 ? 'mt-[26px]' : ''"
        >
          <div class="bg-[#FFF8BB] rounded-lg px-2">{{ member?.cg }}</div>
          <InstagramProfile
            v-if="isCurrBoardAdmin && member?.igHandle"
            class="ml-2"
            :ig-handle="member?.igHandle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import InstagramProfile from "@/components/Board/Card/InstagramProfile";
import Tag from "@/components/Board/Card/Tag";

export default {
  components: {
    InstagramProfile,
    Tag,
  },
  props: {
    member: {
      type: Object,
      default: null,
    },
    isAnon: {
      type: Boolean,
      default: false,
    },
    boardId: {
      type: Number,
      default: 0,
    },
    yearCreatedAt: {
      type: Number,
      default: 0,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState("board", ["isCurrBoardAdmin", "currBoard"]),
    messageNameTag() {
      return {
        colour: this.currBoard.themeColour,
        textColour: this.currBoard.themeTextColour,
        name: this.currBoard.name,
      };
    },
  },
};
</script>
