<template>
  <svg
    v-if="!isActive"
    class="block"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00001 9.33325H12H22.6667V23.9999C22.6667 24.7072 22.3857 25.3854 21.8856 25.8855C21.3855 26.3856 20.7073 26.6666 20 26.6666H8.00001C7.29277 26.6666 6.61449 26.3856 6.11439 25.8855C5.61429 25.3854 5.33334 24.7072 5.33334 23.9999V11.9999C5.33334 11.2927 5.61429 10.6144 6.11439 10.1143C6.61449 9.6142 7.29277 9.33325 8.00001 9.33325Z"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.803 8.13623L15.6893 19.2499H12.75V16.3106L23.8637 5.19689L23.3333 4.66656L23.8637 5.19689C24.2534 4.80711 24.7821 4.58813 25.3333 4.58813C25.8846 4.58813 26.4132 4.80711 26.803 5.19689C27.1928 5.58667 27.4118 6.11533 27.4118 6.66656C27.4118 7.21779 27.1928 7.74645 26.803 8.13623Z"
      fill="white"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M16 20L11 21L12 16L14 18L16 20Z" fill="#6245F4" />
    <path
      d="M26.803 8.13623L26.803 8.13627L25 9.93954L22.0606 7.0002L23.8637 5.19689C23.8637 5.19688 23.8637 5.19687 23.8637 5.19685C24.2535 4.8071 24.7821 4.58813 25.3333 4.58813C25.8846 4.58813 26.4132 4.80711 26.803 5.19689C27.1928 5.58667 27.4118 6.11533 27.4118 6.66656C27.4118 7.21779 27.1928 7.74645 26.803 8.13623Z"
      fill="white"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00004 9.33325H12H22.6667V23.9999C22.6667 24.7072 22.3858 25.3854 21.8857 25.8855C21.3856 26.3856 20.7073 26.6666 20 26.6666H8.00004C7.2928 26.6666 6.61452 26.3856 6.11442 25.8855C5.61433 25.3854 5.33337 24.7072 5.33337 23.9999V11.9999C5.33337 11.2927 5.61433 10.6144 6.11442 10.1143C6.61452 9.6142 7.2928 9.33325 8.00004 9.33325Z"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 19.9999H16L27.3333 8.66656C27.8638 8.13613 28.1618 7.41671 28.1618 6.66656C28.1618 5.91642 27.8638 5.19699 27.3333 4.66656C26.8029 4.13613 26.0835 3.83813 25.3333 3.83813C24.5832 3.83813 23.8638 4.13613 23.3333 4.66656L12 15.9999V19.9999Z"
      fill="#6245F4"
    />
    <path d="M16 20L11 21L12 16L16 20Z" fill="#6245F4" />
    <path
      d="M26.803 8.13623L26.803 8.13627L25 9.93954L22.0606 7.0002L23.8637 5.19689C23.8637 5.19688 23.8637 5.19687 23.8637 5.19685C24.2535 4.8071 24.7821 4.58813 25.3333 4.58813C25.8846 4.58813 26.4132 4.80711 26.803 5.19689C27.1928 5.58667 27.4118 6.11533 27.4118 6.66656C27.4118 7.21779 27.1928 7.74645 26.803 8.13623Z"
      fill="white"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
