<template>
  <div class="w-full h-full p-6">
    <h1 class="text-[24px] leading-[40px] font-bold pb-6">{{ formHeader }}</h1>
    <div class="flex flex-col gap-y-6">
      <TextInput
        v-model="payload.fullname"
        label="Name*"
        class="w-full"
        placeholder="None"
        disabled-classes="disabled:text-[#99A4B2] disabled:border-[#D3D8DE] disabled:bg-[#F0F2F4]"
        disabled
      />
      <TextInput
        v-model="cg"
        label="CG*"
        class="w-full"
        placeholder="None"
        disabled-classes="disabled:text-[#99A4B2] disabled:border-[#D3D8DE] disabled:bg-[#F0F2F4]"
        disabled
      />
      <div>
        <label class="font-[12px] leading-[22px] mb-[4px]"
          >Instagram Handle (optional)</label
        >
        <input
          v-model="payload.igHandle"
          :class="[inputStyleClass, focusStyleClass()]"
        />
        <div v-if="notValidIgHandle" class="text-[#DA0000] text-[12px] italic">
          Invalid instagram handle
        </div>
      </div>
      <div class="relative">
        <label class="font-[12px] leading-[22px] mb-[4px]"
          >Message* (max 2200 char)</label
        >
        <textarea
          v-model="payload.message"
          :class="[textareaClass, focusStyleClass()]"
          rows="1"
          maxlength="2200"
        >
        </textarea>
        <div
          v-if="characterCount <= 100"
          class="absolute bottom-[8px] right-[12px] font-[10px] leading-[22px] opacity-50"
        >
          {{ characterCount }} characters left
        </div>
      </div>
      <TextInput
        v-model="year"
        label="Year*"
        class="w-full"
        placeholder="None"
        disabled-classes="disabled:text-[#99A4B2] disabled:border-[#D3D8DE] disabled:bg-[#F0F2F4]"
        disabled
      />
      <FileUploader @set-file-and-url="setFileAndUrl" />
      <Toggle v-model="isAnon" class="justify-between w-full">
        <template #left> Post as anonymous?</template>
      </Toggle>
      <div class="flex flex-row justify-center">
        <BaseButton
          half-width
          class="button border-2 border-solid"
          :style="{
            'background-color': currBoard.themeColour,
            'border-color': currBoard.themeColour,
            color: currBoard.themeTextColour,
          }"
          solid-classes="text-black font-semibold rounded-[24px]"
          disabled-classes="disabled:text-[#99A4B2] disabled:border-[#D3D8DE] disabled:bg-[#F0F2F4]"
          :disabled="payload.message === undefined || notValidIgHandle === true"
          data-event-tracking="post-message"
          @click="selectMethod()"
        >
          {{ submitButton }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import TextInput from "@/components/design/TextInput";
import BaseButton from "@/components/design/BaseButton";
import FileUploader from "./FileUploader.vue";
import Toggle from "./Toggle.vue";
export default {
  components: {
    TextInput,
    Toggle,
    BaseButton,
    FileUploader,
  },
  data() {
    return {
      postId: "",
      cg: "",
      textareaHeight: "auto",
      year: "",
      payload: {
        igHandle: "",
        message: "",
      },
      isAnon: false,
      filesInfoList: [],
      notValidIgHandle: false,
    };
  },
  computed: {
    ...mapState("board", [
      "currBoard",
      "currPost",
      "formHeader",
      "submitButton",
    ]),
    ...mapState("user", ["token"]),
    ...mapGetters({
      originalPayload: "profile/payload",
      cgName: "profile/cgName",
    }),
    characterCount() {
      const messageLength =
        this.payload.message === undefined ? 0 : this.payload.message.length;
      return 2200 - messageLength;
    },
    textareaClass() {
      return [
        "whitespace-pre-line",
        "w-full",
        "min-h-[70px]",
        "px-2",
        "py-3",
        "bg-[#F8F9F9]",
        "border",
        "border-[#B3B3B3]",
        "rounded-lg",
        "resize-none",
        "text-[16px]",
        "leading-[22px]",
        "h-[200px]",
        "focus:bg-[#ffffff]",
        "outline-none",
        "shadow-none",
        "max-h-[300px]",
        "overflow-y-auto",
      ].join(" ");
    },
    inputStyleClass() {
      return [
        "w-full",
        "min-h-[40px]",
        "px-2",
        "py-3",
        "border",
        "rounded-lg",
        "text-[16px]",
        "leading-[22px]",
        this.notValidIgHandle ? "border-[#DA0000]" : "border-[#B3B3B3]",
        "focus:bg-[#ffffff]",
        "outline-none",
        "shadow-none",
      ].join(" ");
    },
  },
  watch: {
    "payload.igHandle": {
      handler() {
        this.checkValidIgHandle();
      },
      immediate: true,
    },
  },
  async created() {
    await this.$store.dispatch("profile/fetchProfile");
    // Store the formHeader,submitButton & currPost in sessionStorage to persist state after reload
    if (this.submitButton && this.formHeader) {
      if (this.submitButton === "Save Changes") {
        sessionStorage.setItem("currPost", JSON.stringify(this.currPost));
      }
      sessionStorage.setItem("submitButton", this.submitButton);
      sessionStorage.setItem("formHeader", this.formHeader);
    } else {
      if (sessionStorage.getItem("submitButton") === "Save Changes") {
        await this.$store.dispatch(
          "board/updateCurrPost",
          JSON.parse(sessionStorage.getItem("currPost"))
        );
      }
      await this.$store.dispatch(
        "board/updateFormHeader",
        sessionStorage.getItem("formHeader")
      );
      await this.$store.dispatch(
        "board/updateSubmitButton",
        sessionStorage.getItem("submitButton")
      );
    }
    this.payload = { ...this.originalPayload };
    this.cg = this.cgName;
    this.year = this.currBoard.yearList[this.currBoard.yearList.length - 1];
    if (this.submitButton === "Save Changes") {
      this.payload.message = this.currPost.message;
      this.isAnon = this.currPost.member == null;
      this.postId = this.currPost.id;
    }
  },
  async mounted() {
    // Because user can only write a message for the newest year, we need to set color to latest year
    await this.$store.dispatch(
      "board/updateCurrBoardYear",
      this.currBoard.yearList[this.currBoard.yearList.length - 1]
    );
  },
  methods: {
    async postMessage() {
      const finalAttachmentList = [];

      if (this.filesInfoList.length) {
        // Uploads files to AWS according to presigned url, IF the file is from local
        const awsAxios = axios.create();
        delete awsAxios.defaults.headers.common.Authorization;

        this.filesInfoList.forEach(async (fileInfo) => {
          if (fileInfo.state === "local") {
            await awsAxios.put(fileInfo.uploadUrl, fileInfo.file, {
              headers: { "Content-Type": "application/octet-stream" },
            });
          }
        });

        this.filesInfoList.forEach((fileInfo) => {
          finalAttachmentList.push(fileInfo.finalUrl);
        });
      }

      let requestBody = {
        igHandle: this.payload.igHandle,
        message: this.payload.message,
        isAnon: this.isAnon,
      };

      if (finalAttachmentList.length > 0) {
        requestBody = {
          ...requestBody,
          attachmentUrlList: finalAttachmentList,
        };
      }

      await axios.request(
        process.env.VUE_APP_API_URL + `/board/${this.currBoard.id}/posts`,
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          data: requestBody,
        }
      );

      this.$router.push("/birthdayboard");
    },

    async editMessage() {
      const finalAttachmentList = [];
      if (this.filesInfoList.length) {
        // Uploads files to AWS according to presigned url, IF the file is from local
        const awsAxios = axios.create();
        delete awsAxios.defaults.headers.common.Authorization;

        this.filesInfoList.forEach(async (fileInfo) => {
          if (fileInfo.state === "local") {
            await awsAxios.put(fileInfo.uploadUrl, fileInfo.file, {
              headers: { "Content-Type": "application/octet-stream" },
            });
          }
        });
        this.filesInfoList.forEach((fileInfo) => {
          finalAttachmentList.push(fileInfo.finalUrl);
        });
      }

      let requestBody = {
        igHandle: this.payload.igHandle,
        message: this.payload.message,
        isAnon: this.isAnon,
      };
      if (finalAttachmentList.length > 0) {
        requestBody = {
          ...requestBody,
          attachmentUrlList: finalAttachmentList,
        };
      }
      await axios
        .request(process.env.VUE_APP_API_URL + `/board/posts/${this.postId}`, {
          method: "put",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          data: requestBody,
        })
        .then(() => {
          this.$router.push("/birthdayboard");
        });
    },
    selectMethod() {
      switch (this.submitButton) {
        case "Post Message":
          return this.postMessage();
        case "Save Changes":
          return this.editMessage();
        default:
      }
    },
    checkValidIgHandle() {
      const instagramHandleRegex = /^[a-zA-Z0-9._]{1,30}$/;
      if (this.payload.igHandle !== "") {
        this.notValidIgHandle = !instagramHandleRegex.test(
          this.payload.igHandle
        );
      } else {
        this.notValidIgHandle = false;
      }
    },
    setFileAndUrl(fileAndUrlObjList) {
      this.filesInfoList = [];
      fileAndUrlObjList.forEach((fileAndUrlObj) => {
        this.filesInfoList.push({
          file: fileAndUrlObj.file,
          uploadUrl: fileAndUrlObj.uploadUrl,
          state: fileAndUrlObj.state,
          finalUrl: fileAndUrlObj.uploadUrl
            .split("/")
            .slice(-1)[0]
            .split("?")[0],
        });
      });
    },
    focusStyleClass() {
      return "focus:border-[" + this.currBoard.themeColour + "]";
    },
  },
};
</script>

<style scoped>
.button {
  font-size: 20px !important;
  line-height: 22px !important;
  padding: 12px 32px !important;
}

@media (min-width: 640px) {
  .button {
    font-size: 24px !important;
    line-height: 22px !important;
    padding: 12px 24px !important;
  }
}
</style>
