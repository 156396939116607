<template>
  <div class="flex flex-col mx-auto w-full">
    <h1>{{ title }}</h1>
    <div
      v-for="(c, i) in content"
      :key="i"
      class="flex mx-auto mb-[24px] lg:px-0"
      :class="width"
    >
      <img v-if="c.type === 'Image'" :src="c.data" alt="" />
      <div v-else-if="c.type === 'Markup'" v-html="c.data" />
      <audio v-else-if="c.type === 'Audio'" controls controlsList="nodownload" oncontextmenu='return false' :src="c.data" class="w-full">
        Your browser does not support the <code>audio</code> element.
      </audio>
      <video v-else-if="c.type === 'Video'" controls controlsList="nodownload" oncontextmenu='return false' :src="c.data" class="w-full">
        Your browser does not support the <code>video</code> element.
      </video>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: 'w-full lg:w-[60%] px-[24px] md:px-[48px]',
    },
  },
  computed: {
    content () {
      return this.resource.content.map(c => this.setComponentType(c)).filter(r => Boolean(r))
    },
    title () {
      return this.resource.title
    },
  },
  methods: {
    setComponentType (component) {
      // things to do: Santize c.Markup -> implementation using native Sanitizer API (Launching in chrome 105)
      if (component.Markup) return { type: 'Markup', data: component.Markup }
      const mediaUrl = component.media?.url || component.link
      if (this.isImage(mediaUrl)) return { type: 'Image', data: mediaUrl }
      if (this.isAudio(mediaUrl)) return { type: 'Audio', data: mediaUrl }
      if (this.isVideo(mediaUrl)) return { type: 'Video', data: mediaUrl }
      return null
    },
    isImage (fileName) {
      const ALLOWED_EXTENSIONS = ['.png', '.jpeg', 'jpg']
      return ALLOWED_EXTENSIONS.filter((ext) => fileName.toLowerCase().includes(ext)).length > 0
    },
    isAudio (fileName) {
      const ALLOWED_EXTENSIONS = ['.mp3', '.wav', 'ogg']
      return ALLOWED_EXTENSIONS.filter((ext) => fileName.toLowerCase().includes(ext)).length > 0
    },
    isVideo (fileName) {
      const ALLOWED_EXTENSIONS = ['.mp4', 'webm']
      return ALLOWED_EXTENSIONS.filter((ext) => fileName.toLowerCase().includes(ext)).length > 0
    },
  },
}
</script>
