<template>
  <div>
    <button class="flex items-center show-menu-button" @click="updateShowCalendarMenu">
      <div
        ref="selectLabel"
        class="text-[20px] leading-[24px] md:text-[24px] md:leading-[32px] font-bold font-proxima"
      >
        {{ currentMonth || 'Select month' }}
      </div>

      <img
        v-if="showCalendarMenu"
        class="ml-[4px] rotate-180"
        src="../../../../public/assets/icons/chevron-down.svg"
        alt=""
      />
      <img v-else class="ml-[4px]" src="../../../../public/assets/icons/chevron-down.svg" alt="" />
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('calendar', ['currentMonth', 'showCalendarMenu']),
  },
  watch: {
    currentMonth () {
      this.$refs.selectLabel.classList.remove('fade')

      setTimeout(() => {
        this.$refs.selectLabel.classList.add('fade')
      }, 0)
    },
  },
  methods: {
    async updateShowCalendarMenu () {
      await this.$store.dispatch('calendar/updateShowCalendarMenu', !this.showCalendarMenu)
    },
  },
}
</script>

<style scoped>
.show-menu-button {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #0b0c0f;
  height: 24px;
}

@media (min-width: 1024px) {
  .show-menu-button {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    height: auto;
    padding-top: 4px;
    padding-bottom: 12px;
  }
}

.fade {
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
