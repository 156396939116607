import axios from "axios";
const INITIAL_STATE = {
  unreadCount: 0,
  updates: [],
  todaysUpdates: [],
  earlierUpdates: [],
  updateTypes: [],
  activeButton: "all",
};

const state = () => INITIAL_STATE;

const mutations = {
  setUnreadCount(state, count) {
    state.unreadCount = count;
  },
  setUpdates(state, updates) {
    state.updates = updates;
  },
  setTodaysUpdates(state, todaysUpdates) {
    state.todaysUpdates = todaysUpdates;
  },
  setEarlierUpdates(state, earlierUpdates) {
    state.earlierUpdates = earlierUpdates;
  },
  setUpdateTypes(state, updateTypes) {
    state.updateTypes = updateTypes;
  },
  setActiveButton(state, activeButton) {
    state.activeButton = activeButton;
  },
};

const actions = {
  async getUpdates({ commit, dispatch, rootState }, type) {
    try {
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/updates/get",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      let typesArray = [{ name: "All", value: "all", priority: 0 }];
      const todaysUpdates = [];
      const earlierUpdates = [];
      let unreadCount = 0;

      if (status !== 200) throw new Error("Failed in getting updates");
      commit("setUpdates", data);
      // extract update type and priority from updates exclude duplicates
      for (let i = 0; i < data.length; i++) {
        // filter updates by date
        const currentDate = new Date();

        // populate todays and earlier for all notifications
        if (type === "all") {
          if (
            new Date(data[i].postedTime).setHours(0, 0, 0, 0) <
            currentDate.setHours(0, 0, 0, 0)
          ) {
            // earlier
            earlierUpdates.push(data[i]);
          } else {
            // todays
            todaysUpdates.push(data[i]);
          }
        } else {
          if (
            new Date(data[i].postedTime).setHours(0, 0, 0, 0) <
              currentDate.setHours(0, 0, 0, 0) &&
            data[i].updateType.updateType === type
          ) {
            // earlier
            earlierUpdates.push(data[i]);
          } else if (data[i].updateType.updateType === type) {
            // todays
            todaysUpdates.push(data[i]);
          }
        }

        // populate update types
        if (
          !typesArray.find(
            (type) => type.value === data[i].updateType.updateType
          )
        ) {
          const capitalized =
            data[i].updateType.updateType.charAt(0).toUpperCase() +
            data[i].updateType.updateType.slice(1);
          typesArray.push({
            name: capitalized,
            value: data[i].updateType.updateType,
            priority: data[i].updateType.priority,
          });
        }

        if (data[i].isNew) {
          unreadCount++;
        }
      }
      typesArray.sort((a, b) => a.priority - b.priority);
      commit("setUpdateTypes", typesArray);
      commit("setEarlierUpdates", earlierUpdates);
      commit("setTodaysUpdates", todaysUpdates);
      commit("setUnreadCount", unreadCount);
    } catch (e) {
      dispatch("toast/error", "Failed to get updates.", {
        root: true,
      });
    }
  },

  async markReadUpdate({ dispatch, rootState }, updateId) {
    try {
      const accessToken = rootState.user.token;
      const { status } = await axios.request(
        process.env.VUE_APP_API_URL + "/updates/mark-read-update",
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            updateId,
          },
        }
      );
      if (status !== 201) throw new Error("Failed in marking read update");
    } catch (e) {
      dispatch("toast/error", "Failed to mark read update.", { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
