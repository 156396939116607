<template>
  <div
    class="relative flex flex-row items-center justify-between h-[64px] px-[24px] lg:px-[48px] xl:px-[204px] z-[20] bg-cover"
    :style="{
      'background-image': `url(${bgImageURL})`,
      'background-color': currBoard.themeColour,
    }"
  >
    <div class="inline-flex items-center">
      <div
        class="flex flex-row items-center cursor-pointer"
        @click="redirectMainBoard"
      >
        <span
          class="font-fofer text-white text-[28px] leading-[20px] font-medium"
          :style="{ color: currBoard.themeTextColour }"
        >
          Happy Birthday,
        </span>
        <div
          v-show="isPageUniqueToBoard"
          class="flex items-center ml-[8px] py-[6px]"
        >
          <div class="mr-[4px]">{{ currBoard.selectName.emoji }}</div>
          <h1
            class="text-[20px] leading-[24px] font-light"
            :style="{ color: currBoard.themeTextColour }"
          >
            {{ currBoard.name }}
          </h1>
        </div>
      </div>
      <BoardSelect v-if="showBoardChange" class="ml-4" />
    </div>
    <img
      src="../../../../public/assets/icons/board/hamburger.svg"
      class="h-[24px] w-[24px] cursor-pointer"
      :class="{
        'black-hamburger': currBoard.name === 'SPL' && currBoard.year === 2024,
        'brown-hamburger': currBoard.name === 'SPG' && currBoard.year === 2024,
      }"
      alt="menu logo"
      @click="openSideBar"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import BoardSelect from "@/components/Board/Input/BoardSelect";

export default {
  components: {
    BoardSelect,
  },
  props: {
    showBoardChange: {
      type: Boolean,
      default: false,
    },
    isPageUniqueToBoard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowWidth: 0,
    };
  },
  computed: {
    ...mapState("board", ["boards", "currBoard"]),
    bgImageURL() {
      return this.getBgImageURL();
    },
  },
  created() {
    this.setWindowWidth();
    window.addEventListener("resize", this.setWindowWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.setWindowWidth);
  },
  methods: {
    openSideBar() {
      this.$store.dispatch("board/updateShowSideBar", true);
    },
    redirectMainBoard() {
      this.$store.dispatch("board/updateReadFilterValue", "all");
      this.$store.dispatch("board/updateMyZoneFilterValue", "all");
      this.$router.push("/birthdayboard");
    },
    getBgImageURL() {
      if (this.windowWidth < 768) {
        return this.currBoard.navbarBackground.mobile;
      } else if (this.windowWidth >= 768 && this.windowWidth < 1024) {
        return this.currBoard.navbarBackground.tablet;
      } else if (this.windowWidth >= 1024) {
        return this.currBoard.navbarBackground.desktop;
      }
    },
    setWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
<style scoped>
.black-hamburger {
  filter: invert(1);
}

.brown-hamburger {
  filter: brightness(0) saturate(100%) invert(43%) sepia(10%) saturate(1000%)
    hue-rotate(351deg) brightness(96%) contrast(95%);
}
</style>
