<template>
  <master-layout>
    <div class="flex-1 max-w-[552px] min-h-[calc(var(--app-height))] mx-auto">
      <form ref="form" @submit.prevent="submit">
        <div class="sm:py-[64px] py-0 flex justify-center items-start">
          <TextTile class="max-w-[552px] w-full py-[32px] px-[24px] tile">
            <h1 class="text-[24px] leading-[32px] font-bold mb-[24px]">
              Change Password
            </h1>

            <div>
              <PasswordInput
                v-model="currentPassword"
                placeholder="Enter current password"
                label="Current password"
                :error="errors.currentPassword"
              />
            </div>

            <div>
              <PasswordInput
                v-model="newPassword"
                placeholder="Enter new password"
                label="New password"
                :error="errors.newPassword"
              />
            </div>

            <PasswordConditions
              :has-eight-characters="hasEightCharacters"
              :has-one-lower-case="hasOneLowerCase"
              :has-one-upper-case="hasOneUpperCase"
              :has-one-number-or-symbol="hasOneNumberOrSymbol"
            />

            <div>
              <PasswordInput
                v-model="confirmPassword"
                placeholder="Re-enter new password"
                :error="passwordsDoNotMatch ? 'Passwords do not match' : ''"
                label="Confirm new password"
              />
            </div>

            <BaseButton
              :disabled="!canSave || loading"
              style="line-height: 24px"
              solid-classes="bg-[#6245F4] text-white hover:bg-[#4959f0]"
              full-width
              @click="submit"
            >
              {{ loading ? "Loading..." : "Save Changes" }}
            </BaseButton>
          </TextTile>
        </div>
      </form>
    </div>
  </master-layout>
</template>

<script>
import BaseButton from '@/components/design/BaseButton.vue'
import PasswordInput from '@/components/design/PasswordInput.vue'
import TextTile from '@/components/design/TextTile.vue'
import PasswordConditions from '@/components/common/PasswordConditions'
import ValidatesPassword from '@/mixins/ValidatesPassword'

export default {
  components: {
    BaseButton,
    TextTile,
    PasswordInput,
    PasswordConditions,
  },

  mixins: [ValidatesPassword],

  data () {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      loading: false,
      errors: {
        currentPassword: null,
        newPassword: null,
      },
    }
  },
  async ionViewWillEnter () {
    await this.$store.dispatch("updates/getUpdates", "all");
  },
  methods: {
    async submit () {
      this.loading = true

      try {
        await this.$axios.put('/profile/change-password', {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        })
        this.$store.dispatch('toast/show', 'You have successfully changed your password.')
        this.currentPassword = ''
        this.newPassword = ''
        this.confirmPassword = ''
      } catch (e) {
        let msg = e.message
        if (e.response && e.response.data && e.response.data.message) {
          msg =
            e.response.data.message.length && Array.isArray(e.response.data.message)
              ? e.response.data.message[0]
              : e.response.data.message
        }
        this.error = true
        this.$store.dispatch('toast/error', msg, { root: true })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.tile {
  @media (max-width: 552px) {
    box-shadow: none !important;
  }
}
</style>
