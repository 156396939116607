<template>
  <div class="cards" :data-event-tracking="dataEventTracking">
    <DraggableCard
      v-for="(card, index) in cards"
      ref="cards"
      :key="index"
      :is-section="isSection"
      :card="card"
      :is-current="index === activeIndex"
      :country-name="countryNames && countryNames[index] ? countryNames[index] : ''"
      :alt-text="altText"
      :drag-horizontal-distance="dragHorizontalDistance"
      :drag-vertical-distance="dragVerticalDistance"
      @cardAccepted="$emit('cardAccepted')"
      @cardRejected="$emit('cardRejected')"
      @cardSkipped="$emit('cardSkipped')"
      @handleClickImage="$emit('handleClickImage')"
      @hideCard="hideACard"
      @increaseIndex="$emit('increaseIndex')"
      @pickCard="pickCard"
      @releaseCard="releaseCard"
    />
  </div>
</template>

<script>
import DraggableCard from '@/components/hogcfd/DraggableCard'

export default {
  name: 'DraggableCardsStack',
  components: { DraggableCard },
  props: {
    cards: {
      type: Array,
      required: true,
    },
    dragHorizontalDistance: {
      type: Number,
      default: 200,
    },
    dragVerticalDistance: {
      type: Number,
      default: -50,
    },
    countryNames: { type: Array, required: false, default: () => [''] },
    isSection: { type: String, required: false, default: '' },
    altText: { type: String, required: false, default: '' },
  },
  data () {
    return {
      refCards: [],
      activeIndex: 0,
    }
  },
  computed: {
    dataEventTracking () {
      return this.cards[this.activeIndex].tracking
    },
  },
  mounted () {
    const self = this
    this.$refs.cards.forEach(function (item) {
      self.refCards.push(item)
    })
  },
  methods: {
    PeekOut () {
      this.refCards[0].manualDrag()
    },
    moveToOrigin () {
      this.activeIndex = 0
      this.refCards.forEach(function (item, index) {
        setTimeout(function (y) {
          item.moveToOrigin()
        }, 30 * index)
      })
    },
    movePrevToOrigin () {
      if (this.activeIndex === 0) return
      this.activeIndex--
      this.refCards[this.activeIndex].moveToOrigin()
    },
    moveToOut (index, direction) {
      this.refCards[index].moveToOut(direction)
      if (index < this.refCards.length) {
        this.activeIndex = index + 1
      }
    },
    hideACard (isDelayed = false) {
      if (this.activeIndex < this.refCards.length && !isDelayed) {
        this.activeIndex = this.activeIndex + 1
      }
    },
    pickCard () {
      this.$emit('pickCard')
    },
    releaseCard (param) {
      this.$emit('releaseCard', param)
    },
  },
}
</script>

<style scoped>
.cards {
  position: relative;
  display: flex;
  width: 268px;
  height: 268px;
}

@media (max-width: 768px) {
  .cards {
    position: relative;
    display: flex;
    width: 240px;
    height: 240px;
  }
}
</style>
