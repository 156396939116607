<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
    d="M18.6665 24L10.6665 16L18.6665 8L20.5332 9.86667L14.3998 16L20.5332 22.1333L18.6665 24Z"
    :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#000000',
    },
  },
}
</script>
