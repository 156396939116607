<template>
  <div class="relative mb-[48px]">
    <TextInput
      v-model="localValue"
      class="w-full"
      v-bind="{ inputClasses, labelClasses, helperClasses, errorMessageClasses, type, placeholder, error, label }"
    />
    <img v-if="passwordShown" src="../../../public/assets/icons/register/eye-hide.svg" class="absolute top-[32px] right-2 cursor-pointer" @click="togglePassword" />
    <img v-else src="../../../public/assets/icons/register/eye-hide.svg" class="absolute top-[32px] right-2 cursor-pointer" @click="togglePassword" />
  </div>
</template>

<script>
import TextInput from '@/components/design/TextInput.vue'
import HasLocalValue from '@/mixins/HasLocalValue'
import HasInputClassProps from '@/mixins/HasInputClassProps'

export default {
  components: {
    TextInput,
  },

  mixins: [HasLocalValue, HasInputClassProps],

  props: {
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    inputClasses: {
      type: [String, Array, Object],
      default: null,
    },
  },

  data() {
    return {
      passwordShown: false,
    }
  },

  computed: {
    type() {
      return this.passwordShown ? 'text' : 'password'
    },
  },

  methods: {
    togglePassword() {
      this.passwordShown = !this.passwordShown
    },
  },
}
</script>
