import axios from "axios";

const state = () => ({
  isSelectingPhotos: false,
  photos: [],
  selectedPhotos: [],
  isShowQr: false,
  loading: false,
});

const getters = {
  isPhotoSelected: (state) => (photo) => {
    return state.selectedPhotos.includes(photo);
  },
  isPhotosEmpty: (state) => {
    return state.photos.length === 0;
  },
};

const mutations = {
  setIsSelectingPhotos(state, value) {
    state.isSelectingPhotos = value;
  },
  setIsShowQr(state, value) {
    state.isShowQr = value;
  },
  setPhotos(state, photos) {
    state.photos = photos;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  addToSelectedPhotos(state, photo) {
    state.selectedPhotos.push(photo);
  },
  removeFromSelectedPhotos(state, photo) {
    state.selectedPhotos = state.selectedPhotos.filter(
      (item) => item !== photo
    );
  },
  resetSelectedPhotos(state) {
    state.selectedPhotos = [];
  },
};

const actions = {
  updateIsSelectingPhotos({ commit }, value) {
    commit("setIsSelectingPhotos", value);
  },
  updateIsShowQr({ commit }, value) {
    commit("setIsShowQr", value);
  },
  async fetchPhotos({ commit, dispatch, rootState }) {
    commit("setLoading", true);
    const accessToken = rootState.user.token;
    try {
      const { data: photos } = await axios.get(
        process.env.VUE_APP_API_URL + "/photo/all",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      commit("setPhotos", photos);
    } catch (e) {
      dispatch("toast/error", "Failed to fetch photos.", { root: true });
    } finally {
      commit("setLoading", false);
    }
  },
  toggleSelectedPhoto({ getters, commit }, photo) {
    if (getters.isPhotoSelected(photo))
      commit("removeFromSelectedPhotos", photo);
    else commit("addToSelectedPhotos", photo);
  },
  resetSelectedPhotos({ commit }) {
    commit("resetSelectedPhotos");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
