<template>
  <div>
    <div class="mb-[12px]">
      <h2 class="font-semibold text-[20px]">{{ name }}</h2>
      <p v-if="description" class="font-normal text-[16px]">
        {{ description }}
      </p>
    </div>
    <div class="flex overflow-x-auto">
      <div
        v-for="(asset, index) in assets"
        :key="index"
        class="relative mr-[16px] w-[240px] min-w-[240px]"
      >
        <a
          class="absolute rounded-full top-[12px] right-[12px]"
          :href="downloadItem(asset)"
          :data-event-tracking="asset.dataEventTracking"
        >
          <img class="block" src="../../../public/assets/icons/e-invites/download-white.svg" alt="" />
        </a>
        <img :src="asset.mediaPreview" :alt="asset.dataEventTracking" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    assets: {
      type: Array,
      required: true,
    },
  },
  methods: {
    downloadItem (item) {
      const iosLink = item.iosLink
      const download = item.downloadLink
      if (!iosLink) {
        return download
      }
      return this.isIOS ? iosLink : download
    },
  },
}
</script>
