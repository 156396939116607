<template>
  <div class="flex flex-col mx-auto">
    <h1 class="text-[24px] font-bold mb-[24px]">Please enter password</h1>
    <input
      v-model="password"
      class="h-[40px] border border-[#637083] rounded-[8px] text-[16px] px-[12px] text-[#373E49]"
      :class="{
        'border-[#DA0000]': isInvalid,
        'border-[#637083]': !isInvalid,
      }"
      type="password"
      placeholder="Password"
      @keydown.enter="enterPassword"
    />
    <div v-if="isInvalid" class="text-[#DA0000] mt-[4px] ml-[12px] text-[12px] italic">
      Please enter the correct password
    </div>
    <button
      :disabled="isButtonDisabled"
      class="bg-[#6245F4] text-white rounded-[12px] mt-[24px] border-[1.5px] px-[16px] py-[8px]"
      :class="{
        'bg-[#D3D8DE]': isButtonDisabled,
        'cursor-default': isButtonDisabled,
        'border-[#D3D8DE]': isButtonDisabled,
        'hover:bg-[#4959f0]': !isButtonDisabled,
      }"
      @click="enterPassword"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    resStatus: {
      type: Number,
      required: true,
      default: 0,
    },
    buttonText: {
      type: String,
      required: true,
      default: 'Access Resource',
    },
  },
  data () {
    return {
      password: '',
    }
  },
  computed: {
    isButtonDisabled () {
      return this.isLoading || this.password === ''
    },
    isInvalid () {
      return this.resStatus === 401
    },
  },
  methods: {
    enterPassword () {
      this.$emit('enter-password', this.password)
    },
  },
}
</script>
