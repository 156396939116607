<template>
  <div>
    <form
      v-if="!modelValue || !modelValue.length || editMode"
      class="text-left block w-[calc(100%-40px) mb-[32px]"
      @submit.prevent="sendOtp"
    >
      <InputLabel for="phone-number">Mobile number</InputLabel>

      <div class="flex">
        <vue-tel-input
          class="w-full bg-[#F8F9F9] border-[1px] border-solid border !border-r-0 !rounded-r-[0px] !rounded-l-[8px] h-[42px]"
          :dropdown-options="{
            showDialCodeInList: true,
            showDialCodeInSelection: true,
            showFlags: false,
            showSearchBox: true,
            width: '300px',
          }"
          default-country="SG"
          :preferred-countries="['SG','US','AU']"
          :input-options="{
            placeholder: '',
            styleClasses: 'bg-[#F8F9F9] !pl-0',
            type: 'tel',
            autofocus: true,
          }"
          :disabled="editMode && loading"
          @input="(number, phoneObject) => handleValue(phoneObject)"
        />
        <button
          class="
            text-white
            bg-[#6a77f3]
            w-[65px]
            px-[8px]
            py-[8px]
            rounded-r-[8px]
            hover:bg-[#4959f0]
            disabled:opacity-70
            disabled:pointer-events-none
          "
          :disabled="editMode && loading"
        >
          Verify
        </button>
      </div>

      <span class="text-[16px] text-[#373E49] leading-[24px] mt-[4px] block">
        A verification code will be sent to this number.
      </span>
    </form>

    <div
      v-else
      :class="{ 'mb-[32px]': !pendingVerification }"
    >
      <InputLabel>Mobile number</InputLabel>

      <div
        class="
          w-[calc(100%-40px)
          flex
          items-center
          justify-between
          bg-[#EBF3FF]
          border
          border-[#D3D8DE]
          rounded-[12px]
          px-[12px]
          py-[8px]
        "
      >
        <span>+{{ countryCode }} {{ modelValue }}</span>

        <span
          v-if="pendingVerification"
          class="text-[#909f9f] text-sm font-bold"
        >
          Pending
        </span>

        <span
          v-else
          class="text-green-500 text-sm font-bold"
        >
          Verified
        </span>
      </div>

      <span class="text-[16px] text-[#373E49] leading-[24px] mt-[4px] block">
        <span v-if="pendingVerification">A SMS has been sent to this number. </span>
        <a
          class="text-blue-500 inline-block hover:underline"
          href="#"
          @click.prevent="editMode = true"
        >
          Click here
        </a>
        <span>to edit this field.</span>
      </span>
    </div>

    <div
      v-if="pendingVerification && !editMode"
      class="text-left mt-4 mb-[32px]"
    >
      <form class="flex" @submit.prevent="verifyPhoneNumber">
        <input
          id="otp"
          v-model="otp"
          type="text"
          placeholder="Enter OTP here"
          class="flex-1 bg-[#F8F9F9] w-[calc(100%-40px)] px-[12px] py-[8px] border border-r-0 rounded-l-[8px]"
          :class="error ? 'border-red-500' : 'border-[#D3D8DE]'"
          :disabled="pendingVerification && !editMode && loading"
        />
        <button
          class="
            text-white
            bg-[#6a77f3]
            w-[96px]
            px-[16px]
            py-[8px]
            rounded-r-[8px]
            hover:bg-[#4959f0]
            disabled:opacity-70
            disabled:pointer-events-none
          "
          :disabled="pendingVerification && !editMode && loading"
        >
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import InputLabel from '@/components/design/InputLabel'
import ToastsFieldErrors from '@/mixins/ToastsFieldErrors'

export default {
  components: InputLabel,
  mixins: [ToastsFieldErrors],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    countryCode: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      otp: '',
      pendingVerification: false,
      editMode: false,
      loading: false,
      error: false,
    }
  },
  methods: {
    handleValue (phoneObject) {
      this.$emit('update:modelValue', phoneObject.nationalNumber)
      this.$emit('setCountryCode', phoneObject.countryCallingCode)
    },
    async sendOtp () {
      this.loading = true
      try {
        await this.$axios.post('/profile/send-otp', { contactNumber: this.modelValue, countryCode: this.countryCode })
        this.editMode = false
        this.pendingVerification = true
        this.error = false
      } catch (error) {
        this.toastFieldErrors(error)
      } finally {
        this.loading = false
      }
    },
    async verifyPhoneNumber () {
      this.loading = true
      try {
        await this.$axios.post('/profile/verify-phone-number', { countryCode: this.countryCode, contactNumber: this.modelValue, otp: this.otp })
        this.pendingVerification = false
        this.error = false
      } catch (error) {
        this.toastFieldErrors(error)
      } finally {
        this.loading = false
      }
    },
    async inputRegisterForm (key, modelValue) {
      await this.$store.dispatch('form/inputRegisterForm', { key, modelValue })
    },
    async validateNumber (phoneObject) {
      await this.$store.dispatch('form/validateContactNumber', phoneObject.valid)
    },
  },
}
</script>
