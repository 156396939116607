import axios from "axios";
const INITIAL_STATE = {
  isLoading: true,
  milestones: [],
  tracks: [],
  filteredTrackName: "",
  showDetailsPage: false
};

const state = () => INITIAL_STATE;

const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setMilestones(state, milestones) {
    state.milestones = milestones;
  },
  setTracks(state, tracks) {
    state.tracks = tracks;
  },
  setFilteredTrackName(state, filteredTrackName) {
    state.filteredTrackName = filteredTrackName;
  },
  setShowDetailsPage(state, showDetailsPage) {
    state.showDetailsPage = showDetailsPage;
  }
};

const actions = {
  async getMilestones({ commit, dispatch, rootState }) {
    try {
      commit("setIsLoading", true);
      const accessToken = rootState.user.token;
      const { status, data } = await axios.get(
        process.env.VUE_APP_API_URL + "/milestones/get-member-milestones",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (status !== 200) throw new Error("Failed in getting milestones");
      commit("setTracks", data);
      commit("setIsLoading", false);
    } catch (e) {
      dispatch("toast/error", "Failed to get milestones.", {
        root: true,
      });
    }
  },
  updateFilteredTrackName({ commit }, filteredTrackName) {
    commit("setFilteredTrackName", filteredTrackName);
  },
  updateMilestones({ commit }, milestones) {
    milestones.sort((a, b) => a.priority - b.priority);
    commit("setMilestones", milestones);
  },
  updateShowDetailsPage({ commit }, showDetailsPage) {
    commit('setShowDetailsPage', showDetailsPage);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
