<template>
  <Collapsible v-model="localValue" @opened="$emit('opened')" @closed="$emit('closed')">
    <template #trigger="{ isOpen, toggle }">
      <div class="w-full header py-[16px] flex cursor-pointer" @click="toggle">
        <h3 class="text-[16px] leading-[20px] font-bold font-[#373E49]">
          {{ title }}
        </h3>
        <div class="ml-auto flex">
          <h5 class="text-[16px] leading-[20px] font-bold text-[#7C8A9C] mr-[8px]">
            {{ selected }}
          </h5>
          <img
            src="../../../../public/assets/icons/chevron-down.svg"
            alt=""
            class="rotate-[-90deg] theme-accordian-icon"
            :class="{ 'rotate-0 theme-accordian-icon': isOpen }"
          />
        </div>
      </div>
    </template>
    <template #collapsible="{ isOpen }">
      <div v-show="isOpen" class="w-full body bg-[#ffffff] md:pb-0">
        <slot />
      </div>
    </template>
  </Collapsible>
</template>

<script>
import Collapsible from '@/components/design/Collapsible'
import HasLocalValue from '@/mixins/HasLocalValue'

export default {
  components: {
    Collapsible,
  },

  mixins: [HasLocalValue],

  props: {
    title: {
      type: String,
      required: true,
    },
    selected: {
      type: Array(String),
      required: true,
    },
    initialOpen: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      localValue: true,
    }
  },
}
</script>
