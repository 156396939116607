export default {
    props: ['modelValue'],
  
    data() {
      return {
        localValue: this.modelValue,
      }
    },
  
    watch: {
      localValue(newValue) {
        this.$emit('update:modelValue', newValue)
      },
      modelValue(newValue) {
        this.localValue = newValue
      },
    },
  }