<template>
  <div>
    <router-link :to="linkto" class="cursor-pointer flex items-center">
      <ChevronIcon v-if="!forward" fill="#21252C" class="w-[24px] h-[24px]" />
      <p
        class="text-[20px] leading-[24px] font-source-sans-3 underline underline-offset-8"
        :class="fontBold"
      >
        {{ text }}
      </p>
      <ChevronIcon v-if="forward" fill="#21252C" class="w-[24px] h-[24px] rotate-180" />
    </router-link>
  </div>
</template>

<script>
import ChevronIcon from '../../../public/assets/icons/hogcfd/ChevronIcon'

export default {
  components: { ChevronIcon },
  props: {
    text: {
      type: String,
      required: true,
    },
    linkto: {
      type: String,
      required: true,
    },
    forward: {
      type: Boolean,
      default: false,
    },
    isBold: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fontBold () {
      return this.isBold ? 'font-bold' : ''
    },
  },
}
</script>
