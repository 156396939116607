<template>
  <master-layout>
    <div
      class="w-full flex justify-center px-[24px] lg:px-0 min-h-[calc(var(--app-height)-88px)]"
    >
      <Loading v-if="isLoadingWhitelist" />
      <div v-else-if="resource" class="flex flex-col w-[500px] mt-[48px]">
        <p
          class="font-bold text-center text-[24px] leading-[40px] tracking-[-1%]"
        >
          Mother's Day Message 2024
        </p>
        <div class="mt-[24px] relative">
          <img
            class="hidden lg:block rounded-xl"
            src="../../../public/assets/images/md2024/md2024-3.png"
          />
          <img
            class="block lg:hidden rounded-xl"
            src="../../../public/assets/images/md2024/md2024-3.png"
          />
          <ProtectedContent
            :resource="resource"
            class="absolute bottom-[8px] lg:bottom-[32px]"
            width="w-full lg:w-[435px] px-[16px] lg:px-[48px] mb-[0px]"
          />
        </div>
      </div>
      <PasswordEntry
        v-else
        class="pt-[36px]"
        :is-loading="isLoadingPassword"
        :res-status="resStatus"
        @enter-password="getResourceWithPassword"
      />
    </div>
  </master-layout>
</template>

<script>
import { mapState } from "vuex";
import Loading from "@/components/common/LoadingState.vue";
import PasswordEntry from "@/components/ProtectedPage/PasswordEntry.vue";
import ProtectedContent from "@/components/ProtectedPage/ProtectedContent.vue";
import axios from "axios";

export default {
  components: {
    Loading,
    PasswordEntry,
    ProtectedContent,
  },
  data() {
    return {
      isLoadingPassword: false,
      isLoadingWhitelist: true,
      resource: undefined,
      resStatus: 0,
    };
  },
  computed: {
    ...mapState("user", ["token"]),
  },
  async ionViewWillEnter() {
    try {
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "/cms/protected/md2024-3/whitelist",
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.resource = response.data;
    } catch (e) {
      if (
        e.response &&
        (e.response.status === 403 || e.response.status === 500)
      ) {
        this.$router.push({ path: "/" });
      }
    } finally {
      this.isLoadingWhitelist = false;
    }
  },
  methods: {
    async getResourceWithPassword(password) {
      if (this.isLoadingPassword) return;
      this.isLoadingPassword = true;
      try {
        const response = await axios.request(
          process.env.VUE_APP_API_URL + "/cms/protected",
          {
            method: "post",
            data: { password, slug: "md2024-3" },
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.resource = response.data;
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.resStatus = e.response.status;
        }
      } finally {
        this.isLoadingPassword = false;
      }
    },
  },
};
</script>
