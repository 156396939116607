<template>
  <div
    v-on-clickaway="closeOptionModal"
    class="bg-[#ffffff] w-full rounded-t-[24px] px-[32px] py-[24px] md:w-[600px] md:rounded-[8px] md:px-[40px] md:py-[40px] drop-shadow-2xl"
  >
    <div class="h-full flex flex-col justify-between">
      <div class="flex flex-row justify-between mb-[32px]">
        <p class="text-[20px] md:text-[24px] leading-[24px] font-bold">
          {{ isDeleting ? "Delete Message" : "More Options" }}
        </p>
        <div
          class="cursor-pointer"
          @click="closeOptionModal"
          @mouseover="isMouseOverCloseIcon = true"
          @mouseout="isMouseOverCloseIcon = false"
        >
          <CloseIcon :fill="closeIconFill" />
        </div>
      </div>

      <div v-if="!isDeleting" class="flex flex-col gap-[16px]">
        <MessageOptionComponent
          v-if="currPost.isMe"
          text="Edit message"
          :is-edit="true"
          @click="editMessage"
        />
        <MessageOptionComponent
          v-if="isCurrBoardAdmin"
          :isPin="true"
          :isPinned="currPost.isPinned"
          :text="!currPost.isPinned ? 'Pin message' : 'Unpin message'"
          @click="!currPost.isPinned ? pinMessage() : unpinMessage()"
        />
        <MessageOptionComponent
          v-if="isCurrBoardAdmin"
          :isHide="true"
          :isHidden="currPost.isHidden"
          :text="!currPost.isHidden ? 'Hide message' : 'Unhide message'"
          @click="!currPost.isHidden ? hideMessage() : unhideMessage()"
        />
        <MessageTagComponent
          v-if="isCurrBoardAdmin"
          :tags="tags"
          @tags-updated="handleTagsUpdated"
        />
        <MessageOptionComponent
          v-if="currPost.isMe"
          text="Delete message"
          :is-delete="true"
          @click="setIsDeleting(true)"
        />
      </div>

      <div v-else>
        <p class="text-[16px] leading-[24px]">
          Are you sure? Deleted message can not be restored.
        </p>
        <div class="flex flex-row px-[30px] justify-between pt-[32px]">
          <button
            class="px-[32px] py-[12px] bg-[#ffffff] rounded-[100px] border-[1px] border-solid text-[20px]"
            :style="{ 'border-color': currBoard.themeColour }"
            @click="closeOptionModal"
          >
            Cancel
          </button>
          <button
            class="px-[32px] py-[12px] rounded-[100px] border-[1px] border-solid text-[20px] font-bold"
            :style="{
              'background-color': currBoard.themeColour,
              'border-color': currBoard.themeColour,
              color: currBoard.themeTextColour,
            }"
            @click="deleteMessage"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import MessageOptionComponent from "@/components/Board/Card/MessageOptionComponent";
import MessageTagComponent from "@/components/Board/Card/MessageTagComponent";
import CloseIcon from "../../../../public/assets/icons/board/CloseIcon";

export default {
  components: { MessageOptionComponent, MessageTagComponent, CloseIcon },
  data() {
    return {
      isDeleting: false,
      isMouseOverCloseIcon: false,
      tags: [],
    };
  },
  computed: {
    ...mapState("board", ["currBoard", "currPost", "isCurrBoardAdmin"]),
    ...mapState("user", ["token"]),
    closeIconFill() {
      return this.isMouseOverCloseIcon ? this.currBoard.themeColour : "#000000";
    },
  },
  async created() {
    this.isFavouriteActive = this.currPost.isFavouriteActive;
    this.isReadActive = this.currPost.isReadActive;
    this.isPostRead = this.currPost.isRead;
    this.isPostFavourited = this.currPost.isFavourite;
    if (this.isCurrBoardAdmin) {
      const { data } = await axios.get(
        process.env.VUE_APP_API_URL +
          `/board/${this.currPost.boardId}/posts/${this.currPost.id}/tags`,
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.tags = data;
    }
  },
  methods: {
    closeOptionModal() {
      this.$emit("close");
    },
    setIsDeleting(isDeleting) {
      this.isDeleting = isDeleting;
    },
    editMessage() {
      this.$store.dispatch("board/updateFormHeader", "Save Changes");
      this.$store.dispatch("board/updateSubmitButton", "Save Changes");
      this.$router.push({ path: "/birthdayboard/message", replace: true });
      this.$emit("close");
    },
    async deleteMessage() {
      await axios.request(
        process.env.VUE_APP_API_URL +
          `/board/${this.currPost.boardId}/posts/${this.currPost.id}`,
        {
          method: "delete",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.$emit("close");
      this.$emit("delete-message");
    },
    async pinMessage() {
      await axios.request(
        process.env.VUE_APP_API_URL +
          `/board/${this.currPost.boardId}/posts/${this.currPost.id}/pin`,
        {
          method: "patch",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.$emit("close");
      this.$emit("pin-message");
    },
    async unpinMessage() {
      await axios.request(
        process.env.VUE_APP_API_URL +
          `/board/${this.currPost.boardId}/posts/${this.currPost.id}/unpin`,
        {
          method: "patch",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.$emit("close");
      this.$emit("unpin-message");
    },
    async hideMessage() {
      await axios.request(
        process.env.VUE_APP_API_URL +
          `/board/${this.currPost.boardId}/posts/${this.currPost.id}/hide`,
        {
          method: "patch",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.$emit("close");
      this.$emit("hide-message");
    },
    async unhideMessage() {
      await axios.request(
        process.env.VUE_APP_API_URL +
          `/board/${this.currPost.boardId}/posts/${this.currPost.id}/unhide`,
        {
          method: "patch",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      this.$emit("close");
      this.$emit("unhide-message");
    },
    handleTagsUpdated({ postId, tags }) {
      this.$emit("handle-tags-updated", { postId, tags });
    },
  },
};
</script>
