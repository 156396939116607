<template>
  <div>
    <BaseSwiper
      :id="baseSwiperId"
      :ref="baseSwiperId"
      v-slot="slotProps"
      :items="events"
      :options="swiperOptions"
      class="mt-1"
      :class="{
        'lg:hidden': events.length < 4,
      }"
    >
      <HomeTile
        class="h-[100px] w-[200px] border-[0px] rounded-lg drop-shadow-tile relative flex flex-col"
        corner-text-class="text-xs lg:text-[16px] sm:px-[12px] font-semibold leading-[20px]"
        image-class="rounded-[12px]"
        :image-src="slotProps.item.imageSrc"
        :corner-text="slotProps.item.cornerText"
        text-wrapper-class="min-h-[48px] text-left pt-[8px]"
      >
        <h1 class="text-[16px] leading-[16px] font-medium">
          {{ slotProps.item.title }}
        </h1>
      </HomeTile>
    </BaseSwiper>

    <div v-if="events.length < 4" class="hidden mt-4 h-[100px] w-[200px] lg:flex lg:items-stretch">
      <HomeTile
        v-for="(item, i) in events"
        :key="i"
        class="desktop-slide flex-1 border-[0px] rounded-lg drop-shadow-tile relative"
        :class="{
          'tablet-slide': events.length < 4,
          'ml-[20px]': i >= 1,
        }"
        corner-text-class="text-xs lg:text-[16px] sm:px-[12px] font-semibold leading-[20px]"
        image-class="rounded-[12px]"
        :image-src="item.imageSrc"
        :corner-text="item.cornerText"
        text-wrapper-class="text-left pt-[8px]"
      >
        <h1 class="text-[16px] leading-[16px] font-medium">
          {{ item.title }}
        </h1>
      </HomeTile>
    </div>
  </div>
</template>

<script>
import BaseSwiper from "@/components/design/BaseSwiper.vue";
import HomeTile from "@/components/design/HomeTile.vue";

export default {
  components: {
    BaseSwiper,
    HomeTile,
  },
  props: {
    baseSwiperId: {
      type: String,
    },
    events: {
      type: Array,
      default: () => [],
    },
    swiperOptions: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    breakpoints() {
      return {
        641: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 3,
          centeredSlides: false,
        },
        1366: {
          slidesPerView: 4,
          centeredSlides: false,
        },
      };
    },
  },
  methods: {
    update() {
      const swiper = this.$refs[this.baseSwiperId];
      swiper.update();
    },
  },
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .tablet-slide {
    max-width: calc(33.3% - 15px);
  }
}

@media (min-width: 1366px) {
  .desktop-slide {
    max-width: calc(25% - 15px);
  }
}
</style>
