<template>
  <div
    class="flex flex-row px-6 py-3 justify-between items-center bg-[#FFF5E4] rounded-[8px] border-[1px] border-[#EFD19F]"
  >
    <p class="text-[16px] leading-[20px]">{{ boardToastMessage }}</p>
    <div class="cursor-pointer" @click="closeToast">
      <CloseIcon fill="#000000" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CloseIcon from "../../../../public/assets/icons/board/CloseIcon.vue";

export default {
  components: {
    CloseIcon,
  },
  computed: {
    ...mapState("board", ["boardToastMessage"]),
  },
  methods: {
    closeToast() {
      this.$store.dispatch("board/updateIsBoardToastOpen", false);
    },
  },
};
</script>
