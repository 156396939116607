<template>
  <div>
    <ion-card
      class="w-[160px] h-[180px] relative"
      :class="
        milestone.state[0].stateName === 'completed'
          ? 'bg-[#FFFFFF]'
          : 'bg-[#F0F0F0]'
      "
    >
      <ion-card-header
        v-if="milestone.state[0].stateName != 'not-started'"
        class="h-[28px] items-center justify-center text-center text-[#262626] font-bold"
        :class="getStatusBG"
        >{{ getStatusText }}</ion-card-header
      >
      <div
        class="flex flex-col justify-center"
        :class="
          milestone.state[0].stateName != 'not-started'
            ? 'h-[calc(100%-28px)]'
            : 'h-full'
        "
      >
        <img
          :src="milestone.state[0].badgeIcon"
          class="w-[80px] h-[80px] mx-auto"
        />
        <h2
          class="pt-[24px] font-bold text-[14px] leading-[20px] text-[#161616] text-center"
        >
          {{ milestone.displayName }}
        </h2>
      </div>
    </ion-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formattedStatus: [
        {
          status: "in-progress",
          text: "IN PROGRESS ⚡",
          statusBg: "bg-[#FFEFB0]",
        },
        {
          status: "completed",
          text: "COMPLETED ⭐",
          statusBg: "bg-[#DBFFEE]",
        },
      ],
    };
  },
  props: {
    milestone: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getStatusText() {
      const status = this.formattedStatus.filter((status) => {
        return status.status == this.milestone.state[0].stateName;
      });
      return status ? status[0].text : "";
    },
    getStatusBG() {
      const status = this.formattedStatus.filter((status) => {
        return status.status == this.milestone.state[0].stateName;
      });
      return status ? status[0].statusBg : "";
    },
  },
};
</script>
