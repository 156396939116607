import { useAuth0 } from "@auth0/auth0-vue";
import { Browser } from "@capacitor/browser";
import { createLogoutUri } from "../utils/redirect-uri";

export default {
    data () {
        const { logout } = useAuth0();
        return {
          logout,
        }
      },
    methods:{
        async logoutUser() {
          if (process.env.VUE_APP_NODE_ENV === "local") {
            localStorage.removeItem('token');
            this.$router.push('/login')
          } else {
            await this.logout({
              logoutParams: {
                returnTo: createLogoutUri(
                  process.env.VUE_APP_PACKAGE_ID,
                  process.env.VUE_APP_AUTH0_DOMAIN
                ),
              },
              openUrl: async (url) =>
                await Browser.open({
                  url,
                  windowName: "_self",
                }),
            });
          }
        },
        
      }
}