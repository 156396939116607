<template>
  <div class="mt-2 flex flex-wrap">
    <div
      v-if="isLeadersOnly"
      class="box-border h-7 p-[4px] mr-2 rounded-lg"
      :class="hasPast ? pastClass : leadersClass"
    >
      <p class="text-[16px] font-semibold leading-[20px] text-center px-1">Leaders</p>
    </div>
    <div v-if="tags.length > 0" class="flex">
      <div v-for="tag in tags" :key="tag.name">
        <div
          v-if="tag.isTag"
          class="box-border h-7 p-[4px] mr-2 rounded-lg"
          :class="[
            {
              'bg-[#D3D8DE] text-[#7C8A9C]': hasPast,
              'bg-[#FEDB00] text-[#21252C]': tag.name === '#HOGC21' && !hasPast,
              'bg-[#D3E5EF] text-[#373E49]': tag.name === 'No FOMO' && !hasPast,
              'bg-[#ECDDFF] text-[#6200DF]': tag.name === 'Youth' && !hasPast,
              'bg-[#CCE0FF] text-[#0013BF]': tag.name === 'UR' && !hasPast,
              'bg-[#E0F5ED] text-[#00795C]': tag.name === 'Pre-Uni' && !hasPast,
              'bg-[#FFE1DF] text-[#FF3D22]': tag.name === 'Students' && !hasPast,
              'bg-[#D7EEFF] text-[#0D99FF]': tag.name === 'Global' && !hasPast,
            }
          ]"
        >
          <p class="text-[16px] font-semibold leading-[20px] text-center px-1">
            {{ tag.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hasPast: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    isLeadersOnly: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      pastClass: 'bg-[#D3D8DE] text-[#7C8A9C]',
      leadersClass: 'bg-[#FCEFE2] text-[#373E49]',
    }
  },
}
</script>

<style scoped>
* {
  font-family: 'proxima-nova';
  font-style: normal;
}
</style>
