<template>
  <div>
    <img src="../../../public/assets/icons/hogcfd/year-stamp.svg" class="relative" />
    <p class="absolute top-[37px] left-[28px] rotate-[-10deg] font-my-type-of-font text-[24px] leading-[32px] font-bold">{{ year }}</p>
  </div>
</template>

<script>
export default {
  props: {
    year: {
      type: String,
      required: true,
    },
  },
}
</script>
