<template>
  <div>
    <p class="font-xtypewriter text-[20px] leading-[28px]">
      Love and Respect,<br />Pastor How<br />Father’s Day {{ year }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    year: {
      type: String,
      default: '',
    },
  },
}
</script>
