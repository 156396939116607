<template>
  <div>
    <form
      v-if="!modelValue || !modelValue.length || editMode"
      class="text-left block w-[calc(100%-40px) mb-[32px]"
      @submit.prevent="changeEmailAddress"
    >
      <InputLabel for="email">Email</InputLabel>

      <div class="flex">
        <input
          id="email"
          :value="modelValue"
          type="text"
          placeholder="@email.com"
          class="
            flex-1
            bg-[#F8F9F9]
            w-[calc(100%-40px)]
            px-[12px]
            py-[8px]
            border
            border-r-0
            rounded-l-[8px]
          "
          :class="error ? 'border-red-500' : 'border-[#D3D8DE]'"
          :disabled="editMode && loading"
          autofocus
          @input="handleValue"
        />
        <button
          class="
            text-white
            bg-[#6a77f3]
            w-[96px]
            px-[16px]
            py-[8px]
            rounded-r-[8px]
            hover:bg-[#4959f0]
            disabled:opacity-70
            disabled:pointer-events-none
          "
          :disabled="editMode && loading"
        >
          Verify
        </button>
      </div>

      <span class="text-[16px] text-[#373E49] leading-[24px] mt-[4px] block">
        A verification code will be sent to this address.
      </span>
    </form>

    <div v-else class="mb-[32px]">
      <InputLabel>Email</InputLabel>

      <div
        class="
          w-[calc(100%-40px)
          flex
          items-center
          justify-between
          bg-[#EBF3FF]
          border
          border-[#D3D8DE]
          rounded-[12px]
          px-[12px]
          py-[8px]
        "
      >
        <span class="truncate">{{ modelValue }}</span>

        <span
          v-if="isVerified"
          class="text-green-500 text-sm font-bold"
        >
          Verified
        </span>

        <span
          v-else
          class="text-[#909f9f] text-sm font-bold"
        >
          Pending
        </span>
      </div>

      <span
        v-if="!editMode && loading"
        class="text-[16px] text-[#373E49] leading-[24px] mt-[4px] block"
      >
        Resending email...
      </span>

      <p v-else class="text-[16px] text-[#373E49] leading-[24px] mt-[4px] mx-0 mb-0">
        <span v-if="!isVerified">A verification code has been sent to this address. </span>
        <a
          class="text-blue-500 inline-block hover:underline"
          href="#"
          @click.prevent="editMode = true"
        >
          Click here
        </a>
        <span>to edit this field. </span>

        <span v-if="!isVerified">
          If you did not receive an email,
          <a
            class="text-blue-500 inline-block hover:underline"
            href="#"
            @click.prevent="resendVerificationEmail"
          >
            resend here
          </a>.
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import InputLabel from '../../components/design/InputLabel'
import ToastsFieldErrors from '@/mixins/ToastsFieldErrors'

export default {
  components: InputLabel,
  mixins: [ToastsFieldErrors],
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    isVerified: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      editMode: false,
      loading: false,
      error: false,
    }
  },
  methods: {
    handleValue (event) {
      this.$emit('update:modelValue', event.target.value)
    },
    async changeEmailAddress () {
      this.loading = true
      try {
        await this.$axios.put('/profile/change-email', { email: this.modelValue })
        this.$emit('unverified')
        this.editMode = false
        this.error = false
      } catch (error) {
        this.toastFieldErrors(error)
      } finally {
        this.loading = false
      }
    },
    async resendVerificationEmail () {
      this.loading = true
      try {
        await this.$axios.post('/profile/resend-verify-email', { email: this.modelValue })
        this.error = false
      } catch (error) {
        this.toastFieldErrors(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
