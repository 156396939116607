<template>
  <svg
    v-if="!isActive"
    class="block"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 6.44444V15.1222V26H23.5714C24.2155 26 24.8332 25.7425 25.2887 25.284C25.7441 24.8256 26 24.2039 26 23.5556V10.1111L19.9286 4H11.4286C10.7845 4 10.1668 4.25754 9.71131 4.71596C9.25587 5.17438 9 5.79614 9 6.44444Z"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.66667 21.333H6.33333C5.59695 21.333 5 21.93 5 22.6663V26.6663C5 27.4027 5.59695 27.9997 6.33333 27.9997H7.66667C8.40305 27.9997 9 27.4027 9 26.6663V22.6663C9 21.93 8.40305 21.333 7.66667 21.333Z"
      fill="white"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 26.6667C8 27.0203 8 28.0001 8 28.0001C8 28.0001 8.97971 28.0001 9.33333 28.0001H14.3413C14.6282 28.0001 14.9074 27.9076 15.1375 27.7363C15.3676 27.5651 15.5363 27.3242 15.6187 27.0494L16.7273 25.4545L17.5292 22.5121C17.6492 22.1081 17.7065 21.6721 17.4545 21.3334C17.2039 20.9947 16.7319 20.7961 16.3105 20.7961L12.7273 20.7273L13.3333 18.0441C13.3332 17.7294 13.2318 17.4231 13.0442 17.1705C12.8567 16.9178 12.5928 16.7322 12.2916 16.641C11.9905 16.5499 11.6679 16.558 11.3717 16.6642C11.0755 16.7703 10.8213 16.969 10.6467 17.2307L8 21.3334V26.6667Z"
      fill="white"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20 4V8.8C20 9.11826 20.1264 9.42348 20.3515 9.64853C20.5765 9.87357 20.8817 10 21.2 10H26"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>

  <svg
    v-else
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 6.44444V15.1222V26H23.5714C24.2155 26 24.8332 25.7425 25.2887 25.284C25.7441 24.8256 26 24.2039 26 23.5556V10.1111L19.9286 4H11.4286C10.7845 4 10.1668 4.25754 9.71131 4.71596C9.25587 5.17438 9 5.79614 9 6.44444Z"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.66667 21.333H6.33333C5.59695 21.333 5 21.93 5 22.6663V26.6663C5 27.4027 5.59695 27.9997 6.33333 27.9997H7.66667C8.40305 27.9997 9 27.4027 9 26.6663V22.6663C9 21.93 8.40305 21.333 7.66667 21.333Z"
      fill="white"
      stroke="#6245F4"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 26.6667C8 27.0203 8 28.0001 8 28.0001C8 28.0001 8.97971 28.0001 9.33333 28.0001H14.3413C14.6282 28.0001 14.9074 27.9076 15.1375 27.7363C15.3676 27.5651 15.5363 27.3242 15.6187 27.0494L16.7273 25.4545L17.5292 22.5121C17.6492 22.1081 17.7065 21.6721 17.4545 21.3334C17.2039 20.9947 16.7319 20.7961 16.3105 20.7961L12.7273 20.7273L13.3333 18.0441C13.3332 17.7294 13.2318 17.4231 13.0442 17.1705C12.8567 16.9178 12.5928 16.7322 12.2916 16.641C11.9905 16.5499 11.6679 16.558 11.3717 16.6642C11.0755 16.7703 10.8213 16.969 10.6467 17.2307L8 21.3334V26.6667Z"
      fill="#6245F4"
    />
    <path
      d="M20 4V8.8C20 9.11826 20.1264 9.42348 20.3515 9.64853C20.5765 9.87357 20.8817 10 21.2 10H26"
      fill="#6245F4"
    />
    <path
      d="M20 4V8.8C20 9.11826 20.1264 9.42348 20.3515 9.64853C20.5765 9.87357 20.8817 10 21.2 10H26L20 4Z"
      stroke="#6245F4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
