<template>
  <div class="rounded-lg bg-[#ffffff] flex flex-col" :class="border">
    <img v-show="imageSrc" :src="imageSrc" alt="" class="pt-4 px-4 rounded-lg" :class="hasPast ? 'fill-[#99A4B2] opacity-[40%]' : ''"/>
    <div class="relative flex items-start rounded-2 p-4 overflow-hidden">
      <EventDateDetails
        class="w-[90px]"
        :has-past="hasPast"
        :start-date="startDate"
        :end-date="endDate"
      />

      <div class="ml-[12px] w-full" :class="hasPast ? 'text-[#7C8A9C]' : 'text-[#0B0C0F]'">
        <h5 class="font-bold text-[16px] leading-[20px]">
          {{ title }}
        </h5>

        <div v-if="description" class="font-normal text-[16px] leading-[24px] mt-2">
          <p v-for="(line, index) in description.split('\n')" :key="index" class="min-h-[24px]">
            {{ line }}
          </p>
        </div>

        <EventTimeLocation
          :has-past="hasPast"
          :event-time="eventTime"
          :event-location="eventLocation"
        />

        <div v-if="link && buttonText" class="items-center font-bold text-base mt-2" :class="hasPast ? 'text-[#7C8A9C]' : 'text-[#6245F4]'">
          <a v-if="isExternal" :href="link" target="_blank" :data-event-tracking="dataEventTracking">
            {{ buttonText }}
          </a>
          <router-link v-else :to="link" :data-event-tracking="dataEventTracking">
            {{ buttonText }}
          </router-link>
        </div>

        <EventTags :has-past="hasPast" :tags="tags" :is-leaders-only="isLeadersOnly" />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import EventDateDetails from '@/components/Calendar/Events/EventDateDetails.vue'
import EventTimeLocation from '@/components/Calendar/Events/EventTimeLocation.vue'
import EventTags from '@/components/Calendar/Events/EventTags.vue'

export default {
  components: {
    EventDateDetails,
    EventTimeLocation,
    EventTags,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
    eventTime: {
      type: String,
      default: null,
    },
    eventLocation: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
    dataEventTracking: {
      type: String,
      default: null,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    imageSrc: {
      type: String,
      default: null,
    },
    isCalledOut: {
      type: Boolean,
      default: false,
    },
    isLeadersOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showMonthName (startDate, endDate) {
      return dayjs(startDate).month() !== dayjs(endDate).month()
    },
    hasPast () {
      return dayjs().isAfter(this.endDate, 'day')
    },
    border () {
      if (this.hasPast) {
        return 'border border-l-8 border-[#7C8A9C]'
      }
      if (this.isCalledOut) {
        return 'border border-l-8 border-[#FFCD19]'
      }
      return 'border border-l-8 border-[#6245F4]'
    },
  },
}
</script>

<style scoped>
* {
  font-family: 'proxima-nova';
  font-style: normal;
}
</style>
