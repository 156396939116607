<template>
  <div>
    <button
      class="rounded-[8px] leading-[22px] bg-[#080A6E] w-[24px] h-[24px] text-white disabled:opacity-60 disabled:cursor-not-allowed"
      :disabled="disabled || localValue <= min"
      @click="decrement"
    >
      -
    </button>
    <input
      v-model.number="localValue"
      type="number"
      class="text-center mx-[8px] border border-[#D3D8DE] w-[44px] h-[32px] rounded-[8px] disabled:opacity-60 disabled:cursor-not-allowed"
      :min="min"
      :max="max"
      :disabled="disabled"
    />
    <button
      class="rounded-[8px] leading-[22px] bg-[#080A6E] w-[24px] h-[24px] text-white disabled:opacity-60 disabled:cursor-not-allowed"
      :disabled="disabled || localValue >= max"
      @click="increment"
    >
      +
    </button>
  </div>
</template>

<script>
import HasLocalValue from '@/mixins/HasLocalValue'

export default {
  mixins: [HasLocalValue],

  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    increment () {
      if (this.max === null || this.localValue < this.max) {
        this.localValue++
      }
    },

    decrement () {
      if (this.min === null || this.localValue > this.min) {
        this.localValue--
      }
    },
  },
}
</script>
