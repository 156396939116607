import { createStore } from 'vuex';
import lookupsModule from './modules/lookups';
import photosModule from './modules/photos';
import profileModule from './modules/profile';
import userModule from './modules/user';
import toastModule from './modules/toast';
import cmsModule from './modules/cms';
import loaderModule from './modules/loader';
import serviceReplayModule from './modules/serviceReplay';
import boardModule from './modules/board';
import calendarModule from './modules/calendar'
import formModule from './modules/form'
import protectedModule from './modules/protected'
import updatesModule from './modules/updates'
import milestonesModule from './modules/milestones'

const store = createStore({
  modules: {
    lookups: lookupsModule,
    photos: photosModule,
    profile: profileModule,
    user: userModule,
    toast: toastModule,
    cms: cmsModule,
    loader: loaderModule,
    serviceReplay: serviceReplayModule,
    board: boardModule,
    calendar: calendarModule,
    form: formModule,
    protected: protectedModule,
    updates: updatesModule,
    milestones: milestonesModule,
  },
});

export default store;
