<template>
  <FDLayout>
  <div class="flex flex-col w-full overscroll-y-none overflow-y-scroll overflow-x-hidden justify-start">
    <div class="h-[100vh] w-[100vw] overscroll-y-none">
      <div class="pt-[123px] px-[24px] sm:px-0 sm:max-w-[678px] mx-auto">
        <FDTitle year="2017" />
        <p class="font-source-sans-3 text-[19px] sm:text-[20px] leading-[28px] sm:leading-[30px] mt-[41px] sm:mt-[56px]">
          I am writing this Father’s Day message to you on a Monday.<br />
          My Mondays are very special because they are reserved for my family.<br />
          But you are special. Of all the churches God would have led you to, <br class="hidden sm:inline" />
          He brought you to HOGC. <br class="hidden sm:block"/>
          So I thank God for the privilege to invest and impart to you.<br />
          <br />
          My Bible is very precious to me.<br />
          Because of my Bible, I can say – <br class="sm:hidden"/>“from me and my generation onwards <br class="sm:hidden"/>it is<br class="hidden sm:inline" />
          different.”<br />
          May this gift of a Bible shape your thinking and change your life.<br />
          I pray you will read it, live it and share <br class="sm:hidden"/>it for the rest of your life.<br />
          <br />
          My Bible has taught me 4 things:<br />
          <ol class="list-decimal ml-9 mt-4 font-source-sans-3 text-[18.5px] sm:text-[20px] leading-[28px]">
            <li>Look back and thank God.</li>
            <li>Look forward and trust God.</li>
            <li>Look around and serve God.</li>
            <li>Look up and seek God.</li>
          </ol>
          <br />
          <b>Look back and thank God.</b><br />
          Your past may be difficult and painful, but hey, you are still here.<br />
          The devil tried to knock you out.<br />
          People tried to knock you down.<br />
          But you are still here.<br />
          You survived.<br />
          You didn’t stay down.<br />
          My Bible says - “Having done all, stand!” (If you don’t know where this verse is
          from, that’s why I am giving you this Bible.)<br />
          You are standing.<br />
          With Christ in your life, you have grown from victim to Victor.<br />
          So smile and thank God.<br />
          <br />
          <b>Look forward and trust God.</b><br />
          Your destiny is greater <br class="sm:hidden"/>than your history.<br />
          From you and your generation <br class="sm:hidden"/>onwards, it will be different.<br />
          While you cannot change your past or your circumstances,<br class="hidden sm:block"/>
          you can change your future.<br />
        </p>
        <BibleQuote
          title="David says..."
          quote="“Once I was young, and now I am old.
          Yet I have never seen the godly abandoned or
          their children begging for bread.”"
          class="my-[48px]"
        />
        <BibleQuote
          title="Isaiah says..."
          quote="“You whom I have taken from the ends of the earth,
          And called from its farthest regions,
          And said to you,
          ‘You are My servant,
          I have chosen you and have not cast you away:
          Fear not, for I am with you;
          Be not dismayed, for I am your God.
          I will strengthen you,
          Yes, I will help you,
          I will uphold you with My righteous right hand.’ ”"
          class="my-[48px]"
        />
        <p class="font-source-sans-3 text-[19px] sm:text-[20px] leading-[28px] sm:leading-[30px] mt-[48px] sm:mt-[40px]">
          Your best days are ahead.<br />
          Your future is amazing.<br />
          <br />
          <b>Look around and serve God.</b><br />
          Don’t wallow in self-pity.<br />
          Don’t look down and be self-absorbed.<br />
          Jesus taught us to look up and see the harvest.<br />
          As you look around at school, work, church and the world, you will discover that there are many people who are struggling like you or even worse off.<br />
          In fact, you are already living the <br class="sm:hidden"/>dream of millions of people in the world.<br />
          If you don’t like your life, I can think of millions of people in <br class="hidden sm:block" />
          Africa or the Middle East who will gladly trade their lives for yours.<br />
          My Bible taught me to look around... to see the needs and meet those needs.<br />
          Despite your wounds and disadvantages, you can serve God <br />and make a difference.<br />
          <br />
          <b>Look up and seek God.</b><br />
          May my life inspire you to seek God.<br />
          May this Bible teach you to seek God.<br />
          God is my first choice, not my last resort.<br />
          <br />
          People often ask me how long <br class="sm:hidden"/>do I pray daily.<br />
          I would like to quote the great Smith Wigglesworth, who said “I don't pray <br class="sm:hidden"/>for<br class="hidden sm:inline" />
          more than one hour... but I also <br class="sm:hidden"/>don’t go for more than one hour <br class="sm:hidden"/>without prayer.”<br />
          This may not be literal because I am <br class="sm:hidden"/>not praying while I am asleep, but you get
          the idea...<br />
          On mountain high and in valley <br class="sm:hidden"/>low...always look up and seek God.
        </p>
        <FDSignOff class="mt-[48px]" year="2017" />
      </div>

      <FDFooter
        back-text="2016 letter"
        forward-text="2013 letter"
        back-slug="/hogcfd/2016"
        forward-slug="/hogcfd/2013"
        class="mt-[48px]"
      />
      <div class="pt-[96px] mt-[96px] lg:mt-0" />
    </div>
  </div>
</FDLayout>
</template>

<script>
import FDSignOff from '@/components/hogcfd/FDSignOff'
import FDTitle from '@/components/hogcfd/FDTitle'
import FDFooter from '@/components/hogcfd/FDFooter'
import BibleQuote from '@/components/hogcfd/BibleQuote.vue'
import FDLayout from '@/components/FDLayout.vue'

export default {
  components: {
    FDTitle,
    FDFooter,
    FDSignOff,
    BibleQuote,
    FDLayout
  },
}
</script>
