<template>
    <master-layout>
      <div
        class="md:py-[40px] md:pl-[40px] lg:pr-[30px] sm:px-[24px] py-[24px] lg:py-[56px] lg:pl-[64px] lg:flex-1 lg:overflow-hidden mb-[24px]"
      >
        <section class="px-[24px] sm:px-0 z-10">
          <h1
            class="text-[32px] leading-[40px] sm:text-[48px] sm:leading-[64px] font-bold mb-[4px] text-[#0B0C0F]"
          >
            Library
          </h1>
        </section>
      </div>
    </master-layout>
  </template>
  
  <script>
  </script>
  